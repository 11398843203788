import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  FunctionField,
  LinkButton,
  IconField,
  CustomDateField,
  ListDownloadButton,
  TextField,
} from '../../..';
import { useResource } from '../../../../hooks';
import { AsbuiltData } from '../../../../dataProvider';
import { AddIcon } from '../../../../assets';
import { getCsvDelimiter, getDecimalPoint } from '../../../../utils';
import { AsbuiltCsvDownloadButton } from './views';

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton
    path={`${basePath}/create`}
    label="admin.actions.creationInstructions"
  >
    <AddIcon />
  </LinkButton>
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 266,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 100,
          },
        },
      },
    },
  }),
);

const SiteAsbuiltList: React.FC = props => {
  const location = useLocation();
  const resource = useResource('asbuilts');
  const classes = useStyles();
  const decimalDelimiter = getDecimalPoint();
  const separatorDelimiter = getCsvDelimiter();
  const exportOtherNumberFormat = decimalDelimiter === ',';

  return (
    <FunctionField
      {...props}
      resource="sites"
      addLabel={false}
      render={record =>
        record ? (
          <CustomList
            breadcrumbs={['resources.sites.name']}
            resource={resource}
            controllerResource={`${resource}_${record.id}`}
            basePath={location.pathname}
            location={location}
            filter={{ siteId: record.id, retrofitIds: [] }}
            actions={<Actions />}
            hasCreate
            bulkActionButtons={false}
          >
            <Datagrid className={classes.root}>
              <CustomDateField
                resource={resource}
                source="execStartDate"
                showTime
              />
              <IconField resource={resource} source="execStatus" />
              <CustomDateField
                resource={resource}
                source="startPeriod"
                showTime
              />
              <CustomDateField
                resource={resource}
                source="endPeriod"
                showTime
              />
              <TextField resource={resource} source="csvType" />
              <FunctionField
                resource={resource}
                source="url"
                sortable={false}
                render={asbuiltRecord => {
                  const data = asbuiltRecord as AsbuiltData;
                  const url = data.url || '';
                  // CSVの出力フォーマットにより表示ボタンを切り替える
                  return exportOtherNumberFormat ? (
                    <AsbuiltCsvDownloadButton
                      label="admin.actions.csvDownload"
                      asbuiltFileId={data.asbuiltFileId}
                      decimalDelimiter={decimalDelimiter}
                      separatorDelimiter={separatorDelimiter}
                      execStatus={data.execStatus}
                    />
                  ) : (
                    <ListDownloadButton
                      url={data.execStatus === 'Complete' ? url : ''}
                      label="admin.actions.csvDownload"
                    />
                  );
                }}
              />
            </Datagrid>
          </CustomList>
        ) : null
      }
    />
  );
};

export default SiteAsbuiltList;
