import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  TextField,
  CustomDateField,
  ReferenceField,
  IconField,
  SiteSearchInput,
  Filter,
  CorporationNameField,
  Loading,
} from '../..';
import { useResource, useGetAccount } from '../../../hooks';
import { RetrofitData } from '../../../dataProvider';
import { config, controllerStatusTooltip } from '../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 70,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 48,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(7)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 48,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(8)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 104,
          },
        },
      },
    },
  }),
);

const SerchFilter: React.FC = props => (
  <Filter {...props}>
    <SiteSearchInput source="q" alwaysOn />
  </Filter>
);

const trancelateLicensePermissionName = (permission: string | undefined) => {
  return permission?.replace('MachineGuidance2D', '2D MachineGuidance');
};

const RetrofitList: React.FC = (props: any) => {
  const resource = useResource('retrofits');
  const { getCorporationId, isLoading } = useGetAccount();
  const corporationId = getCorporationId();
  const classes = useStyles();

  if (isLoading() || corporationId === '') return <Loading />;
  return (
    <CustomList
      {...props}
      resource={resource}
      title="admin.pages.retrofitList"
      bulkActionButtons={false}
      filter={{
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      }}
      filters={<SerchFilter />}
    >
      <Datagrid rowClick="edit" className={classes.root}>
        {config.feature.useRetrofitLicense ? (
          <IconField
            resource={resource}
            source="licenseMachineType"
            label="resources.retrofits.fields.machineInfoMachineType"
            sortable={false}
            onTooltip={({ res, record, translate }) => {
              const { hasLicense, licenseGrantInfo } = record as RetrofitData;
              if (
                hasLicense &&
                licenseGrantInfo !== 'Unlimited' &&
                licenseGrantInfo !== 'FlexUnlimited'
              ) {
                return translate(res.replace('_activate', ''), {
                  licenseGrantInfo:
                    trancelateLicensePermissionName(licenseGrantInfo),
                });
              }
              return translate(res);
            }}
          />
        ) : (
          <IconField resource={resource} source="machineInfoMachineType" />
        )}
        <TextField resource={resource} source="machineInfoMachineName" />
        <CorporationNameField label="resources.retrofits.fields.corporationId" />
        <ReferenceField
          resource={resource}
          source="siteId"
          reference="sites"
          link=""
        >
          <TextField resource="sites" source="name" />
        </ReferenceField>
        <IconField resource={resource} source="isOnline" sortable={false} />
        <IconField
          resource={resource}
          source="isError"
          onTooltip={controllerStatusTooltip}
          sortable={false}
        />
        <IconField resource={resource} source="rentalStatus" sortable={false} />
        <CustomDateField
          resource={resource}
          source="lastOnlineTime"
          showTime
          sortable={false}
        />
      </Datagrid>
    </CustomList>
  );
};

RetrofitList.displayName = 'RetrofitList';
export default RetrofitList;
