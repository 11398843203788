import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FilePresent from '@mui/icons-material/FilePresent';
import FitbitIcon from '@mui/icons-material/Fitbit';
import {
  BasicSettingIcon,
  CuttingEdgeIcon,
  CalibrationIcon,
  TopographicSurveyIcon,
  ErrorRetrofitIcon,
  ConfigurationIcon,
  TabletConfigurationIcon,
} from '../../assets';
import { TooltipIconButton } from '..';
import { useJumpTo } from '../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    shareFileIcon: {
      position: 'relative',
      bottom: '3px',
    },
  }),
);

const Space: React.FC = () => {
  const size = 8;
  return (
    <span
      style={{
        display: 'inline-block',
        width: size,
        height: size,
      }}
    ></span>
  );
};

export interface Props {
  retrofitId: string;
  parentPath: string;
  classes?: object;
  supportMode: boolean;
}

const RetrofitInfoMenu: React.FC<Props> = ({
  parentPath,
  retrofitId,
  supportMode,
  classes: classOverride,
}) => {
  const classes = useStyles({ classes: classOverride, supportMode: false });
  const { gotoInfo } = useJumpTo();

  return (
    <div className={classes.root}>
      {supportMode ? (
        <>
          {/* 建機詳細 - レトロフィット共有ファイル */}
          <TooltipIconButton
            tooltip="admin.tootip.retrofitShareFileList"
            onClick={() => {
              gotoInfo(parentPath, retrofitId, 'sharefiles');
            }}
          >
            <FilePresent className={classes.shareFileIcon} />
          </TooltipIconButton>
          <Space />
        </>
      ) : null}
      {/* 建機詳細 - タブレット/Retrofit Kit設定情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitConfigList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'config');
        }}
      >
        <TabletConfigurationIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - Retrofit Kit info情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitKitInfoList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'kitinfo');
        }}
      >
        <ConfigurationIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - レトロフィット刃先確認結果一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitAccuraciesList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'accuracies');
        }}
      >
        <CuttingEdgeIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - レトロフィット車体キャリブレーション情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitCalibaList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'calibs');
        }}
      >
        <CalibrationIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - 基本設定情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitBasicSettingList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'basic_settings');
        }}
      >
        <BasicSettingIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - レトロフィットエラー情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitErrorList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'errors');
        }}
      >
        <ErrorRetrofitIcon />
      </TooltipIconButton>
      <Space />
      {/* 建機詳細 - レトロフィットTopographicSurvey情報一覧 */}
      <TooltipIconButton
        tooltip="admin.tootip.retrofitTopographicSurveyList"
        onClick={() => {
          gotoInfo(parentPath, retrofitId, 'toposurveys');
        }}
      >
        <TopographicSurveyIcon />
      </TooltipIconButton>
      {parentPath.includes(`/sites/`) ? (
        <>
          {/* 建機詳細 - 施工実績 */}
          <Space />
          <TooltipIconButton
            tooltip="admin.tootip.retrofitAsbuilts"
            onClick={() => {
              gotoInfo(parentPath, retrofitId, 'asbuilts');
            }}
          >
            <FitbitIcon />
          </TooltipIconButton>
        </>
      ) : null}
    </div>
  );
};

RetrofitInfoMenu.displayName = 'RetrofitInfoMenu';
export default RetrofitInfoMenu;
