import { RetrofitData } from '../dataProvider';

// 改行コード
const newLineCode = '\r\n';

const translateConvert = (translate: any, source: string, value?: number) => {
  if (value === undefined) return '';
  const res = `resources.retrofits.controllerStatusCode.${source}.${value}`;
  return translate(res);
};

/**
 * controllerStatus のエラー内容をピックアップしてtranslateを書けた内容を返却する。
 * 空文字を省いた配列の全要素を順に改行コードで結合した新しい文字列を返す。
 **/
const controllerStatusTooltip = ({
  res,
  record,
  translate,
}: {
  res: string;
  record: any;
  translate: any;
}) => {
  const { isError, controllerStatusCode } = record as RetrofitData;
  if (!isError || !controllerStatusCode) {
    return translate(res);
  }

  const version = translateConvert(
    translate,
    'version',
    controllerStatusCode.version,
  );
  const ahrsEstimateStatus = translateConvert(
    translate,
    'ahrsEstimateStatus',
    controllerStatusCode.ahrsEstimateStatus,
  );
  const positionStatus = translateConvert(
    translate,
    'positionStatus',
    controllerStatusCode.positionStatus,
  );
  const baseStationConnectionStatus = translateConvert(
    translate,
    'baseStationConnectionStatus',
    controllerStatusCode.baseStationConnectionStatus,
  );

  const boomImuStatus = translateConvert(
    translate,
    'boomImuStatus',
    controllerStatusCode.boomImuStatus,
  );
  const armImuStatus = translateConvert(
    translate,
    'armImuStatus',
    controllerStatusCode.armImuStatus,
  );

  const bucketImuStatus = translateConvert(
    translate,
    'bucketImuStatus',
    controllerStatusCode.bucketImuStatus,
  );
  const bodyImuStatus = translateConvert(
    translate,
    'bodyImuStatus',
    controllerStatusCode.bodyImuStatus,
  );
  const boomHeadPressureSensorStatus = translateConvert(
    translate,
    'boomHeadPressureSensorStatus',
    controllerStatusCode.boomHeadPressureSensorStatus,
  );
  const boomBottomPressureSensorStatus = translateConvert(
    translate,
    'boomBottomPressureSensorStatus',
    controllerStatusCode.boomBottomPressureSensorStatus,
  );
  const tiltBucketImuStatus = translateConvert(
    translate,
    'tiltBucketImuStatus',
    controllerStatusCode.tiltBucketImuStatus,
  );

  // 数値が14行、15桁
  const secondBoomImuStatus = translateConvert(
    translate,
    'secondBoomImuStatus',
    controllerStatusCode.secondBoomImuStatus,
  );
  const rotarySensorStatus = translateConvert(
    translate,
    'rotarySensorStatus',
    controllerStatusCode.rotarySensorStatus,
  );
  const boomOffsetSensorStatus = translateConvert(
    translate,
    'boomOffsetSensorStatus',
    controllerStatusCode.boomOffsetSensorStatus,
  );

  // 数値が15桁
  const tiltrotatorStatus = translateConvert(
    translate,
    'tiltrotatorStatus',
    controllerStatusCode.tiltrotatorStatus,
  );
  // 空文字を省いた配列の全要素を順に改行コードで結合した新しい文字列を返す。
  return [
    version,
    ahrsEstimateStatus,
    positionStatus,
    baseStationConnectionStatus,
    boomImuStatus,
    armImuStatus,
    bucketImuStatus,
    bodyImuStatus,
    boomHeadPressureSensorStatus,
    boomBottomPressureSensorStatus,
    tiltBucketImuStatus,
    secondBoomImuStatus,
    rotarySensorStatus,
    boomOffsetSensorStatus,
    tiltrotatorStatus,
  ]
    .filter(f => f)
    .join(newLineCode);
};

export default controllerStatusTooltip;
