// for id
// インドネシア語
import raMessage from 'ra-language-indonesian-new';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Tidak ditemukan',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Apakah Anda ingin menghapus %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Menunggu persetujuan',
    usermenu: {
      userNotice: 'Pemberitahuan',
      terms: 'Syarat Penggunaan',
      manual: 'Manual',
      downloads: 'Unduh',
    },
    breadcrumbs: {
      details: 'Detail',
      retrofitEdit: 'Detail',
      taskCreate: 'Buat Tugas',
      taskEdit: 'Detail Tugas',
      geofenceCreate: 'Buat',
      geofenceEdit: 'Edit',
      geofenceAlertList: 'Peringatan Geofence',
      geofenceAlertShow: 'Detail',
      compactionWorkAreaCreate: 'Tambahkan Wilayah Kerja Pemadatan',
      compactionWorkAreaShow: 'Wilayah Kerja Pemadatan',
      compactionWorkAreaEdit: 'Edit Wilayah Kerja Pemadatan',
      compactionLayerCreate: 'Buat Lapisan Pemadatan',
      compactionLayerEdit: 'Edit Lapisan Pemadatan',
      supportMode: 'Mode Dukungan',
      retrofitManage: 'Pendaftaran Pengontrol',
      retrofitManageAdmin: 'Pendaftaran Pengontrol (Admin)',
      retrofitNamePlateFiles: 'Pendaftaran Pengontrol',
      retrofitAlternateRegistCreate: 'Pendaftaran Proksi Pengontrol',
      retrofitAlternateRegistEdit: 'Detail',
      retrofitManageAdminBulkActionCreate: 'Pendaftaran',
      terms: 'SYARAT PENGGUNAAN',
      downloads: 'Unduh',
    },
    pages: {
      siteList: 'Daftar Lokasi Kerja',
      siteRetrofitCreate: 'Tambahkan Mesin ke Lokasi Kerja',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Tambahkan Keranjang ke Lokasi Kerja',
      siteBucketEdit: 'Detail Keranjang',
      siteProjectEdit: 'Detail Proyek',
      siteProjectCreate: 'Pendaftaran File Proyek',
      siteExtensionarmCreate: 'Tambahkan Lengan Ekstensi ke Lokasi Kerja',
      taskCreate: 'Buat Tugas',
      taskEdit: 'Detail Tugas',
      geofenceAlertList: 'Peringatan Geofence',
      geofenceAlertShow: 'Detail Peringatan Geofence',
      geofenceCreate: 'Buat Geofence',
      geofenceEdit: 'Edit Geofence',
      compactionWorkAreaCreate: 'Tambahkan Wilayah Kerja Pemadatan',
      compactionLayerCreate: 'Buat Lapisan Pemadatan',
      compactionLayerEdit: 'Edit Lapisan Pemadatan',
      siteFileEdit: 'Detail',
      siteFileCreate: 'Tambahkan File ke Lokasi Kerja',
      retrofitList: 'Daftar Mesin',
      retrofitEdit: '',
      retrofitRentalCreate: 'Pendaftaran Penugasan',
      retrofitTransferCreate: 'Pendaftaran Transfer',
      retrofitSendBack: 'Pendaftaran Kirim Kembali',
      bucketList: 'Daftar Keranjang',
      bucketEdit: 'Detail Keranjang',
      bucketCreate: 'Pendaftaran Keranjang',
      bucketRentalCreate: 'Berbagi Keranjang',
      extensionarmList: 'Daftar Lengan Ekstensi',
      extensionarmEdit: 'Detail Lengan Ekstensi',
      extensionarmShare: 'Berbagi Lengan Ekstensi',
      retrofitBulkActionList: 'Daftar Pendaftaran Massal',
      retrofitBulkActionCreate: 'Pendaftaran Massal',
      retrofitBasicInfoList: 'Daftar Pengontrol',
      retrofitBasicInfoCreate: 'Pendaftaran Pengontrol',
      retrofitBasicInfoEdit: 'Pembaruan Pengontrol',
      gnssSettingList: 'Daftar Pengaturan Ntrip',
      gnssSettingCreate: 'Buat Pengaturan Ntrip',
      gnssSettingAdminCreate: 'Buat Pengaturan Ntrip',
      gnssSettingOwnerCreate: 'Buat Pengaturan Ntrip',
      gnssSettingEdit: 'Edit Pengaturan Ntrip',
      gnssSettingAdminEdit: 'Edit Pengaturan Ntrip',
      gnssSettingOwnerEdit: 'Edit Pengaturan Ntrip',
      approvalList: 'Daftar Mesin',
      retrofitRentalEdit: 'Detail Penugasan',
      retrofitTransferEdit: 'Detail Transfer',
      asbuiltsCreate: 'Peroleh Hasil Konstruksi',
      asbuiltsShow: 'Riwayat Proses Konstruksi',
      asbuiltsRetrofitCreate: 'Peroleh Hasil Konstruksi',
      retrofitConfigList: 'Info Konfigurasi',
      retrofitKitInfoList: 'Info Pengontrol',
      retrofitAccuraciesList: 'Hasil Pemeriksaan Akurasi',
      retrofitAccuraciesShow: 'Detail',
      retrofitCalibaList: 'Info Kalibrasi',
      retrofitCalibInfo: 'Detail Info Kalibrasi Mesin',
      retrofitBasicSettingList: 'Info Pengaturan Dasar',
      retrofitErrorList: 'Info Kesalahan',
      retrofitErrorShow: 'Detail',
      retrofitTopographicSurveyList: 'Daftar Survei Topografi',
      retrofitTopographicSurveyShow: 'Detail survei topografi',
      retrofitShareFileList: 'Log Aplikasi',
      retrofitCorporationLicenseList: 'Daftar Lisensi',
      retrofitCorporationLicenseEdit: 'Detail Lisensi',
      CompactionLayerList: 'Lapisan Pemadatan',
      userNoticeList: 'Daftar Pemberitahuan',
      userNoticeCreate: 'Tambahkan Pemberitahuan',
      userNoticeEdit: 'Pemberitahuan Pembaruan',
      userNoticeDrawer: 'Pemberitahuan',
      retrofitAlternateRegists: 'Daftar Pendaftaran Proksi Pengontrol',
      firmwareList: 'Daftar Firmware',
      firmwareShow: 'Detail Firmware',
      firmwareCreate: 'Tambahkan Firmware',
      retrofitManage: 'Teks',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Pendaftaran Massal',
      retrofitManageAdminBulkActionCreate: 'Pendaftaran Massal',
      retrofitAlternateRegistCreate: 'Pendaftaran Proksi Pengontrol',
      retrofitAlternateRegistEdit: 'Detail Pendaftaran Proksi Pengontrol',
      retrofitAlternateRegistCreateText: 'Teks',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Info Ntrip',
      ntripEdit: 'Manajemen Ntrip',
    },
    label: {
      login: 'Login',
      selected: 'Dipilih (%{length} kasus)',
      listNoResults: 'Data tidak ditemukan',
      maintenance: {
        title: 'Saat ini sedang menjalani pemeliharaan.',
        message: `Kami telah menghentikan layanan untuk sementara karena pemeliharaan.
      Kami mohon maaf atas ketidaknyamanan ini, namun mohon tunggu sebentar lagi.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Letakkan atau pilih file (%{fileTypes}) yang akan diunggah.',
        maxFileNum:
          'Jumlah maksimal pengontrol yang dapat didaftarkan sekaligus adalah lima.',
      },
      nosetting: 'Tidak Ada Pengaturan',
      siteRetrofitList: {
        remoteSupport: 'Dukungan jarak jauh',
      },
      retrofitEdit: {
        breadcrumbs: 'Detail',
        belongsDetails: '',
        ntripSettingInfo: 'Info Pengaturan Ntrip',
        machineryDetails: 'Mesin',
        retrofitDetails: 'Pengontrol',
        basicInfo: 'Info Dasar',
        controller: 'Pengontrol',
        reciverMain: 'Penerima Utama GNSS',
        reciverSub: 'Sub Penerima GNSS',
        retrofitstatus: 'Status Pengontrol',
        license: 'Lisensi',
        licenseId: 'ID Lisensi',
        expiredDate: 'Tanggal Kedaluwarsa ',
      },
      retrofitRental: {
        rentalDetails: 'Detail Penugasan',
        rentalInfo: 'Detail Penugasan',
        lending: 'Penugasan',
        return: 'Kembali',
        lendingDestination: 'Perusahaan yang Ditugaskan',
        lendingCompany: 'Nama Perusahaan',
        lendingEmail: 'Email',
        companyName: 'Nama Perusahaan',
      },
      retorfitTransfer: {
        tab: 'Transfer',
        transferInfo: 'Detail Transfer',
        transferButton: 'Transfer',
        transferEditButton: 'Edit Transfer',
      },
      siteConfigureEdit: {
        onButton: 'ON',
        offButton: 'OFF',
      },
      approvals: {
        rentalTab: 'Penugasan',
        transferTab: 'Transfer',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID Perusahaan sedang Digunakan',
        corporationName: 'Perusahaan sedang Digunakan',
      },
      buckets: {
        bucketTab: 'Keranjang',
        extensionarmTab: 'Lengan Ekstensi',
        extensionarmFile: 'File Lengan Ekstensi',
        shareTo: 'Bagikan Ke',
      },
      bucketRental: {
        corporation: 'Bagikan Ke',
        bucketType: 'Jenis',
        bk3File: 'File BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Info Dasar',
        bulkActionTab: 'Pendaftaran Massal',
        ntripTab: 'Manajemen Ntrip',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Pembaruan Massal',
        retrofitDistributor: 'Distributor',
        retrofitRegion: 'Wilayah',
        corporationId: 'ID Perusahaan',
        corporationName: 'Nama Perusahaan',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Kirim Kembali',
        initialize: 'Inisialisasi',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Nama Perusahaan',
      },
      terms: {
        title: 'Syarat Penggunaan',
        message:
          'Silakan klik tautan di bawah untuk meninjau Syarat Penggunaan.',
        confirmMessage1:
          'Untuk menggunakan Smart Construction Pilot, Anda harus menyetujui dokumen berikut ini.',
        confirmMessage2:
          'Jika Anda tidak setuju, Anda tidak akan dapat menggunakan Smart Construction Pilot.',
        select: 'Silakan pilih wilayah Anda.',
      },
      downloads: {
        title: 'Pengonversi File Proyek',
        message:
          'Ini adalah aplikasi mandiri yang membuat file proyek dari file desain.',
        button: 'Unduh',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Penomoran Otomatis',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Unduh',
        resultFileDownload: 'Unduh',
      },
      tasks: {
        coordinate: 'Koordinat (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Ukuran Blok (%{unit})',
      },
      compactionLayers: {
        thickness: 'Ketebalan (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detail',
        rentalDetail: 'Detail Penugasan',
      },
      pendingTransfers: {
        breadcrumbs: 'Detail',
        transferDetail: 'Detail Transfer',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detail',
        alternateRegistDetail: 'Detail Pendaftaran Proksi Pengontrol',
      },
      supportMode: {
        retrofit: 'Mesin',
        optional: 'Opsional',
      },
      retrofitList: {
        assignment: 'Penugasan',
        license: 'Lisensi',
      },
      userNotices: {
        languageDetails: 'Isi Pemberitahuan',
        language: 'Bahasa',
        languageEn: 'Indonesia',
        languageJa: 'Jepang',
        languageFr: 'Prancis',
        languageDe: 'Jerman',
        languageEs: 'Spanyol',
        Information: 'Pemberitahuan',
        Maintenance: 'Pemeliharaan',
        emergency: 'Keadaan darurat',
        important: 'Penting',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Pendaftaran Pengontrol',
        corporationName: 'Nama Perusahaan',
        basicInfoSerialNumber: 'Nomor Seri',
        confirmInput: 'Masukkan kembali Nomor Seri',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Nomor Seri',
        status: 'Status',
        imageFile: 'Gambar',
        message1:
          'Silakan ambil gambar nomor seri pengontrol atau kode QR yang jelas dan tidak buram.',
        message2: 'Gambar harus berukuran kurang dari %{mbyte}MB.',
        errormessage:
          'Jumlah maksimal pengontrol yang dapat didaftarkan sekaligus adalah lima.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Valid',
          disable: 'Tidak Valid',
          unLicense: 'Tidak dialokasikan',
        },
        machine: 'Mesin',
        before: 'Sebelum',
        after: 'Setelah',
      },
      geofences: {
        project: 'Proyek',
        projectSourceLayer: 'Lapisan',
        rgb: 'Warna(RGB)',
        select: 'Geofence pemilihan',
        coordinates: 'Koordinat',
        coordinatesLabel: 'Koordinat (U,T)',
        unitLength: 'Unit:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinat (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Radius',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Pendaftaran Proksi Pengontrol',
        corporationName: 'Nama Perusahaan',
        email: 'Email',
        basicInfoSerialNumber: 'Nomor Seri',
        confirmInput: 'Masukkan kembali Nomor Seri',
      },
      ntripList: {
        unusedTotal: 'Total yang Tidak Digunakan',
      },
      calibrationDetail: {
        breadcrumbs: 'Detail',
        machineInfo: 'Info Mesin',
        machineGeometryInfo: 'Info Geometri Mesin',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Info Pemasangan IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Info Pemasangan GNSS Utama',
        bodyCenterToGnssSub: 'Info Pemasangan Sub GNSS',
        extendedModel: 'Model yang Diperluas',
        measuredValues: 'Info Kalibrasi',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Kesalahan konversi data',
        system_localization_quantity_error: `Beberapa file yang berisi informasi pelokalan tidak dapat didaftarkan.
        Harap daftarkan hanya satu file yang berisi informasi pelokalan.`,
        system_detect_0byte_file_error: `Pendaftaran file ke server gagal karena kesalahan komunikasi.
        Silakan daftarkan kembali file tersebut.`,
        system_batch_error: 'Kesalahan Sistem (P001)',
        system_parameter_error: 'Kesalahan Sistem (P002)',
        system_parameterfile_notfound: 'Kesalahan Sistem (P003)',
        system_exception: 'Kesalahan Sistem (P004)',
        system_logfile_error: 'Kesalahan Sistem (P005)',
        system_parameterfile_parameter_error: 'Kesalahan Sistem (P006)',
        desing_localization_error: `Tidak ada informasi pelokalan yang tersedia.
        Silakan daftarkan salah satu file berikut:

        ・File GC3
        ・File TP3 berisi titik kontrol
        ・File CAL`,
        desing_no_support_dxf_error:
          'File DXF tidak dapat dibaca karena versinya lebih lama dari AutoCAD2000.',
        desing_tile_error: 'Gagal membuat file proyek.',
        desing_outline_error: 'Pembuatan garis batas luar gagal.',
        desing_area_length_error:
          'Prosesnya dibatalkan karena area proyek terlalu besar. Silakan periksa apakah data desain dan skala data CAD sudah benar.',
        desing_layer_name_duplication_error: `Data proyek dengan nama lapisan duplikat tidak dapat didaftarkan.
        Silakan ubah nama lapisan desain atau daftarkan agar hanya ada satu file dengan nama lapisan desain yang sama.`,
        targetFiles: '[Nama file]',
        targetLayers: '[Lapisan]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Tidak ada',
        system_batch_error: 'Kesalahan Sistem (T001)',
        system_parameter_error: 'Kesalahan Sistem (T002)',
        system_parameterfile_notfound: 'Kesalahan Sistem (T003)',
        system_exception: 'Kesalahan Sistem (T004)',
        system_logfile_error: 'Kesalahan Sistem (T005)',
        system_basefile_notfound: 'Kesalahan Sistem (T006)',
        system_prjfile_multi: 'Kesalahan Sistem (T007)',
        system_basefile_read_error: 'Kesalahan Sistem (T008)',
        system_target_layer_notfound: 'Kesalahan Sistem (T009)',
        system_target_layer_duplication: 'Nama lapisan yang sama sudah ada.',
        system_taskarea_error: 'Area tugas tidak valid.',
        system_taskarea_design_notfound:
          'Tidak ada data untuk file proyek dalam area tugas (TIN, linework, titik).',
        system_tile_error: 'Gagal membuat file proyek.',
        system_outline_error: 'Pembuatan garis batas luar gagal',
        system_parameterfile_error: 'Kesalahan Sistem',
      },
      retrofitError: {
        1000: 'Data UDP dari Pengontrol tidak terdeteksi',
        2000: 'IMU Bodi Tidak Terdeteksi',
        2001: 'Kesalahan Perangkat Keras IMU Bodi',
        2002: 'Kesalahan Algoritma IMU Bodi',
        2003: 'Kesalahan Data IMU Bodi',
        2004: 'Kesalahan Inisialisasi IMU Bodi',
        2100: 'IMU Boom Tidak Terdeteksi',
        2101: 'Kesalahan Perangkat Keras IMU Boom',
        2102: 'Kesalahan Algoritma IMU Boom',
        2103: 'Kesalahan Data IMU Boom',
        2104: 'Kesalahan Inisialisasi IMU Boom',
        2200: 'IMU Lengan Tidak Terdeteksi',
        2201: 'Kesalahan Perangkat Keras IMU Lengan',
        2202: 'Kesalahan Algoritma IMU Lengan',
        2203: 'Kesalahan Data IMU Lengan',
        2204: 'Kesalahan Inisialisasi IMU Lengan',
        2300: 'IMU Keranjang Tidak Terdeteksi',
        2301: 'Kesalahan Perangkat Keras IMU Keranjang',
        2302: 'Kesalahan Algoritma IMU Keranjang',
        2303: 'Kesalahan Data IMU Keranjang',
        2304: 'Kesalahan Inisialisasi IMU Keranjang',
        2400: 'IMU keranjang miring Tidak Terdeteksi',
        2401: 'Kesalahan Perangkat Keras IMU keranjang miring',
        2402: 'Kesalahan Algoritma IMU keranjang miring',
        2403: 'Kesalahan Data IMU keranjang miring',
        2404: 'Kesalahan Inisialisasi IMU keranjang miring',
        2500: 'IMU Boom ke-2 Tidak Terdeteksi',
        2501: 'Kesalahan Perangkat Keras IMU Boom ke-2',
        2502: 'Kesalahan Algoritma IMU Boom ke-2',
        2503: 'Kesalahan Data IMU Boom ke-2',
        2504: 'Kesalahan Inisialisasi IMU Boom ke-2',
        2600: 'Sensor Ayun Tidak Terdeteksi',
        2601: 'Kesalahan Sistem Sensor Ayun',
        2602: 'Kesalahan Perangkat Keras Sensor Ayun',
        2700: 'Sensor Offset Tidak Terdeteksi',
        2701: 'Kesalahan Sistem Sensor Offset',
        2702: 'Kesalahan Perangkat Keras Sensor Offset',
        3000: 'Sensor tekanan sisi kepala tidak terdeteksi',
        3100: 'Sensor tekanan sisi bawah tidak terdeteksi',
        4000: 'Antena GNSS tidak terdeteksi',
        5000: 'Informasi Koreksi tidak terdeteksi',
        5001: 'Sinyal penerima radio tidak terdeteksi',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: 'Anda akan diarahkan ke situs web lain. Setuju?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Keranjang',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'ID Perusahaan dan Nama Perusahaan diperlukan.',
        },
        save: {
          message1: 'ID/Nama Perusahaan tidak dapat diubah karena itu termasuk',
          message2: 'mesin yang ditugaskan ke Perusahaan lain.',
          message3: '',
          message4: 'Silakan periksa nomor seri di bawah ini.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Pengontrol',
        },
        undeletable: {
          message: 'Pengontrol sedang digunakan dan tidak dapat dihapus.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Apakah Anda ingin menghentikan Pengaturan Ntrip ini?',
          delete: 'Apakah Anda ingin menghapus Pengaturan Ntrip?',
        },
      },
      siteRetrofitList: {
        title: 'Informasi Tambahan',
        message: 'Periode konstruksi telah berakhir.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Pengaturan Umum',
          siteSetting: 'Pengaturan Lokasi Kerja',
        },
        mapBackgroundColor: 'Warna Latar Belakang Peta',
        message:
          'Fungsi deteksi kontak geofence/pemberitahuan peringatan mungkin tidak berfungsi dengan baik tergantung pada lingkungan dan kondisi penggunaannya. Jangan terlalu yakin saat menggunakan fungsi tersebut, dan pastikan Anda memahami fungsi dan ketentuan penggunaan.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Apakah Anda ingin menghapus geofence ini?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Mesin terhubung ke lokasi kerja dan tidak dapat ditugaskan. Silakan putuskan sambungan mesin dari lokasi kerja.',
          message2: 'Silakan putuskan sambungan mesin dari lokasi kerja.',
        },
        sameOwner: {
          message:
            'Mesin tidak dapat ditugaskan karena perusahaan Anda adalah pemiliknya.',
        },
        unableTransfer: {
          message:
            'Mesin sedang menunggu persetujuan transfer dan tidak dapat didaftarkan penugasannya.',
        },
        unableAlternateRegist: {
          message:
            'Mesin sedang menunggu Persetujuan Pendaftaran Proksi Pengontrol dan tidak dapat didaftarkan untuk penugasan.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Mesin yang terhubung ke lokasi kerja tidak dapat ditransfer. Silakan putuskan sambungan mesin dari lokasi kerja.',
        messageRentaled: 'Mesin ditugaskan dan tidak dapat ditransfer.',
        messageLicense: 'Mesin memiliki lisensi dan tidak dapat ditransfer.',
        pendingApprovals: {
          message1:
            'Permintaan transfer telah diterima. Harap tunggu persetujuan.',
          message2:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Apakah Anda ingin mengirim kembali pengontrol dan mewariskan pengaturan ke pengontrol target?',
        deleteNtrip:
          'Apakah Anda ingin menginisialisasi pengontrol yang dikembalikan?',
        unableRegion:
          'Pengontrol ini telah dikirim ke luar negeri. Jika pengontrol ini benar, silakan reset "Wilayah" ke nol.',
        unableCorporation: {
          message1: 'Pengontrol tidak dapat dikirim kembali.',
          message2:
            'Jika Anda yakin ini adalah kesalahan, silakan hubungi dealer Anda.',
        },
        retrofitRentaled:
          'Pengontrol telah ditetapkan dan tidak dapat dikirim kembali.',
        tiedToSite:
          'Mesin terhubung ke lokasi kerja dan tidak dapat dikirim kembali.',
      },
      retrofitAddToSiteAction: {
        title: 'Informasi Tambahan',
        message:
          'Ada mesin yang menunggu persetujuan, sehingga tidak dapat dihubungkan ke lokasi kerja.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Informasi Tambahan',
        message:
          'Mesin sedang ditransfer dan tidak dapat dihubungkan ke lokasi kerja.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'Pengaturan Ntrip yang dipilih sedang dikirim ke mesin lain.',
        },
        unableTransferInfo: {
          message: 'Untuk mentransfer, harap batalkan pengaturan berikut.',
          reasonSiteId: 'Lokasi Kerja',
          reasonRentaled: 'Penugasan',
          reasonLicense: 'Lisensi',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Perusahaan yang ditugaskan saat ini sedang menugaskan mesin tersebut ke perusahaan lain. Tanggal mulai penugasan ke perusahaan lain adalah %{date}.',
          message2: 'Silakan pilih tanggal akhir setelah %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Mesin tersebut terhubung ke lokasi kerja di perusahaan yang ditugaskan. Apakah Anda ingin mengakhiri penugasan ini?',
        },
        confirmDoubleRental: {
          message:
            'Perusahaan yang ditugaskan saat ini sedang menugaskan mesin tersebut ke perusahaan lain. \nPerusahaan yang ditugaskan saat ini sedang menugaskan mesin tersebut ke perusahaan lain. Menetapkan tanggal akhir juga mengakhiri penugasan ke perusahaan lain. Apakah Anda ingin menetapkan tanggal akhir?',
        },
        unapprovedSave: {
          message: 'Penugasan ini belum disetujui dan tidak dapat disimpan.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Apakah Anda ingin menghapus penugasan ini?',
        },
        approvedDelete: {
          title: '',
          message: 'Penugasan ini telah disetujui dan tidak dapat dihapus.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Mesin sedang dalam penugasan dan tidak dapat ditransfer.',
        },
        approvedSave: {
          message: 'Mesin memiliki lisensi dan tidak dapat ditransfer.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Apakah Anda ingin menghapus transfer ini?',
        },
        approvedDelete: {
          title: '',
          message: 'Transfer ini telah disetujui dan tidak dapat dihapus.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Lengan Ekstensi',
        },
      },
      projectFileUpload: {
        message:
          'Beberapa file titik kontrol (.gc3/.cal) tidak dapat didaftarkan.',
      },
      projectEdit: {
        confirm: {
          message:
            'Setelah memperbarui, proyek akan menghapus tugas yang ada. Apakah Anda ingin memperbarui?',
        },
      },
      projectConversionStatus: {
        title: 'Status Konversi',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Status Konversi',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'Tugas yang dipilih tidak dapat dihapus karena sedang digunakan.',
        },
        unableToCreate: {
          message: 'Tidak ada data desain yang terdaftar dalam proyek ini.',
        },
        unableToDelete: {
          message1:
            'Tugas tidak dapat dihapus karena status proyek dan status tugas sebagai berikut.',
          message2: 'Proyek: Mengonversi',
          message3: 'Tugas   : Kesalahan',
        },
        unableToUpdate: {
          message1:
            'Tugas tidak dapat diedit karena status proyek dan status tugas tidak seperti berikut.',
          message2: 'Proyek: [Dikonversi]',
          message3: 'Tugas : [Mentransfer][Ditransfer][Gagal Mentransfer]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Tugas',
        },
        unableToDelete: {
          message:
            'Tugas yang dipilih tidak dapat dihapus karena sedang digunakan.',
        },
      },
      taskMap: {
        crossingMessage: 'Area tugas berpotongan.',
        sameValueMessage: 'Koordinat yang sama telah dimasukkan.',
        areaZeroMessage: 'Tidak ada area untuk area tugas.',
        noPolygonMessage:
          'Tugas tidak dapat didaftarkan karena belum terdaftar.',
      },
      compactionMaterials: {
        add: {
          title: 'Tambahkan Bahan',
        },
        update: {
          title: 'Perbarui Bahan',
        },
      },
      weathers: {
        add: {
          title: 'Tambahkan Cuaca',
        },
        update: {
          title: 'Perbarui Cuaca',
        },
      },
      operators: {
        add: {
          title: 'Tambahkan Operator',
        },
        update: {
          title: 'Perbarui Operator',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Edit Wilayah Kerja Pemadatan',
        },
        undeletable: {
          message:
            'Lapisan pemadatan yang termasuk dalam area konstruksi sedang digunakan dan tidak dapat dihapus',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Lapisan pemadatan sedang digunakan dan tidak dapat dihapus',
        },
        deleteConfirm: {
          messageTargetItem: 'Lapisan Pemadatan',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Pengaturan Ntrip lainnya sedang dikirim ke mesin yang dipilih.',
        },
        confirmDelete: {
          message: 'Apakah Anda ingin menghapus Pengaturan Ntrip?',
        },
        confirmDeleteWithMachine: {
          message:
            'Mesin saat ini terhubung. Apakah Anda ingin menghapus Pengaturan Ntrip?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Pemberitahuan',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Atur lisensi ke mesin berikut.',
        },
        changeDisable: {
          message:
            'Silakan ubah ID lisensi mesin konstruksi berikut menjadi valid.',
        },
        changeTemporary: {
          message:
            'Atur lisensi ke mesin berikut. Lisensi sementara yang ditetapkan saat ini akan menjadi tidak valid.',
        },
        errorAdd: {
          message:
            'Lisensi mesin tidak dapat diubah karena Semua lisensi telah ditetapkan.',
        },
        errorSameType: {
          message:
            'Lisensi mesin tidak dapat diubah karena jenis lisensi yang sama telah ditetapkan.',
        },
        errorUnlimited: {
          message:
            'Mesin telah menetapkan lisensi Tidak Terbatas, lisensi lain tidak dapat diatur.',
        },
        addAll: {
          message:
            'Mesin berikut telah ditetapkan lisensinya. Apakah Anda ingin menetapkan Semua lisensi?',
        },
        compare: {
          message: 'Ubah lisensi untuk mesin berikut.',
        },
        warning: {
          message:
            '%{retrofit} memiliki lisensi %{id}. Apakah Anda ingin mencabut lisensinya?',
        },
        unableTransfer: {
          message:
            'Mesin sedang ditransfer sehingga lisensi tidak dapat dihubungkan.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial:
            'Kesalahan Sistem. Terdapat data yang gagal menganalisis gambar.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Permintaan pendaftaran telah diterima. Mohon tunggu hingga disetujui.',
          message2:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        corporationIdCheck: {
          message1:
            'Pengontrol sudah terdaftar. Silakan periksa dengan mencari di mesin.',
          message2:
            'Jika Anda tidak dapat menemukan pengontrol target dalam daftar, silakan hubungi kami dari URL di bawah.',
        },
        earthbrainIdCheck: {
          message1: 'Kami mohon maaf atas ketidaknyamanannya,',
          message2:
            'tapi silakan hubungi kami dari URL di bawah dengan informasi berikut.',
          message3: '-Nomor seri pengontrol',
          message4: '-Nama mesin yang akan didaftarkan (opsional)',
          message5: '-ID Perusahaan yang akan didaftarkan',
        },
        invalidSerialNumber: {
          message1: 'Pengontrol sudah digunakan oleh perusahaan lain.',
          message2: 'Jika Anda ingin mengubahnya karena alasan lain, ',
          message3:
            'silakan hubungi kami dari URL di bawah dengan informasi berikut.',
          message4: '-Nomor seri pengontrol',
          message5: '-Nama mesin yang akan didaftarkan (opsional)',
          message6: '-ID Perusahaan yang akan didaftarkan',
          message7: '-Alasan untuk berubah',
        },
        lendingRetrofits: {
          message1: 'Pengontrol ditugaskan.',
          message2:
            'Silakan mendaftar kembali setelah penugasan berakhir di perusahaan yang menugaskan.',
          message3:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        siteAttached: {
          message1: 'Pengontrol terhubung ke lokasi kerja.',
          message2:
            'Silakan mengajukan permohonan kembali setelah melepaskan mesin dari lokasi kerja.',
          message3:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        serialNumberExistError: {
          message1:
            'Ini adalah nomor seri pengontrol yang tidak ada. Pastikan nomor seri pengontrol yang Anda masukkan sudah benar.',
          message2:
            'Jika masalah masih berlanjut, silakan hubungi kami dari URL di bawah.',
        },
        ntripShortage: {
          message1:
            'Kami tidak dapat mengeluarkan ID pengguna dan kata sandi Ntrip Anda.',
          message2: 'Silakan hubungi kami pada URL di bawah ini.',
        },
        serialnumberCheck: {
          message: 'Silakan periksa produk dengan nomor seri berikut',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'Email diperlukan.',
        },
        pendingApprovals: {
          message1:
            'Permintaan pendaftaran telah diterima. Mohon tunggu hingga disetujui.',
          message2:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        corporationIdCheck: {
          message1:
            'Pengontrol sudah terdaftar. Silakan periksa dengan mencari di mesin.',
          message2:
            'Jika Anda tidak dapat menemukan pengontrol target dalam daftar, silakan hubungi kami dari URL di bawah.',
        },
        earthbrainIdCheck: {
          message1: 'Kami mohon maaf atas ketidaknyamanannya,',
          message2:
            'tapi silakan hubungi kami dari URL di bawah dengan informasi berikut.',
          message3: '-Nomor seri pengontrol',
          message4: '-Nama mesin yang akan didaftarkan (opsional)',
          message5: '-ID Perusahaan yang akan didaftarkan',
        },
        invalidSerialNumber: {
          message1: 'Pengontrol sudah digunakan oleh perusahaan lain.',
          message2: 'Jika Anda ingin mengubahnya karena alasan lain, ',
          message3:
            'silakan hubungi kami dari URL di bawah dengan informasi berikut.',
          message4: '-Nomor seri pengontrol',
          message5: '-Nama mesin yang akan didaftarkan (opsional)',
          message6: '-ID Perusahaan yang akan didaftarkan',
          message7: '-Alasan untuk berubah',
        },
        lendingRetrofits: {
          message1: 'Pengontrol ditugaskan.',
          message2:
            'Silakan mendaftar kembali setelah penugasan berakhir di perusahaan yang menugaskan.',
          message3:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        siteAttached: {
          message1: 'Pengontrol terhubung ke lokasi kerja.',
          message2:
            'Silakan mengajukan permohonan kembali setelah melepaskan mesin dari lokasi kerja.',
          message3:
            'Jika Anda memiliki pertanyaan, silakan hubungi kami dari URL di bawah.',
        },
        serialNumberExistError: {
          message1:
            'Ini adalah nomor seri pengontrol yang tidak ada. Pastikan nomor seri pengontrol yang Anda masukkan sudah benar.',
          message2:
            'Jika masalah masih berlanjut, silakan hubungi kami dari URL di bawah.',
        },
        ntripShortage: {
          message1:
            'Kami tidak dapat mengeluarkan ID pengguna dan kata sandi Ntrip Anda.',
          message2: 'Silakan hubungi kami pada URL di bawah ini.',
        },
        serialnumberCheck: {
          message: 'Silakan periksa produk dengan nomor seri berikut',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Pengontrol ini sudah dimiliki oleh perusahaan Anda.',
          message2: 'Silakan menghubungi perusahaan pemohon.',
        },
        userCorporation: {
          message1:
            'Anda tidak dapat menyetujui permintaan ini karena Anda sudah meminjamkan pengontrol ini.',
          message2: 'Silakan menghubungi perusahaan pemohon.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Ada mesin yang digunakan di lokasi tanpa lisensi.
      Lisensi diperlukan untuk digunakan di lokasi.
      Jika Anda belum membeli lisensi, silakan beli dari Smart Construction Marketplace.`,
        message2: `
      Jika Anda menyewa dari perusahaan lain, harap minta lisensi mesin tersebut dari sumber persewaan.

      Jika Anda memiliki pertanyaan, silakan periksa situs dukungan berikut.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Log Aplikasi',
      retrofitConfigList: 'Info Konfigurasi',
      retrofitKitInfoList: 'Info Pengontrol',
      retrofitAccuraciesList: 'Hasil Pemeriksaan Akurasi',
      retrofitCalibaList: 'Info Kalibrasi',
      retrofitBasicSettingList: 'Pengaturan Dasar',
      retrofitErrorList: 'Detail Kesalahan',
      retrofitTopographicSurveyList: 'Survei Topografi',
      retrofitAsbuilts: 'Hasil Konstruksi',
    },
    actions: {
      ok: 'OK',
      add: 'Tambahkan',
      create: 'Pendaftaran',
      yes: 'Ya',
      no: 'Tidak',
      confirmation: 'Konfirmasi',
      save: 'Simpan',
      cancel: 'Batalkan',
      required: 'Diperlukan',
      addMachineToSite: 'Tambahkan Mesin ke Lokasi Kerja',
      addBucketToSite: 'Tambahkan',
      deleteFromSite: 'Putuskan sambungan dari Lokasi Kerja',
      removeLicense: 'Putuskan sambungan dari Mesin',
      lendMachine: 'Penugasan',
      transferMachine: 'Transfer',
      remoteSupport: 'Dukungan jarak jauh',
      lendBucket: 'Bagikan',
      approval: 'Persetujuan',
      connect: 'Hubungkan',
      creationInstructions: 'Peroleh Hasil Konstruksi',
      csvDownload: 'Unduh',
      retrofitBundleCreate: 'Pendaftaran Massal',
      termsAccept: 'Setuju',
      termsDeny: 'Tidak Setuju',
      bulkRegistration: 'Pembaruan Massal',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Item Pemadatan',
      addLanguage: 'Tambahkan Bahasa',
    },
    message: {
      error: 'Sebuah kesalahan telah terjadi',
      confirm: 'Setuju?',
      fileUpload: 'Seret dan letakkan file di sini.',
      success: 'Pendaftaran telah selesai',
      approved: 'Persetujuan telah selesai',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Apakah Anda ingin menghapus %{title}?',
      bulkDeleteConfirmContent: 'Apakah Anda ingin menghapus %{title}?',
      disconnectFromSite:
        'Apakah Anda ingin memutuskan sambungan dari lokasi kerja?',
      saveConfirmContent: 'Konfirmasi',
      removeLicense: 'Apakah Anda ingin memutuskan sambungan dari mesin?',
      task: {
        crossingMessage: 'Area tugas berpotongan.',
        sameValueMessage: 'Koordinat yang sama telah dimasukkan.',
        areaZeroMessage: 'Tidak ada area untuk area tugas.',
        invalidPolygonMessage: 'Koordinat yang sama telah dimasukkan.',
      },
      geofence: {
        crossingMessage: 'Area geofence berpotongan.',
        sameValueMessage: 'Koordinat yang sama telah dimasukkan.',
        areaZeroMessage: 'Tidak ada area untuk area geofence.',
      },
      licenseError:
        'Anda tidak memiliki lisensi untuk Smart Construction Pilot (Aplikasi Web).',
      retrofitsManage: {
        serialNumberMatchError: 'Nomor Seri tidak cocok.',
        serialNumberInputError: 'Nomor Seri tidak ada.',
        invalidAccess:
          'Anda tidak berwenang melihat halaman ini, kembali ke halaman atas.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Nomor Seri tidak cocok.',
        serialNumberInputError: 'Nomor Seri tidak ada.',
        retrofitRentaled:
          'Informasi perusahaan tidak dapat diperbarui saat pengontrol sedang bertugas.',
        invalidCorporation:
          'Karena perusahaan Anda bukan distributor, Anda tidak dapat melakukan pendaftaran proksi pengontrol.',
      },
    },
    validation: {
      startDate: 'Tanggal Mulai',
      endDate: 'Tanggal Akhir',
      invalidDateFormat: 'Format tanggal tidak valid.',
      invalidDateTimeFormat: 'Format tanggal dan waktu tidak valid.',
      duplicateProject: 'Nama proyek diduplikasi.',
      incorrectBeforeDate:
        '%{target} harus ditetapkan pada tanggal sebelum %{before}.',
      incorrectAfterDate:
        '%{target} harus ditetapkan pada tanggal setelah %{after}.',
      incorrectPlanStartDate:
        'Tanggal mulai yang dijadwalkan harus berada sebelum tanggal akhir yang dijadwalkan.',
      incorrectPlanEndDate:
        'Tanggal akhir yang dijadwalkan harus berada setelah tanggal mulai yang dijadwalkan.',
      incorrectActualStartDate:
        'Tanggal mulai harus ditetapkan sebelum tanggal akhir.',
      incorrectActualEndDate:
        'Tanggal akhir harus ditetapkan setelah tanggal mulai.',
      withActualStartDate: 'Silakan masukkan juga tanggal mulai.',
      inputAvailableDate: 'Tanggal yang dapat dimasukkan adalah dari %{date}.',
      registrationRequired:
        'Silakan pilih perusahaan atau alamat email yang ditugaskan.',
      bothInput:
        'Anda hanya dapat memasukkan perusahaan yang ditugaskan atau alamat email.',
      supportMode: 'Silakan masukkan ID Perusahaan atau ID Lokasi Kerja.',
      invalidBkFile: 'Ini bukan file BK3.',
      invalidPdfFile: 'Ini bukan file PDF.',
      duplicatedBucketName: 'Nama keranjang diduplikasi.',
      duplicatedTaskName: 'Nama tugas diduplikasi.',
      duplicateGeofenceName: 'Nama Geofence diduplikasi.',
      duplicatedCompactionWorkAreaName: 'Nama area kerja pemadatan diduplikasi',
      duplicatedCompactionLayerName: 'Nama lapisan pemadatan diduplikasi',
      duplicated: {
        compactionMaterials: {
          message: 'Nama bahan diduplikasi',
        },
        weathers: {
          message: 'Nama cuaca diduplikasi',
        },
        operators: {
          message: 'Nama operator diduplikasi',
        },
      },
      containInvalidString:
        'Karakter yang tidak valid disertakan. (%{msg} tidak diperbolehkan)',
      validHalfWidthAlphanumeric:
        'Hanya karakter alfanumerik dengan lebar setengah yang diperbolehkan.',
      validNumberFormat: 'Bukan angka.',
      validIntegerFormat: 'Bukan bilangan bulat.',
      validIntegerRangeFormat: 'Input diperbolehkan dari 0 hingga 2147483647.',
      validDuplicateLanguage: 'Bahasa yang sama dipilih.',
      validBeforeDate:
        '%{target} harus ditetapkan sebagai tanggal dan waktu sebelum %{before}.',
      validAfterDate:
        '%{target} harus ditetapkan sebagai tanggal dan waktu setelah %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Lokasi Kerja',
      fields: {
        name: 'Lokasi Kerja',
        corporationName: 'Nama Perusahaan',
        status: 'Status',
        workPeriodStartDate: 'Tanggal Mulai',
        workPeriodEndDate: 'Tanggal Akhir',
      },
      status: {
        completed: 'Lengkap',
        waiting: 'Sebelum Konstruksi Dimulai',
        working: 'Dalam Masa Konstruksi',
      },
    },
    retrofits: {
      name: 'Mesin',
      fields: {
        information: 'Info Mesin',
        rental: 'Penugasan',
        sendBack: 'Kirim Kembali',
        corporationId: 'Perusahaan sedang Digunakan',
        siteId: 'Lokasi Kerja',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Daring',
        lastOnlineTime: 'Koneksi Terakhir',
        basicInfoManufacturer: 'Produsen',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Nomor Produk',
        basicInfoSerialNumber: 'Nomor Seri',
        controllerManufacturer: 'Produsen',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Versi Firmware',
        gnssReceiverMainManufacturer: 'Produsen',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Versi Firmware',
        gnssReceiverSubManufacturer: 'Produsen',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Versi Firmware',
        machineInfoCompanyName: 'Merek',
        machineInfoMachineType: 'Jenis Mesin',
        machineInfoMachineName: 'Nama Mesin',
        machineInfoMachineId: 'ID Mesin',
        rentalStatus: 'Penugasan',
        lastUpdated: 'Terakhir Diperbarui',
      },
      isError: {
        true: 'Format pemberitahuan status pengontrol tidak valid',
        false: 'Normal',
      },
      isOnline: {
        true: 'Daring',
        false: 'Luring',
      },
      machineInfoMachineType: {
        excavator: 'Ekskavator',
        roadroller: 'Road Roller',
        dozer: 'Doser',
        rigiddump: 'Truk Angkut Berat',
        wheelloader: 'Pemuat Beroda',
        grader: 'Grader',
        automobile: 'Mobil',
      },
      licenseMachineType: {
        excavator: 'Ekskavator',
        roadroller: 'Road Roller',
        dozer: 'Doser',
        rigiddump: 'Truk Angkut Berat',
        wheelloader: 'Pemuat Beroda',
        grader: 'Grader',
        automobile: 'Mobil',
        excavator_license: 'Ekskavator : %{licenseGrantInfo}',
        roadroller_license: 'Road Roller : %{licenseGrantInfo}',
        dozer_license: 'Doser : %{licenseGrantInfo}',
        rigiddump_license: 'Truk Angkut Berat : %{licenseGrantInfo}',
        wheelloader_license: 'Pemuat Beroda : %{licenseGrantInfo}',
        grader_license: 'Grader : %{licenseGrantInfo}',
        automobile_license: 'Mobil : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Mentransfer',
        sent: 'Ditransfer',
        failtosend: 'Transfer gagal',
      },
      rentalStatus: {
        approved: 'Disetujui',
        unapproved: 'Tidak Disetujui',
        returned: 'Kembali',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'Antena GNSS tidak terdeteksi',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'IMU Boom Tidak Terdeteksi',
          2: 'IMU Boom Tidak Terdeteksi',
          3: 'IMU Boom Tidak Terdeteksi',
        },
        armImuStatus: {
          0: '',
          1: 'IMU Lengan Tidak Terdeteksi',
          2: 'IMU Lengan Tidak Terdeteksi',
          3: 'IMU Lengan Tidak Terdeteksi',
        },
        bucketImuStatus: {
          0: '',
          1: 'IMU Keranjang Tidak Terdeteksi',
          2: 'IMU Keranjang Tidak Terdeteksi',
          3: 'IMU Keranjang Tidak Terdeteksi',
        },
        bodyImuStatus: {
          0: '',
          1: 'IMU Bodi Tidak Terdeteksi',
          2: 'IMU Bodi Tidak Terdeteksi',
          3: 'IMU Bodi Tidak Terdeteksi',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Sensor tekanan sisi kepala tidak terdeteksi',
          2: 'Sensor tekanan sisi kepala tidak terdeteksi',
          3: 'Sensor tekanan sisi kepala tidak terdeteksi',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Sensor tekanan sisi bawah tidak terdeteksi',
          2: 'Sensor tekanan sisi bawah tidak terdeteksi',
          3: 'Sensor tekanan sisi bawah tidak terdeteksi',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'IMU miring Tidak Terdeteksi',
          2: 'IMU miring Tidak Terdeteksi',
          3: 'IMU miring Tidak Terdeteksi',
        },
        secondBoomImuStatus: {
          0: '',
          1: 'IMU Boom ke-2 Tidak Terdeteksi',
          2: 'IMU Boom ke-2 Tidak Terdeteksi',
          3: 'IMU Boom ke-2 Tidak Terdeteksi',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Sensor Offset Tidak Terdeteksi',
          2: 'Sensor Offset Tidak Terdeteksi',
          3: 'Sensor Offset Tidak Terdeteksi',
          4: 'Sensor Offset Tidak Terdeteksi',
          5: 'Sensor Offset Tidak Terdeteksi',
          6: 'Sensor Offset Tidak Terdeteksi',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Sensor Ayun Tidak Terdeteksi',
          2: 'Sensor Ayun Tidak Terdeteksi',
          3: 'Sensor Ayun Tidak Terdeteksi',
          4: 'Sensor Ayun Tidak Terdeteksi',
          5: 'Sensor Ayun Tidak Terdeteksi',
          6: 'Sensor Ayun Tidak Terdeteksi',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Tiltrotator Tidak Terdeteksi',
          2: 'Tiltrotator Tidak Terdeteksi',
          3: 'Tiltrotator Tidak Terdeteksi',
        },
      },
    },
    retrofitRentals: {
      name: 'Penugasan',
      fields: {
        id: 'ID',
        retrofitId: 'ID Mesin',
        fromCorporationId: 'Menugaskan ID Perusahaan',
        fromCorporationName: 'Perusahaan yang Menugaskan',
        toCorporationId: 'Perusahaan yang Ditugaskan',
        toCorporationName: 'Perusahaan sedang Digunakan',
        retrofitRentalId: 'ID Penugasan',
        isApproved: 'Status',
        isReturned: 'Kembali',
        planStartDate: 'Tanggal Mulai Terjadwal',
        planEndDate: 'Tanggal Akhir Terjadwal',
        actualStartDate: 'Tanggal Mulai',
        actualEndDate: 'Tanggal Akhir',
        rentalUsers: 'Email',
        email: 'Email',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Disetujui',
        false: 'Tidak Disetujui',
      },
      rentalStatus: {
        approved: 'Disetujui',
        unapproved: 'Tidak Disetujui',
        returned: 'Kembali',
      },
    },
    pendingRentals: {
      name: 'Menunggu Persetujuan',
      fields: {
        fromCorporationName: 'Perusahaan yang Menugaskan',
        planStartDate: 'Tanggal Mulai Terjadwal',
        planEndDate: 'Tanggal Akhir Terjadwal',
      },
    },
    pendingTransfers: {
      name: 'Menunggu Persetujuan',
      fields: {
        fromCorporationName: 'Perusahaan yang Mentransfer',
        planDate: 'Tanggal Transfer Terjadwal',
        lastUpdated: 'Terakhir Diperbarui',
      },
    },
    pendingAlternateRegists: {
      name: 'Menunggu Persetujuan',
      fields: {
        fromCorporationName: 'Pemilik Saat Ini',
        lastUpdated: 'Terakhir Diperbarui',
      },
    },
    assignments: {
      name: 'Transfer',
      fields: {
        id: 'ID',
        retrofitId: 'ID Mesin',
        fromCorporationId: 'Mentransfer ID Perusahaan',
        fromCorporationName: 'Perusahaan yang Mentransfer',
        toCorporationId: 'Perusahaan yang Ditransfer',
        toCorporationName: 'Perusahaan',
        isApproved: 'Status',
        planDate: 'Tanggal Transfer Terjadwal',
        actualDate: 'Tanggal Transfer',
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Terakhir Diperbarui',
      },
      isApproved: {
        true: 'Disetujui',
        false: 'Tidak Disetujui',
      },
    },
    buckets: {
      name: 'Keranjang',
      fields: {
        companyName: 'Nama Perusahaan',
        siteId: 'Lokasi Kerja',
        name: 'Nama',
        corporationId: 'Perusahaan sedang Digunakan',
        bucketType: 'Jenis',
        bk3File: 'File BK3',
        'bk3File.title': 'File BK3',
        gltfFile: 'File Keranjang',
        uploadedBy: 'Didaftarkan Oleh',
        updatedBy: 'Diperbaharui Oleh',
        fileStatus: 'Status',
        lastUpdated: 'Terakhir Diperbarui',
        uploadBk: 'Unggah BK3',
      },
      bucketType: {
        slope: 'Kemiringan',
        standard: 'Standar',
        tilt: 'Miring',
        tiltrotator: 'Rotator Kemiringan',
      },
      uploadedBy: {
        admin: 'Admin',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Mengonversi',
        completed: 'Lengkap',
        error: 'Kesalahan',
      },
    },
    projects: {
      name: 'Proyek',
      fields: {
        name: 'Nama Proyek',
        coordinate: 'Sistem Koordinat',
        version: 'Versi',
        status: 'Status',
        lastUpdated: 'Terakhir Diperbarui',
        regionId: 'Wilayah',
        projectionId: 'Proyeksi',
        geoidId: 'Geoid',
        datumId: 'Data',
        file: 'File',
        retrofitId: 'Mesin Target',
      },
      status: {
        converted: 'Lengkap',
        waitforconvert: 'Mengonversi',
        error: 'Kesalahan',
      },
    },
    extensionarms: {
      name: 'Lengan Ekstensi',
      fields: {
        extensionarmType: 'Jenis',
        name: 'Nama',
        corporationId: 'Perusahaan sedang Digunakan',
        corporationName: 'Perusahaan sedang Digunakan',
        siteId: 'Lokasi Kerja',
        siteName: 'Lokasi Kerja',
        lastUpdated: 'Terakhir Diperbarui',
        extensionarmFile: 'File Lengan Ekstensi',
      },
      extensionarmType: {
        basic: 'Lengan Ekstensi',
        a: 'Tipe A',
        b: 'Tipe B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Bagikan Ke',
        name: 'Nama',
        extensionarmType: 'Jenis',
        extensionarmFile: {
          title: 'File Lengan Ekstensi',
        },
      },
    },
    corporations: {
      name: 'Perusahaan',
      fields: {
        name: 'Perusahaan sedang Digunakan',
      },
    },
    retrofitShareFiles: {
      name: 'Log Aplikasi',
      fields: {
        name: 'Nama File',
        fileTime: 'Tanggal dan Waktu Diterima',
        url: 'Unduh',
      },
    },
    retrofitConfigs: {
      name: 'Info Konfigurasi',
      fields: {
        timeStamp: 'Tanggal dan Waktu Diterima',
        url: 'Unduh',
      },
    },
    retrofitKitInfos: {
      name: 'Info Pengontrol',
      fields: {
        timeStamp: 'Tanggal dan Waktu Diterima',
        url: 'Unduh',
      },
    },
    retrofitAccuracies: {
      name: 'Hasil Pemeriksaan Akurasi',
      fields: {
        checkDateTime: 'Tanggal dan Waktu Konfirmasi',
        surveyPoint: 'Titik Terukur',
        positionN: 'Nilai Terukur : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Nilai Offset : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nama Titik Referensi',
        referencePointN: 'Titik Referensi : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Satuan Panjang',
      },
    },
    retrofitCalibs: {
      name: 'Info Kalibrasi',
      fields: {
        timeStamp: 'Tanggal dan Waktu Diterima',
        url: 'Unduh',
        detailInfo: {
          createDateTime: 'Buat tanggal dan waktu',
          updateDateTime: 'Perbarui tanggal dan waktu',
          machineInfo: {
            companyName: 'Merek',
            machineType: 'Jenis Mesin',
            machineName: 'Nama Mesin',
            machineId: 'ID Mesin',
            drumConfiguration: 'Konfigurasi Drum',
            guidanceMode: 'Mode Panduan',
          },
          machineGeometryInfo: {
            boomLength: 'Panjang Boom',
            secondBoomLength: 'Panjang Boom ke-2',
            armLength: 'Panjang Lengan',
            armTopPinToBucketSideLinkPinLength:
              'Jarak antara Lengan Atas dan Tautan Samping Keranjang',
            boomTopPinToBucketSideLinkPinLength:
              'Jarak antara Boom Atas dan Tautan Samping Keranjang',
            bucketLink1Length:
              'Jarak antara Sisi Keranjang dan Silinder Keranjang',
            bucketLink2Length:
              'Jarak antara Silinder Keranjang dan Tautan Keranjang',
            bodyCenterToBoomFootPin: {
              x: 'Pin Kaki Boom X terkait dengan Pusat Bodi',
              y: 'Pin Kaki Boom Y terkait dengan Pusat Bodi',
              z: 'Pin Kaki Boom Z terkait dengan Pusat Bodi',
            },
            drumWidth: 'Lebar Drum',
            length: 'Panjang',
            shoeInterval: 'Interval Sepatu',
            bladeWidthForDozer: 'Lebar Bilah Untuk Doser',
            distanceFromBodyToBlade: 'Jarak Dari Bodi Ke Bilah',
            frontToMainGnssAntenna: 'Antena Gnss Depan Ke Utama',
            frontToSubGnssAntenna: 'Antena Gnss Depan Ke Tambahan',
            centerToMainGnssAntenna: 'Antena Gnss Pusat Ke Utama',
            centerToSubGnssAntenna: 'Antena Gnss Pusat Ke Sub',
            groundToMainGnssAntenna: 'Antena Gnss Ground Ke Utama',
            groundToSubGnssAntenna: 'Antena Gnss Grounding Ke Sub',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Sudut Offset IMU Boom',
            secondBoomImuOffsetAngle: 'Sudut Offset IMU Boom ke-2',
            armImuOffsetAngle: 'Sudut Offset IMU Lengan',
            bucketImuOffsetAngle: 'Sudut Offset IMU Keranjang',
            bodyImuOffsetRollAngle: 'Sudut Roll Offset IMU Bodi ',
            bodyImuOffsetPitchAngle: 'Sudut Pitch Offset IMU Bodi',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'X GNSS Utama terkait dengan Pusat Bodi',
                y: 'Y GNSS Utama terkait dengan Pusat Bodi',
                z: 'Z GNSS Utama terkait dengan Pusat Bodi',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'X Sub GNSS terkait dengan Pusat Bodi',
                y: 'Y Sub GNSS terkait dengan Pusat Bodi',
                z: 'Z Sub GNSS terkait dengan Pusat Bodi',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Model Boom Ayun',
            twoPieceBoom: 'Model Boom 2 Bagian',
            minimalSwingRadius: 'Model Boom Offset',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Tinggi Mesin',
            },
            bodyImu1: {
              roll: 'Roll IMU1 Bodi',
              pitch: 'Pitch IMU1 Bodi',
              yaw: 'Yaw IMU1 Bodi',
            },
            bodyImu2: {
              roll: 'Roll IMU2 Bodi',
              pitch: 'Pitch IMU2 Bodi',
              yaw: 'Yaw IMU2 Bodi',
            },
            frontLeft: {
              x: 'X Kiri Depan',
              y: 'Y Kiri Depan',
              z: 'Z Kiri Depan',
            },
            frontRight: {
              x: 'X Kanan Depan',
              y: 'Y Kanan Depan',
              z: 'Z Kanan Depan',
            },
            frontGroundingEnd: {
              x: 'X Ujung Grounding Depan',
              y: 'Y Ujung Grounding Depan',
              z: 'Z Ujung Grounding Depan',
            },
            rearGroundingEnd: {
              x: 'X Ujung Grounding Belakang',
              y: 'Y Ujung Grounding Belakang',
              z: 'Z Ujung Permukaan Belakang',
            },
            mainGnssAntenna: {
              x: 'X Antena Gnss Utama',
              y: 'Y Antena Gnss Utama',
              z: 'Z Antena Gnss Utama',
            },
            subGnssAntenna: {
              x: 'X Antena Gnss Tambahan',
              y: 'Y Antena Gnss Tambahan',
              z: 'Z Antena Gnss Tambahan',
            },
            prismPoleOffset: 'Offset Tiang Prisma',
            antennaElectricalCenterHeight: 'Ketinggian Pusat Listrik Antena',
          },
        },
      },
    },
    retrofitBasicSettings: {
      name: 'Pengaturan Dasar',
      fields: {
        timeStamp: 'Tanggal dan Waktu Diterima',
        url: 'Unduh',
      },
    },
    retrofitErrors: {
      name: 'Info Kesalahan',
      fields: {
        notifiedTime: 'Tanggal dan Waktu Kejadian',
        errorCodes: 'Kode Kesalahan: Detail',
      },
    },
    retrofitToposurveys: {
      name: 'Daftar Survei Topografi',
      fields: {
        surveyDate: 'Tanggal dan Waktu Survei',
        projectName: 'Nama Proyek',
        layerName: 'Lapisan',
        pointName: 'Nama Titik',
        surveyPoint: 'Titik Terukur',
        n: 'Nilai Terukur : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Satuan Panjang',
      },
    },
    asbuilts: {
      name: 'Hasil Konstruksi',
      fields: {
        execStartDate: 'Eksekusi Mulai',
        execEndDate: 'Eksekusi Berakhir',
        startPeriod: 'Periode Akuisisi Dari',
        endPeriod: 'Periode Akuisisi Sampai',
        execStatus: 'Status',
        url: '',
        csvType: 'Format Data',
        createUser: 'buat Pengguna',
      },
      execStatus: {
        complete: 'Lengkap',
        inprogress: 'Pemrosesan',
        error: 'Kesalahan',
        completewithoutdata: 'Selesai(Tidak Ada Data)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'waktu yang ditetapkan',
      },
    },
    retrofitBasicInfos: {
      name: 'Info Dasar',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributor',
        retrofitRegionId: 'Wilayah',
        corporationId: 'ID Perusahaan',
        corporationName: 'Nama Perusahaan',
        basicInfoManufacturer: 'Produsen',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Nomor Produk',
        basicInfoSerialNumber: 'Nomor Seri',
        lastUpdated: 'Terakhir Diperbarui',
        description: 'Deskripsi',
        ownerId: 'ID Perusahaan',
        ownerName: 'Nama Perusahaan',
      },
      tag: {
        retrofitBasicInfosDetail: 'Info Dasar',
        sendBack: 'Kirim Kembali',
      },
      sendBack: {
        fields: {
          site: 'Lokasi Kerja',
          corporation: 'Perusahaan sedang Digunakan',
          ntripSettingInfo: 'ID Ntrip',
          toRetrofit: 'Pengontrol Alternatif',
          notificationEmailAddress: 'Email',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Pendaftaran Massal',
      fields: {
        uploadFileName: 'Nama File',
        execStartDate: 'Tanggal dan Waktu Mulai Eksekusi',
        execEndDate: 'Tanggal dan Waktu Berakhirnya Eksekusi',
        execStatus: 'Status',
        uploadFile: 'File Terdaftar',
        resultFile: 'Hasil Pendaftaran',
      },
      execStatus: {
        complete: 'Lengkap',
        inprogress: 'Pemrosesan',
        error: 'Kesalahan',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'AdminCompanyBulkAction',
      fields: {
        uploadFileName: 'Nama File',
        execStartDate: 'Tanggal dan Waktu Mulai Eksekusi',
        execEndDate: 'Tanggal dan Waktu Berakhirnya Eksekusi',
        execStatus: 'Status',
        uploadFile: 'File Terdaftar',
        resultFile: 'Hasil Pendaftaran',
      },
      execStatus: {
        complete: 'Lengkap',
        inprogress: 'Pemrosesan',
        error: 'Kesalahan',
      },
    },
    tasks: {
      name: 'Tugas',
      fields: {
        name: 'Nama',
        status: 'Status',
        description: 'Deskripsi',
        projectSourceLayerId: 'Lapisan',
        retrofitIds: 'Mesin Target',
        expiredDate: 'Tanggal Kedaluwarsa ',
        createDate: 'Data Kedaluwarsa',
        rectangle: 'Rentang yang Didukung',
        lastUpdated: 'Terakhir Diperbarui',
      },
      status: {
        error: 'Kesalahan',
        converting: 'Mengonversi',
        sending: 'Mentransfer',
        sent: 'Ditransfer',
        failtosend: 'Transfer gagal',
      },
    },
    compactionMaterials: {
      name: 'Bahan',
      fields: {
        name: 'Nama',
      },
    },
    weathers: {
      name: 'Cuaca',
      fields: {
        name: 'Nama',
      },
    },
    operators: {
      name: 'Operator',
      fields: {
        name: 'Nama',
      },
    },
    compactionWorkAreas: {
      name: 'Wilayah Kerja Pemadatan',
      fields: {
        name: 'Nama Wilayah Kerja',
        blockSize: 'Ukuran Blok',
        description: 'Deskripsi',
      },
    },
    compactionLayers: {
      name: 'Lapisan Pemadatan',
      fields: {
        name: 'Nama',
        thickness: 'Ketebalan',
        passCount: 'Jumlah Lewatan',
        compactionMaterialId: 'Bahan',
        inUse: 'Status',
        description: 'Deskripsi',
      },
      inUse: {
        true: 'digunakan',
        false: 'tidak terpakai',
      },
    },
    gnssSettings: {
      name: 'Pengaturan Ntrip',
      fields: {
        registCorporationName: 'Didaftarkan oleh',
        ownerCorporationId: 'ID Perusahaan',
        ownerCorporationName: 'Nama Perusahaan',
        name: 'Nama',
        url: 'URL Server',
        portnum: 'Port',
        userId: 'ID',
        password: 'Kata Sandi',
        retrofitId: 'Mesin Target',
        status: 'Status',
        updateDate: 'Terakhir Diperbarui',
      },
      status: {
        waitforsend: 'Mengonversi',
        sending: 'Mentransfer',
        sent: 'Ditransfer',
        failtosend: 'Transfer gagal',
      },
    },
    supportMode: {
      name: 'Mode Dukungan',
      fields: {
        enabled: 'Aktifkan Mode Dukungan',
        corporationId: 'ID Perusahaan',
        corporationName: 'Nama Perusahaan',
        siteId: 'ID Lokasi Kerja',
        siteName: 'Lokasi Kerja',
        latitude: 'Garis Lintang',
        longitude: 'Garis Bujur',
        workPeriodStartDate: 'Periode Konstruksi Aktual (Tanggal mulai)',
        workPeriodEndDate: 'Periode Konstruksi Aktual (Tanggal Berakhir)',
        photoUrl: 'URL Gambar Situs',
        description: 'Deskripsi',
      },
    },
    userNotices: {
      name: 'Pemberitahuan',
      noticeType: {
        emergencyimportant: 'Darurat/Penting',
        emergency: 'Keadaan darurat',
        important: 'Penting',
      },
      fields: {
        noticeType: 'Kepentingan',
        noticeTypes: 'Kepentingan',
        noticeCategory: 'Jenis Pemberitahuan',
        title: 'Judul',
        content: 'Konten',
        publicationStartDate: 'Tanggal dan Waktu Mulai (UTC)',
        publicationEndDate: 'Tanggal dan Waktu Berakhir (UTC)',
        file: 'Lampiran',
        language: 'Bahasa',
        emergency: 'Keadaan darurat',
        important: 'Penting',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versi',
        compatibilityLevel: 'Tingkat Kompatibilitas',
        firmwareFile: 'File Firmware',
        firmwareFileCheckSum: 'Jumlah Pemeriksaan Firmware',
        firstInitializationFile: 'File Inisialisasi 1',
        firstInitializationFileCheckSum: 'CheckSum File Inisialisasi 1',
        secondInitializationFile: 'File Inisialisasi 2',
        secondInitializationFileCheckSum: 'CheckSum File Inisialisasi 2',
      },
    },
    bucketShares: {
      name: 'Berbagi Keranjang',
      fields: {
        fromBucketId: 'Dari Id Keranjang',
        corporationId: 'ID Perusahaan',
        name: 'Nama',
      },
    },
    extensionarmShare: {
      name: 'Berbagi Lengan Ekstensi',
      fields: {
        name: 'Nama',
      },
    },
    retrofitCorporationLicenses: {
      name: 'Lisensi',
      fields: {
        licenseId: 'ID Lisensi',
        licenseType: 'Jenis',
        issuedAt: 'Tanggal Penerbitan',
        expiresAt: 'Tanggal Kedaluwarsa ',
        retrofitId: 'Mesin',
        comment: 'Deskripsi',
        licenseStatus: 'Status',
        licensePermission: 'Lisensi',
        productType: 'Nama Produk',
      },
      licenseStatus: {
        unlicense: 'Tidak dialokasikan',
        disable: 'Tidak Valid',
        enable: 'Valid',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Nama Perusahaan',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Satuan Panjang',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Jenis',
        name: 'Nama',
        dimension: 'Dimensi',
        trigger: 'Pemicu',
        valid: 'Valid',
        alertType: 'Jenis Peringatan',
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Akhir',
        transparency: 'Transparan',
        elevation: 'Elevasi',
        height: 'Tinggi',
        radius: 'Radius',
        lastUpdated: 'Terakhir Diperbarui',
        points: 'Rentang yang Didukung',
        thickness: 'Ketebalan',
      },
      type: {
        line: 'Garis',
        polygon: 'Poligon',
        circle: 'Lingkaran',
        point: 'Titik',
        wall: 'Dinding',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Valid',
        false: 'Tidak Valid',
      },
      alertType: {
        attention: 'Perhatian',
        notice: 'Informasi',
        warning: 'Peringatan',
        caution: 'Waspada',
        danger: 'Bahaya',
      },
      transparency: {
        true: 'Transparan',
        false: 'Buram',
      },
    },
    geofenceAlerts: {
      name: 'Peringatan',
      fields: {
        timeStamp: 'Tanggal dan Waktu Deteksi',
        geofenceName: 'Nama Geofence',
        alertType: 'Jenis Peringatan',
        collisionPoint: 'Titik Tabrakan',
        retrofit: {
          machineInfoMachineName: 'Mesin',
        },
      },
      alertType: {
        attention: 'Perhatian',
        notice: 'Informasi',
        warning: 'Peringatan',
        caution: 'Waspada',
        danger: 'Bahaya',
      },
    },
    ntrips: {
      name: 'Manajemen Ntrip',
      fields: {
        name: 'ID Pengguna',
        ntripPass: 'Kata Sandi',
        serial: 'Nomor Seri',
        customerCorporationId: 'ID Perusahaan',
        customerCorporationName: 'Nama Perusahaan',
        ntrip: 'Status',
        numberOfExchanges: 'Jumlah Pertukaran',
        id: 'ID',
        licenceId: 'ID Lisensi',
        dateOfRegistration: 'Tanggal Pendaftaran',
        billingToCustomers: 'Penagihan Kepada Pelanggan',
        paymentToKomatsu: 'Pembayaran Ke Komatsu',
        applicantId: 'ID Pemohon',
        applicant: 'Pemohon',
        requestCorporationId: 'Minta ID Perusahaan',
        requestCorporationName: 'Minta Nama Perusahaan',
        isUsed: 'Digunakan',
      },
      ntrip: {
        publish: 'Terbayar',
        stop: 'Berhenti',
        delete: 'Dihapus',
      },
    },
    retrofitAlternateRegists: {
      name: 'Pendaftaran Proksi Pengontrol',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fromCompanyId',
        fromCorporationName: 'Pendaftaran Proksi Pengontrol',
        toCorporationId: 'Pengguna',
        toCorporationName: 'Nama Perusahaan',
        isApproved: 'Status',
        createDate: 'Tanggal Aplikasi',
        actualDate: 'Tanggal yang Disetujui',
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Terakhir Diperbarui',
      },
      isApproved: {
        true: 'Disetujui',
        false: 'Tidak Disetujui',
      },
    },
    pendingApprovals: {
      name: 'Menunggu Persetujuan',
      fields: {
        approvalType: 'Jenis',
        requestedBy: 'Perusahaan Pemohon',
        lastUpdated: 'Terakhir Diperbarui',
      },
      approvalType: {
        assignment: 'Penugasan',
        transfer: 'Transfer',
        agent: 'Pendaftaran Proksi Pengontrol',
      },
    },
    retrofitSendbacks: {
      name: 'Kirim Kembali',
      fields: {
        fromRetrofitId: 'Dari Retrofit',
        toRetrofitId: 'Pengontrol Alternatif',
        notificationEmailAddress: 'Email',
        siteId: 'Lokasi Kerja',
        corporationId: 'ID Perusahaan',
        corporationName: 'Perusahaan sedang Digunakan',
        ntripId: 'Info Ntrip',
        isComplete: 'Pengiriman Kembali Selesai',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
