import { isEmpty } from 'lodash';
import { AdminApiConverter, ApiFilter } from '..';
import { AsbuiltTimeData } from '../../types';

const converter: AdminApiConverter<'asbuiltTime', 'asbuiltTime'> = {
  resource: 'asbuiltTimes',
  defaultSort: { field: 'startTime', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'asbuiltTime'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('retrofitId' in filter) apiFilter.retrofitId = filter.retrofitId;
    if ('startTime' in filter) apiFilter.startTime = filter.startTime;
    if ('endTime' in filter) apiFilter.endTime = filter.endTime;

    return apiFilter;
  },
  sortFieldToApi: field => {
    return field;
  },
  recordsFromApi: records =>
    Promise.resolve(
      records.map((record): AsbuiltTimeData => {
        return {
          id: record.id || '',
          siteId: record.siteId || '',
          retrofitId: record.retrofitId,
          startTime: record.startTime || '',
          endTime: record.endTime || '',
        };
      }),
    ),
  recordToApi: record => ({
    siteId: record.siteId,
    startTime: new Date(record.startTime).toISOString(),
    endTime: isEmpty(record.endTime)
      ? new Date(record.endTime).toISOString()
      : '',
  }),
};

export default converter;
