import React, { ChangeEvent, useEffect, useState } from 'react';
import { required, Loading, useTranslate, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import {
  Create,
  CustomForm,
  BreadcrumbsFiled,
  DateTimePickerInput,
  SelectInput,
} from '../../../..';
import {
  useResource,
  useCreateInSite,
  useGetAccount,
  useAccount,
  useGetSelefDetailData,
  useGetListAllPage,
} from '../../../../../hooks';
import { AsbuiltData, AsbuiltTimeData } from '../../../../../dataProvider';
import { config } from '../../../../../utils';
import { useGetSiteName } from '../../../retrofits/info/hooks';
import { getRetrofitInfoBreadcrumbs } from '../../../retrofits/info/util';

interface ApiResult {
  isOwnData: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  breadcrumbs: string[];
  asbuiltTimes: AsbuiltTimeData[];
}

const SiteRetrofitAsbuiltCreate: React.FC = () => {
  const { props, site } = useCreateInSite('asbuilts');
  const resource = useResource('asbuilts');
  const { siteId: urlSiteId, retrofitId: strRetrofitId } =
    useParams<{ siteId: string; retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  const translate = useTranslate();
  //  getEmailは、fakedataの場合のみ参照
  const { getEmail } = useGetAccount();

  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  const getListAllPage = useGetListAllPage();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  const [dateTimeState, setDateTimeState] = useState<{
    startTime?: Date;
    endTime?: Date;
  }>({
    startTime: undefined,
    endTime: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ loading: true, fetched: true });
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // retrofits
      const listFileter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };
      // 建機一覧から閲覧可能なレトロフィットであるか判定
      const retrofit = await getSelefDetailData(
        'retrofits',
        listFileter,
        retrofitId,
      );

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: urlSiteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // 画面のSelectで使用するasbuiltTimeデータ
      const asbuiltTimeRecords = await getListAllPage({
        resource: 'asbuiltTimes',
        filter: { retrofitId },
        sort: {
          field: 'startTime',
          order: 'DESC',
        },
      });
      // Record型で受け取っているため、型を成形
      const asbuiltTimes = asbuiltTimeRecords.data as AsbuiltTimeData[];

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      const {
        data: { siteName },
      } = await getSiteName({ siteId: urlSiteId });

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            siteAttachedData: !!siteAttachedRetrofit,
            fetchedRetrofitId: retrofitId,
            breadcrumbs,
            asbuiltTimes,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({
        loading: false,
        fetched: true,
        data: apiResult,
      });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    getListAllPage,
    retrofitId,
    urlSiteId,
    apiState,
  ]);

  if (!site || apiState.loading || !apiState.data) return <Loading />;
  const { id: siteId, corporationId } = site;
  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { isOwnData, siteAttachedData, breadcrumbs, asbuiltTimes } =
    apiState.data;
  // 建機一覧に存在しないretrofitの基本設定情報は表示させない
  // 現場詳細画面から遷移する場合、対象現場に紐づかないretrofitの基本設定情報は表示させない
  if (siteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  // validation 処理
  const msgStartPeriod = translate('resources.asbuilts.fields.startPeriod');
  const msgEndPeriod = translate('resources.asbuilts.fields.endPeriod');

  // 日付判定用funciton
  function nomalFromToDate(start: string, end: string): boolean {
    return new Date(start) <= new Date(end);
  }

  const startPeriodValidation = (unusedValue: any, allValues: AsbuiltData) => {
    const fromDate = allValues.startPeriod;
    const toDate = allValues.endPeriod;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validBeforeDate',
      args: {
        target: msgStartPeriod,
        before: msgEndPeriod,
      },
    };
  };

  const endPeriodValidation = (unusedValue: any, allValues: AsbuiltData) => {
    const fromDate = allValues.startPeriod;
    const toDate = allValues.endPeriod;

    if (!fromDate || !toDate) {
      return null;
    }
    if (nomalFromToDate(fromDate, toDate)) {
      return null;
    }
    return {
      message: 'admin.validation.validAfterDate',
      args: {
        target: msgEndPeriod,
        after: msgStartPeriod,
      },
    };
  };

  // fakedataの場合は以下のCreateUserが必要
  const createUser = config.api.landlog.useFake ? getEmail() : undefined;

  // タイムセット変更イベントハンドラ：タイムセット選択時に取得期間(開始/終了)に渡す値を更新
  const onChangeAsbuiltTimeSelectEvent = ({
    target: { value: id },
  }: ChangeEvent<HTMLInputElement>) => {
    const asbuiltTime = asbuiltTimes.find(f => f.id === id);
    if (!asbuiltTime) {
      setDateTimeState({ startTime: undefined, endTime: undefined });
      return;
    }
    const startTime = new Date(asbuiltTime.startTime);
    const endTime = asbuiltTime.endTime
      ? new Date(asbuiltTime.endTime)
      : undefined;
    setDateTimeState({ startTime, endTime });
  };
  // 取得期間(開始)変更イベントハンドラ：取得期間(開始)に渡した値から変更される場合はタイムセット選択をクリア
  const onChangeStartPeriod = (value: Date | null, form: any) => {
    if (value?.getTime() !== dateTimeState.startTime?.getTime()) {
      form.change('id', undefined);
      setDateTimeState({ startTime: undefined, endTime: undefined });
    }
  };
  // 取得期間(終了)変更イベントハンドラ：取得期間(終了)に渡した値から変更される場合はタイムセット選択をクリア
  const onChangeEndPeriod = (value: Date | null, form: any) => {
    if (value?.getTime() !== dateTimeState.endTime?.getTime()) {
      form.change('id', undefined);
      setDateTimeState({ startTime: undefined, endTime: undefined });
    }
  };

  // Select内の表示
  const selectOptionTextRenderer = (choice: {
    startTime: any;
    endTime: any;
  }) => {
    const toLocalStringOption: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    };
    const strStartTime = new Date(choice.startTime).toLocaleString(
      undefined,
      toLocalStringOption,
    );
    const strEndTime = choice.endTime
      ? new Date(choice.endTime).toLocaleString(undefined, toLocalStringOption)
      : '';
    return `${strStartTime} ~ ${strEndTime}`;
  };

  return (
    <Create {...props}>
      <CustomForm
        title="admin.pages.asbuiltsRetrofitCreate"
        resource="asbuilts"
        initialValues={{
          siteId,
          corporationId,
          createUser,
          retrofitIds: [retrofitId],
        }}
      >
        <BreadcrumbsFiled
          breadcrumbs={[...breadcrumbs, 'resources.asbuilts.name']}
          label="ra.action.create"
        />
        <SelectInput
          resource="asbuiltTime"
          source="id"
          choices={asbuiltTimes}
          resettable={false}
          optionText={selectOptionTextRenderer}
          onChange={onChangeAsbuiltTimeSelectEvent}
        />
        <DateTimePickerInput
          resource={resource}
          source="startPeriod"
          validate={[
            required('admin.validation.invalidDateTimeFormat'),
            startPeriodValidation,
          ]}
          inputProps={
            dateTimeState.startTime
              ? {
                  inputValue: dateTimeState.startTime,
                  handleChange: onChangeStartPeriod,
                }
              : undefined
          }
        />
        <DateTimePickerInput
          resource={resource}
          source="endPeriod"
          validate={[
            required('admin.validation.invalidDateTimeFormat'),
            endPeriodValidation,
          ]}
          inputProps={
            dateTimeState.endTime
              ? {
                  inputValue: dateTimeState.endTime,
                  handleChange: onChangeEndPeriod,
                }
              : undefined
          }
        />
        <SelectInput
          resource={resource}
          source="csvType"
          optionText="name"
          choices={[
            { id: 'NEZ', name: 'NEZ' },
            { id: 'ENZ', name: 'ENZ' },
          ]}
          validate={required()}
        />
      </CustomForm>
    </Create>
  );
};

SiteRetrofitAsbuiltCreate.displayName = 'SiteRetrofitAsbuiltCreate';
export default SiteRetrofitAsbuiltCreate;
