import { DataProvider, UpdateResult } from 'react-admin';
import { omitBy, isUndefined } from 'lodash';
import {
  getRestDataProvider,
  adminApiConverter,
  BaseApiConverter,
  getConfigList,
} from '.';
import { logger } from '../../utils';

const getApi = (resource: string) => {
  if (
    resource !== 'asbuilts' &&
    resource !== 'asbuiltTimes' &&
    resource !== 'assignments' &&
    resource !== 'bucketNames' &&
    resource !== 'buckets' &&
    resource !== 'bucketShares' &&
    resource !== 'compactionLayers' &&
    resource !== 'compactionMaterials' &&
    resource !== 'compactionWorkAreas' &&
    resource !== 'customers' &&
    resource !== 'firmwares' &&
    resource !== 'datums' &&
    resource !== 'extensionarms' &&
    resource !== 'extensionarmShares' &&
    resource !== 'geofences' &&
    resource !== 'geofenceAlerts' &&
    resource !== 'geoids' &&
    resource !== 'gnssSettings' &&
    resource !== 'landlogapps' &&
    resource !== 'licenseAgreements' &&
    resource !== 'ntrips' &&
    resource !== 'operators' &&
    resource !== 'projections' &&
    resource !== 'projectNames' &&
    resource !== 'projects' &&
    resource !== 'regions' &&
    resource !== 'retrofitAccuracies' &&
    resource !== 'retrofitAdminCorporationBulkActions' &&
    resource !== 'retrofitAlternateRegists' &&
    resource !== 'retrofitAwaitingApprovals' &&
    resource !== 'retrofitBulkActions' &&
    resource !== 'retrofitByTasks' &&
    resource !== 'retrofitCorporationLicenses' &&
    resource !== 'retrofitDistributors' &&
    resource !== 'retrofitRegions' &&
    resource !== 'retrofitErrors' &&
    resource !== 'retrofitManageCorporations' &&
    resource !== 'retrofitOwnerRegistCorporations' &&
    resource !== 'retrofitOwnerChangeCorporations' &&
    resource !== 'retrofitRentals' &&
    resource !== 'retrofitSendbacks' &&
    resource !== 'retrofits' &&
    resource !== 'retrofitCalibs' &&
    resource !== 'retrofitToposurveys' &&
    resource !== 'retrofitShareFiles' &&
    resource !== 'siteBuriedStructures' &&
    resource !== 'siteConfigures' &&
    resource !== 'tasks' &&
    resource !== 'termsConditions' &&
    resource !== 'termsConditionDocuments' &&
    resource !== 'userConfigures' &&
    resource !== 'userNotices' &&
    resource !== 'userNoticeStatus' &&
    resource !== 'userPermissions' &&
    resource !== 'weathers'
  ) {
    throw new Error(`The resource is not supported: ${resource}`);
  }
  const converter = adminApiConverter()[
    resource
  ] as unknown as BaseApiConverter;
  const { embed } = converter;
  const dataProvider = getRestDataProvider(embed);
  return { converter, dataProvider };
};

const adminDataProvider: DataProvider = {
  getList: async (resource, params): Promise<any> => {
    if (
      resource === 'retrofitConfigs' ||
      resource === 'retrofitKitInfos' ||
      resource === 'retrofitCalibs' ||
      resource === 'retrofitBasicSettings'
    )
      return getConfigList(resource, params);
    const { converter, dataProvider } = getApi(resource);
    const sort =
      params.sort.field === 'id' ? converter.defaultSort : params.sort;
    const apiParams = {
      filter: converter.filterToApi(params.filter),
      sort: {
        ...sort,
        field: converter.sortFieldToApi(sort.field),
      },
      pagination: params.pagination,
    };
    const result = await dataProvider.getList(converter.resource, apiParams);
    return {
      ...result,
      data: await converter.recordsFromApi(result.data),
    };
  },
  getOne: async (resource, params): Promise<any> => {
    if (resource === 'retrofitCalibs') {
      const { converter } = getApi(resource);
      const dataProvider = getRestDataProvider(['detailInfo']);
      const result = await dataProvider.getOne('retrofitCalibs', params);
      if (!result) throw new Error(`Can't file ${resource} for id ${params}`);
      const [data] = await converter.recordsFromApi([result.data]);
      return { data };
    }
    const { converter, dataProvider } = getApi(resource);
    const result = await dataProvider.getOne(converter.resource, params);
    const [data] = await converter.recordsFromApi([result.data]);
    return { data };
  },
  getMany: async (resource, params): Promise<any> => {
    const { converter, dataProvider } = getApi(resource);
    const result = await dataProvider.getMany(converter.resource, params);
    return {
      data: await converter.recordsFromApi(result.data),
    };
  },
  getManyReference: async (resource, params): Promise<any> => {
    const { converter, dataProvider } = getApi(resource);
    const apiParams = {
      target: params.target,
      id: params.id,
      filter: converter.filterToApi(params.filter),
      sort: {
        field: converter.sortFieldToApi(params.sort.field),
        order: params.sort.order,
      },
      pagination: params.pagination,
    };
    const result = await dataProvider.getManyReference(
      converter.resource,
      apiParams,
    );
    return {
      ...result,
      data: await converter.recordsFromApi(result.data),
    };
  },
  update: async (resource, params): Promise<any> => {
    if (['termsConditions', 'termsConditionDocuments'].includes(resource)) {
      throw new Error('This API does not support `create` or `update`.');
    }
    const {
      converter: {
        resource: convResource,
        recordToApi,
        recordsFromApi,
        prepareParam,
        postResult,
      },
      dataProvider,
    } = getApi(resource);
    const paramData = prepareParam
      ? await prepareParam(params.data)
      : params.data;
    const apiParams = {
      ...params,
      data: recordToApi(paramData),
      previousData: { id: params.id, ...recordToApi(params.previousData) },
    };
    const result = await dataProvider.update(convResource, apiParams);
    const resultData = postResult
      ? await postResult({
          request: paramData,
          response: result.data,
        })
      : result.data;
    const [data] = await recordsFromApi([resultData]);
    return { data };
  },
  updateMany: async (resource, params) => {
    if (['termsConditions', 'termsConditionDocuments'].includes(resource)) {
      throw new Error('This API does not support `create` or `update`.');
    }
    const { converter, dataProvider } = getApi(resource);
    const apiData = converter.recordToApi(params.data);
    const patchData = omitBy(apiData, isUndefined);
    const { data: prevData } = await dataProvider.getMany(converter.resource, {
      ids: params.ids,
    });

    if (resource === 'retrofitCorporationLicenses') {
      // retrofitCorporationLicensesをupdatemanyする際は、直列処理として実施する
      const result = [] as UpdateResult[];

      // eslint-disable-next-line no-restricted-syntax
      for await (const data of prevData) {
        const updateData = { ...data, ...patchData };

        const updateResult = await dataProvider.update(converter.resource, {
          id: data.id,
          data: updateData,
          previousData: data,
        });
        result.push(updateResult);
      }
      return { data: result.map(({ data }) => data.id) };
    }

    const result = await Promise.all(
      prevData.map(data => {
        let updateData = { ...data, ...patchData };
        // 更新対象がretrofitsかつpatchDataにadminCorporationsが含まれている場合
        if (
          resource === 'retrofits' &&
          Object.prototype.hasOwnProperty.call(patchData, 'adminCorporations')
        ) {
          // 更新対象となる元データにadminCorporationsが設定されている場合
          if (
            Object.prototype.hasOwnProperty.call(data, 'adminCorporations') &&
            !!data.adminCorporations
          ) {
            // 上書きではなく末尾に追加する
            const settingAdminCorporations = [
              ...data.adminCorporations,
              ...patchData.adminCorporations,
            ];
            updateData = {
              ...data,
              ...patchData,
              adminCorporations: settingAdminCorporations,
            };
          }
        }
        return dataProvider.update(converter.resource, {
          id: data.id,
          data: updateData,
          previousData: data,
        });
      }),
    );
    return { data: result.map(({ data }) => data.id) };
  },
  create: async (resource, params): Promise<any> => {
    if (['termsConditions', 'termsConditionDocuments'].includes(resource)) {
      throw new Error('This API does not support `create` or `update`.');
    }
    const {
      converter: {
        resource: convResource,
        recordToApi,
        recordsFromApi,
        prepareParam,
        postResult,
      },
      dataProvider,
    } = getApi(resource);
    const paramData = prepareParam
      ? await prepareParam(params.data)
      : params.data;
    const apiParams = {
      ...params,
      data: recordToApi(paramData),
    };
    const result = await dataProvider.create(convResource, apiParams);
    const resultData = postResult
      ? await postResult({
          request: paramData,
          response: result.data,
        })
      : result.data;
    const [data] = await recordsFromApi([resultData]);
    return { data };
  },
  delete: async (resource, params): Promise<any> => {
    const { converter, dataProvider } = getApi(resource);
    const result = await dataProvider.delete(converter.resource, params);
    const [data] = result.data
      ? await converter.recordsFromApi([result.data])
      : [{ id: params.id }];
    return { data };
  },
  deleteMany: async (resource, params) => {
    const { converter, dataProvider } = getApi(resource);
    const result = await dataProvider.deleteMany(converter.resource, params);
    logger().debug('lintエラー回避用ログ出力deleteMany', result);
    return result;
  },
};

export default adminDataProvider;
