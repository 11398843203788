import { AsbuiltTimeData } from '../../types';

const asbuiltTimes: AsbuiltTimeData[] = [
  {
    id: '11743_2024-09-01T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-09-01T00:00:00Z',
    endTime: '2024-09-01T01:05:00Z',
  },
  {
    id: '11743_2024-09-02T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-09-02T10:00:00Z',
    endTime: '2024-09-02T11:10:00Z',
  },
  {
    id: '11743_2024-09-03T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-09-03T10:00:00Z',
    endTime: '2024-09-03T11:00:00Z',
  },
  {
    id: '11743_2024-09-04T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-09-04T10:00:00Z',
    endTime: '2024-09-04T11:00:00Z',
  },
  {
    id: '11743_2024-10-10T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-10-10T10:00:00Z',
    endTime: '2024-10-11T11:00:00Z',
  },
  {
    id: '11743_2024-10-15T10:00:00Z',
    retrofitId: 6,
    siteId: '65a5a0fc-ae01-4fae-8f8a-406faf1fb0e4',
    startTime: '2024-10-15T10:00:00Z',
    endTime: '',
  },
];

export default asbuiltTimes;
