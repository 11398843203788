import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { colors, getCommonTheme } from '.';
import {
  inputWidth,
  inputHeight,
  autocompleteWidth,
  autocompleteInnerWidth,
  siteAutocompleteWidth,
  siteAutocompleteInnerWidth,
} from './define';

const theme: ThemeOptions = {
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: colors.backgroud,
        borderCollapse: 'separate',
        borderSpacing: '0px 12px',
        overflowWrap: 'break-word',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: colors.backgroud,
        // 全レコードチェック用のボタンは表示しない
        '& .MuiCheckbox-root': {
          visibility: 'hidden',
        },
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: colors.paper,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'initial',
        padding: '0px 24px 0px 24px',
        height: '60px',
        '& .MuiCheckbox-root.Mui-checked': {
          backgroundColor: colors.checkBox.backgroundColor,
        },
        '& .MuiTypography-root': {
          WebkitLineClamp: '3',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        },
      },
      head: {
        color: colors.text.primaryLight,
        borderColor: colors.line,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        height: '36px',
      },
      body: {
        color: colors.text.secondaryLight,
      },
    },
    MuiTabs: {
      root: {
        marginTop: 10,
        minHeight: 40,
        height: 40,
        borderBottom: '1px solid #000344',
      },
      indicator: {
        height: '100%',
        backgroundColor: colors.selectedMenu,
        borderRadius: '3px 3px 0 0',
      },
    },
    MuiTab: {
      root: {
        minWidth: 184,
        width: 184,
        minHeight: 40,
        padding: 0,
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'capitalize',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: '#565664',
        marginRight: 1,
      },
      wrapper: {
        zIndex: 1, // カレントを示すハイライト用DOMが同階層の末尾に存在し、タブ文言のzIndexを上げないと見えなくなる為
      },
      textColorInherit: {
        color: colors.text.primaryLight,
      },
    },
    MuiTableRow: {
      hover: {
        boxShadow: '0px 3px 6px #00000029',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.line,
      },
    },
    MuiCheckbox: {
      root: {
        '&.MuiCheckbox-colorPrimary': {
          '&.Mui-checked': {
            color: colors.text.primaryLight,
          },
        },
      },
    },
    MuiRadio: {
      root: {
        '&.MuiRadio-colorPrimary': {
          '&.Mui-checked': {
            color: colors.text.primaryLight,
          },
        },
      },
    },
    MuiAppBar: {
      root: {
        height: 60,
        '& .MuiTypography-root': {
          color: colors.text.primaryLight,
        },
      },
    },
    MuiMenuItem: {
      root: {
        backgroundColor: colors.menu,
      },
    },
    MuiLink: {
      underlineHover: {
        textDecoration: undefined,
      },
    },
    MuiTypography: {
      root: {
        color: colors.text.secondaryLight,
      },
      colorTextPrimary: {
        color: colors.text.secondaryLight,
      },
      colorInherit: {
        color: undefined,
      },
    },
    // React AdminのPaginationActionの出力結果をもとに成形
    MuiTablePagination: {
      root: {
        overflow: 'hidden',
      },
      toolbar: {
        minHeight: 0,
        border: 0,
        '& button.MuiButton-root': {
          minWidth: 32,
          minHeight: 32,
          borderRadius: '50%',
          marginLeft: 8,
          color: colors.text.primaryLight,
          fontSize: 14,
          '&:hover': {
            backgroundColor: colors.paper,
          },
        },
        '& button:not(.MuiButton-textPrimary)': {
          backgroundColor: colors.selectedMenu,
        },
      },
      select: {
        minHeight: undefined,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      textPrimary: {
        color: colors.text.primaryLight,
      },
    },
    MuiPaper: {
      root: {
        color: colors.text.secondaryLight,
      },
      outlined: {
        border: '3px solid #F5F5FF86',
      },
      elevation1: {
        boxShadow: undefined,
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: undefined,
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.text.secondaryLight,
        '&$focused': {
          color: undefined,
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 148,
        width: 148,
        paddingRight: 12,
        color: undefined,
        fontSize: 12,
        '&$focused': {
          color: undefined,
        },
      },
      formControl: {
        position: undefined,
        transform: undefined,
      },
      marginDense: {
        transform: undefined,
      },
      shrink: {
        transform: undefined,
      },
      filled: {
        transform: undefined,
        '&$marginDense': {
          transform: undefined,
        },
        '&$shrink': {
          transform: undefined,
          '&$marginDense': {
            transform: undefined,
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#FFFFFF80',
        backgroundColor: '#17171A',
        borderRadius: 3,
        '&:hover': {
          backgroundColor: '#31323A',
        },
      },
      input: {
        minWidth: inputWidth,
        minHeight: inputHeight,
        padding: undefined,
        paddingLeft: 12,
        borderRadius: 3,
        color: colors.text.secondaryLight,
      },
      inputMarginDense: {
        paddingTop: undefined,
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: undefined,
        },
      },
    },
    MuiFilledInput: {
      multiline: {
        '&.MuiFilledInput-marginDense': {
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: 12,
          paddingRight: 11,
        },
      },
      root: {
        backgroundColor: undefined,
        borderTopLeftRadius: undefined,
        borderTopRightRadius: undefined,
        '&:hover': {
          backgroundColor: undefined,
        },
        '&.Mui-focused': {
          backgroundColor: '#17171A',
        },
      },
      input: {
        padding: undefined,
      },
      inputMarginDense: {
        paddingTop: undefined,
        paddingBottom: undefined,
        paddingLeft: undefined,
      },
      inputHiddenLabel: {
        paddingTop: undefined,
        paddingBottom: undefined,
        '&$inputMarginDense': {
          paddingTop: undefined,
          paddingBottom: undefined,
        },
      },
      underline: {
        '&:before': {
          transition: undefined,
          borderBottom: undefined,
        },
        '&:after': {
          borderBottom: undefined,
        },
        '&:hover:before': {
          borderBottom: undefined,
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        minHeight: 32,
      },
      select: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#565664',
        borderRadius: 3,
      },
      filled: {
        '&&': {
          minWidth: 300 - 32,
          paddingRight: 32,
        },
      },
    },
    MuiChip: {
      root: {
        height: 30,
        backgroundColor: '#717284',
        borderRadius: 60,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#727272',
        whiteSpace: 'pre-line',
      },
    },
    MuiPickersCalendar: {
      week: {
        '& .MuiPickersDay-dayDisabled': {
          '& .MuiTypography-root': {
            color: '#ffffff60',
          },
        },
      },
    },
    MuiSwitch: {
      root: {
        // checked
        '& .MuiSwitch-colorPrimary.Mui-checked': {
          color: '#FFFFFF',
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
          opacity: 1.0,
          backgroundColor: '#253FC8',
        },
        // uncheck
        '& .MuiSwitch-colorPrimary': {
          color: '#717284',
        },
        '& .MuiSwitch-track': {
          opacity: 1.0,
          backgroundColor: '#00011D',
        },
      },
    },
    RaLabeled: {
      value: {
        padding: undefined,
      },
    },
    RaFormInput: {
      input: {
        width: undefined,
      },
    },
    RaSearchInput: {
      input: {
        marginTop: undefined,
      },
    },
    RaSelectArrayInput: {
      chip: {
        margin: 4,
      },
    },
    RaConfirm: {
      confirmPrimary: {
        color: '#f44336',
      },
    },
    /**
     * HeaderがStickyにされるのを解除
     * https://github.com/marmelab/react-admin/pull/4309/files
     */
    RaDatagrid: {
      headerCell: {
        position: undefined,
        top: undefined,
        zIndex: undefined,
        backgroundColor: undefined,
      },
    },
    RaFilterFormInput: {
      spacer: {
        width: 0,
      },
    },
    RaFileInput: {
      root: {
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          '& .MuiFormHelperText-root': {
            order: 3,
          },
        },
        '& .previews': {
          order: 1,
        },
      },
      preview: {
        display: 'flex',
        alignItems: 'center',
        order: 1,
        '& a': {
          marginRight: '44px',
          fontSize: '12px',
          color: colors.text.secondaryLight,
          textDecoration: 'none',
        },
      },
      dropZone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        order: 2,
        height: '156px',
        backgroundColor: '#565664',
        border: 'dashed 1px #F5F5FF33',
        borderRadius: '3px',
        color: colors.text.secondaryLight,
      },
      removeButton: {
        display: 'inline-flex',
        backgroundColor: '#717284',
        borderRadius: '60px',
        marginBottom: '12px',
        padding: '6px 20px',
        '&:not(:first-child)': {
          marginLeft: '12px',
        },
        '& button': {
          order: 2,
          width: '14px',
          height: '14px',
          padding: '0',
          '& svg': {
            width: '100%',
            height: '100%',
            color: colors.text.primaryLight,
          },
        },
      },
    },
    RaCheckboxGroupInput: {
      label: {
        display: 'contents',
        '& > span': {
          /* MuiInputLabel と同等のスタイルを設定 */
          alignItems: 'center',
          minWidth: 148,
          width: 148,
          paddingRight: 12,
          color: undefined,
          fontSize: 12,
          marginTop: 8, // verticalAlignが聞かないためmargin追加
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#9091A0',
      },
      barColorPrimary: {
        backgroundColor: '#000344',
      },
    },
    RaLinearProgress: {
      root: {
        width: 'auto',
      },
    },
    RaAutocompleteInput: {
      container: {
        '& > div:not(.sites)': {
          '& div': {
            maxWidth: autocompleteWidth,
            '& input': {
              minWidth: autocompleteInnerWidth,
            },
          },
        },
        '& > div.sites': {
          '& div': {
            maxWidth: siteAutocompleteWidth,
            '& input': {
              minWidth: siteAutocompleteInnerWidth,
            },
          },
        },
      },
      suggestionsContainer: {
        background: colors.autoComplete.suggestionList,
        borderRadius: 4,
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
    },
    RaSaveButton: {
      leftIcon: {
        marginRight: 0,
      },
    },
    MuiAutocomplete: {
      input: {
        marginLeft: 6,
      },
      noOptions: {
        background: colors.autoComplete.suggestionList,
      },
      popper: {
        background: colors.autoComplete.suggestionList,
        borderRadius: 4,
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
      listbox: {
        background: colors.autoComplete.suggestionList,
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        // 本来のpaperAnchorRightが設定していたスタイル（dev開発ツールで確認）
        left: 'auto',
        right: 0,
        // ヘッダの高さ分上部にmarginを設定
        marginTop: '50px',
      },
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        right: -6,
        transform: 'scale(1) translate(20%, -50%)',
      },
      badge: {
        width: 'auto',
        whiteSpace: 'nowrap',
      },
    },
  },
};

export default () => createTheme(getCommonTheme(), theme);
