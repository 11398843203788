import React, { useCallback, useEffect, useState } from 'react';
import { omit } from 'lodash';
import {
  useInput,
  FieldTitle,
  InputProps,
  resolveBrowserLocale,
} from 'ra-core';
import { InputHelperText } from 'ra-ui-materialui';
import {
  KeyboardDateTimePicker,
  DateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, ja } from 'date-fns/locale';
import { Locale } from 'date-fns';
import { makeStyles, createStyles } from '@material-ui/core';
import { ResourceFC } from '..';
import { toTimezoneOffsetDate } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    picker: {
      '& .MuiInputBase-input': {
        minWidth: 244,
      },
      '& .MuiFormHelperText-root': {
        marginLeft: 14,
        marginRight: 14,
      },
    },
  }),
);
// ブラウザの言語設定を元にLocaleを設定
const localeMap: { [key: string]: Locale } = { ja };
const getDateLocale = (): Locale => {
  const browserLocale = resolveBrowserLocale();
  if (localeMap[browserLocale]) return localeMap[browserLocale];
  return enUS;
};

const getDateFormatLocale = (date: Date | null): string => {
  const baseYear = '2000';
  const baseMonth = '01';
  const baseDate = '02';
  const baseHour = (date?.getHours() ?? 3) >= 13 ? '15' : '03'; // ユーザ入力時刻の午前/午後に応じて、時間単位の置換ベース文字に午前3時か午後13時を使用する
  const baseMinutes = '44';
  const baseSeconds = '55';
  const localeBaseDateTime = new Date(
    `${baseYear}/${baseMonth}/${baseDate} ${baseHour}:${baseMinutes}:${baseSeconds}`,
  ).toLocaleString();
  return (
    localeBaseDateTime
      .replace('h', "'h'") // 「○○ h ○○ min ○○ s」表記の場合 「h」をそのまま画面表示する
      .replace('min', "'min'") // 「○○ h ○○ min ○○ s」表記の場合 「min」をそのまま画面表示する
      .replace(` ${baseSeconds} s`, '') // 「○○ h ○○ min ○○ s」表記の場合 秒単位は不要なので除去
      .replace(baseYear, 'yyyy')
      .replace(baseMonth, 'MM')
      .replace(baseDate, 'dd')
      .replace(baseHour, 'HH')
      .replace(baseMinutes, 'mm')
      .replace(`:${baseSeconds}`, '') // 秒単位は不要なので除去
      .replace(`${Number(baseMonth)}`, 'M') // 月の単位が一桁時に0埋めなしの場合は M に置換
      .replace(`${Number(baseDate)}`, 'd') // 日の単位が一桁時に0埋めなしの場合は d に置換
      .replace(`${Number(baseHour)}`, 'H') // 時間の単位が一桁時に0埋めなしの場合は H に置換
      .replace(`${Number(baseMinutes)}`, 'm') // 分の単位が一桁時に0埋めなしの場合は m に置換
      .replace(`${Number(baseHour) + 12}`, 'h') // 時間の単位が24時間表記ではなく、午前/午後付きの12時間表記の場合は h に置換
      .replace(`${Number(baseHour) - 12}`, 'h') // 時間の単位が24時間表記ではなく、午前/午後付きの12時間表記の場合は h に置換
      // AM/PM 付きは a, aaa, aaaa のいずれかに置換
      .replace('AM', 'a')
      .replace('PM', 'a')
      .replace('am', 'aaa')
      .replace('pm', 'aaa')
      .replace('a.m.', 'aaaa')
      .replace('p.m.', 'aaaa')
  );
};

type Props = InputProps<DateTimePickerProps> & {
  minDate?: Date;
  maxDate?: Date;
  useUtc?: boolean;
  setDateTime?: Date;
  inputProps?: {
    inputValue?: Date;
    handleChange?: (value: Date | null, form?: any) => void;
  };
};

const DateTimePickerInput: React.FC<Props> = ({
  format,
  label,
  source,
  resource,
  helperText,
  margin = 'dense',
  onBlur,
  onChange,
  onFocus,
  parse,
  validate,
  variant = 'filled',
  autoComplete = 'off',
  form,
  minDate,
  maxDate,
  useUtc = false,
  inputProps,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });
  const [inputValue, setInputValue] = useState<Date | null>(null);
  const providerOptions = {
    utils: DateFnsUtils,
    locale: getDateLocale(),
  };
  const handleChange = useCallback(
    (value: Date | null) => {
      setInputValue(value);
      if (inputProps?.handleChange) {
        inputProps.handleChange(value, form);
      }
      if (!value) return;
      let isMaxDateOver = false;
      let isMinDateBelow = false;

      // 指定時のみ範囲チェックを行う
      if (maxDate) isMaxDateOver = value > maxDate;
      if (minDate) isMinDateBelow = value < minDate;

      if (value.toString() === 'Invalid Date') return;

      if (isMaxDateOver) {
        // maxDate より 未来 の場合、maxDate で丸める
        input.onChange(maxDate?.toISOString());
      } else if (isMinDateBelow) {
        // minDate より 過去 の場合、minDate で丸める
        input.onChange(minDate?.toISOString());
      } else {
        // 通常時
        const outputDate = useUtc
          ? toTimezoneOffsetDate({ date: value, isMinus: true })
          : value;
        input.onChange(outputDate.toISOString());
      }
    },
    [input, maxDate, minDate, useUtc, setInputValue, inputProps, form],
  );
  const classes = useStyles();

  // 入力値パラメータが渡されている場合は渡された入力値をセット
  useEffect(() => {
    if (
      !inputProps ||
      inputProps?.inputValue?.getTime() === inputValue?.getTime()
    )
      return;

    const outputDate =
      inputProps?.inputValue && useUtc
        ? toTimezoneOffsetDate({ date: inputProps?.inputValue, isMinus: true })
        : inputProps?.inputValue;
    input.onChange(outputDate?.toISOString());
  }, [input, useUtc, inputProps, inputValue]);

  let inputDate: Date | null = null;
  if (input.value) {
    inputDate = useUtc
      ? toTimezoneOffsetDate({ date: input.value, isMinus: false })
      : new Date(input.value);
  }

  const datetimeFormat = `${getDateFormatLocale(inputDate)}`;

  return (
    <div className={classes.picker}>
      <MuiPickersUtilsProvider {...providerOptions}>
        <KeyboardDateTimePicker
          id={id}
          initialFocusedDate={
            useUtc
              ? toTimezoneOffsetDate({
                  date: new Date(),
                  isMinus: false,
                })
              : undefined
          }
          onClose={() => {
            form.blur(input.name);
          }}
          {...input}
          autoComplete={autoComplete}
          minDate={
            useUtc && minDate
              ? toTimezoneOffsetDate({
                  date: minDate,
                  isMinus: false,
                })
              : minDate
          }
          maxDate={
            useUtc && maxDate
              ? toTimezoneOffsetDate({
                  date: maxDate,
                  isMinus: false,
                })
              : maxDate
          }
          variant={variant}
          margin={margin}
          error={!!(touched && error)}
          helperText={
            <InputHelperText
              touched={touched}
              error={error}
              helperText={helperText}
            />
          }
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          InputLabelProps={{
            shrink: true,
          }}
          value={inputDate}
          onChange={date => handleChange(date)}
          ampm={false}
          format={datetimeFormat}
          {...omit(rest, 'basePath')}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DateTimePickerInput.displayName = 'DateTimePickerInput';
export default DateTimePickerInput as ResourceFC<Props>;
