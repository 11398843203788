import React, { useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  CustomList,
  Datagrid,
  FunctionField,
  LinkButton,
  IconField,
  CustomDateField,
  ListDownloadButton,
  TextField,
  Loading,
} from '../../../..';
import {
  useAccount,
  useGetSelefDetailData,
  useResource,
} from '../../../../../hooks';
import { AsbuiltData } from '../../../../../dataProvider';
import { AddIcon } from '../../../../../assets';
import { getCsvDelimiter, getDecimalPoint } from '../../../../../utils';
import { AsbuiltCsvDownloadButton } from './views';
import { useGetSiteName } from '../../../retrofits/info/hooks';
import { getRetrofitInfoBreadcrumbs } from '../../../retrofits/info/util';

const Actions: React.FC<{
  basePath?: string;
}> = ({ basePath }) => (
  <LinkButton
    path={`${basePath}/create`}
    label="admin.actions.creationInstructions"
  >
    <AddIcon />
  </LinkButton>
);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      tableLayout: 'fixed',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            paddingTop: '6px',
            paddingBottom: '6px',
            boxSizing: 'content-box',
          },
          '& .MuiTableCell-root:nth-child(1)': {
            paddingRight: '15px',
            paddingLeft: '16px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(2)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 76,
            textAlign: 'center',
          },
          '& .MuiTableCell-root:nth-child(3)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(4)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 112,
          },
          '& .MuiTableCell-root:nth-child(5)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: 266,
          },
          '& .MuiTableCell-root:nth-child(6)': {
            paddingRight: '16px',
            paddingLeft: '15px',
            width: 100,
          },
        },
      },
    },
  }),
);

interface ApiResult {
  isOwnData: boolean;
  siteAttachedData: boolean;
  fetchedRetrofitId: number;
  breadcrumbs: string[];
}

const SiteRetrofitAsbuiltList: React.FC = () => {
  const resource = useResource('asbuilts');
  const { siteId, retrofitId: strRetrofitId } =
    useParams<{ siteId: string; retrofitId: string }>();
  const retrofitId = Number(strRetrofitId);
  const classes = useStyles();
  const location = useLocation();

  const getAccount = useAccount();
  const getSelefDetailData = useGetSelefDetailData();
  const getSiteName = useGetSiteName();
  const redirectTo = useRedirect();

  const [apiState, setApiState] = useState<{
    loading: boolean;
    fetched: boolean;
    data?: ApiResult;
  }>({
    loading: true,
    fetched: false,
    data: undefined,
  });

  const decimalDelimiter = getDecimalPoint();
  const separatorDelimiter = getCsvDelimiter();
  const exportOtherNumberFormat = decimalDelimiter === ',';

  useEffect(() => {
    // 既にDB取得済みでも、urlのretrofitIdが変わった場合は再度実施
    if (apiState.fetched && apiState.data?.fetchedRetrofitId === retrofitId) {
      return;
    }

    const api = async (): Promise<{
      data: {
        apiResult: ApiResult;
      };
    }> => {
      // accounts
      const {
        data: { corporationId },
      } = await getAccount();

      // retrofits
      const listFileter = {
        ownerCorporationId: corporationId,
        adminCorporationId: corporationId,
      };
      // 建機一覧から閲覧可能なレトロフィットであるか判定
      const retrofit = await getSelefDetailData(
        'retrofits',
        listFileter,
        retrofitId,
      );

      // 現場に紐づくレトロフィットであるか判定
      const siteAttachedListFileter = {
        siteId: siteId || '',
      };
      const siteAttachedRetrofit = await getSelefDetailData(
        'retrofits',
        siteAttachedListFileter,
        retrofitId,
      );

      // site ※現場建機詳細から呼ばれた場合に、パンくずリストで必要
      const {
        data: { siteName },
      } = await getSiteName({ siteId });

      // DBの取得結果を使うため、パンくずリストはここで作成
      const breadcrumbs = getRetrofitInfoBreadcrumbs(
        siteAttachedRetrofit?.machineInfoMachineName
          ? siteAttachedRetrofit?.machineInfoMachineName
          : retrofit?.machineInfoMachineName || '',
        siteName,
      );

      return {
        data: {
          apiResult: {
            isOwnData: !!retrofit,
            siteAttachedData: !!siteAttachedRetrofit,
            fetchedRetrofitId: retrofitId,
            breadcrumbs,
          },
        },
      };
    };
    api().then(({ data: { apiResult } }) => {
      setApiState({
        loading: false,
        fetched: true,
        data: apiResult,
      });
    });
  }, [
    getSelefDetailData,
    getAccount,
    getSiteName,
    retrofitId,
    siteId,
    apiState,
  ]);

  if (apiState.loading || !apiState.data) return <Loading />;

  // TODO: 閲覧不可なデータは現場一覧に遷移する機能を有効にする場合、コメントアウトを外す
  const { isOwnData, siteAttachedData, breadcrumbs } = apiState.data;
  // 建機一覧に存在しないretrofitの基本設定情報は表示させない
  // 現場詳細画面から遷移する場合、対象現場に紐づかないretrofitの基本設定情報は表示させない
  if (siteId ? !siteAttachedData : !isOwnData) redirectTo('/');

  return (
    <CustomList
      breadcrumbs={breadcrumbs}
      resource={resource}
      basePath={location.pathname}
      location={location}
      filter={{ siteId, retrofitIds: [retrofitId] }}
      actions={<Actions />}
      hasCreate
      bulkActionButtons={false}
    >
      <Datagrid className={classes.root}>
        <CustomDateField resource={resource} source="execStartDate" showTime />
        <IconField resource={resource} source="execStatus" />
        <CustomDateField resource={resource} source="startPeriod" showTime />
        <CustomDateField resource={resource} source="endPeriod" showTime />
        <TextField resource={resource} source="csvType" />
        <FunctionField
          resource={resource}
          source="url"
          sortable={false}
          render={asbuiltRecord => {
            const data = asbuiltRecord as AsbuiltData;
            const url = data.url || '';
            // CSVの出力フォーマットにより表示ボタンを切り替える
            return exportOtherNumberFormat ? (
              <AsbuiltCsvDownloadButton
                label="admin.actions.csvDownload"
                asbuiltFileId={data.asbuiltFileId}
                decimalDelimiter={decimalDelimiter}
                separatorDelimiter={separatorDelimiter}
                execStatus={data.execStatus}
              />
            ) : (
              <ListDownloadButton
                url={data.execStatus === 'Complete' ? url : ''}
                label="admin.actions.csvDownload"
              />
            );
          }}
        />
      </Datagrid>
    </CustomList>
  );
};

export default SiteRetrofitAsbuiltList;
