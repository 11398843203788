import { AsbuiltData } from '../../types';

const asbuilts: AsbuiltData[] = [
  {
    id: 'ab8c35a4-66cf-1055-ac70-cb4fdff4ca5c',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '',
    execEndDate: '',
    url: '',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T03:01:00.000Z',
  },
  {
    id: '10f7642f-7883-d681-9b33-66314241b342',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    asbuiltFileId: '10f7642f-7883-d681-9b33-66314241b342',
    execStartDate: '2020-03-13T02:01:00.000Z',
    execEndDate: '2020-03-13T03:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:21:00.000Z',
  },
  {
    id: 'ddb96294-5d26-d817-4044-31e9702f8573',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:13.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:00:00.000Z',
    execEndDate: '2020-03-13T01:01:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:01:00.000Z',
  },
  {
    id: '2f78b9cc-bb12-1990-0a46-57e74730d625',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:14.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:01:00.000Z',
    execEndDate: '2020-03-13T01:02:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:02:00.000Z',
  },
  {
    id: '2e52c3d4-35c9-178f-224f-b609f11c36c9',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:15.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:02:00.000Z',
    execEndDate: '2020-03-13T01:03:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:03:00.000Z',
  },
  {
    id: 'f8ddb5f1-7aff-7fb1-42ff-7284c067d41b',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:16.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:03:00.000Z',
    execEndDate: '2020-03-13T01:04:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:04:00.000Z',
  },
  {
    id: '97294297-ae01-c8de-5bd6-8da4c6a4651a',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:17.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:04:00.000Z',
    execEndDate: '2020-03-13T01:05:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:05:00.000Z',
  },
  {
    id: '92871b50-c980-23a8-94c0-39ae0d31c0c9',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:18.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:05:00.000Z',
    execEndDate: '2020-03-13T01:06:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:06:00.000Z',
  },
  {
    id: '648b544b-be83-95bb-61f0-471dd272ae4b',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:19.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:06:00.000Z',
    execEndDate: '2020-03-13T01:07:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:07:00.000Z',
  },
  {
    id: '63c6b925-24c5-b40a-e520-14a416b18956',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:20.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:07:00.000Z',
    execEndDate: '2020-03-13T01:08:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:08:00.000Z',
  },
  {
    id: '8b8f410f-89ed-5486-e660-8bf3c5c8e4eb',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:21.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:08:00.000Z',
    execEndDate: '2020-03-13T01:09:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:09:00.000Z',
  },
  {
    id: 'bc70c4b8-22a4-9e4c-4eb8-6f272ddc9437',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:22.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:09:00.000Z',
    execEndDate: '2020-03-13T01:10:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:10:00.000Z',
  },
  {
    id: 'c2f17cf4-96e7-7508-c2f7-ebac67949dcd',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:23.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:10:00.000Z',
    execEndDate: '2020-03-13T01:11:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:11:00.000Z',
  },
  {
    id: '6346d58e-eaf3-7fb9-dea1-6554873cf6b8',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:24.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:11:00.000Z',
    execEndDate: '2020-03-13T01:12:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:12:00.000Z',
  },
  {
    id: 'ccf632f1-0a0d-1efd-c722-d9e15815a913',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:25.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:12:00.000Z',
    execEndDate: '2020-03-13T01:13:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:13:00.000Z',
  },
  {
    id: '7f2733ab-7d84-d2cd-395e-1048caaad1b3',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:26.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:13:00.000Z',
    execEndDate: '2020-03-13T01:14:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:14:00.000Z',
  },
  {
    id: '2271e992-2686-5ac9-3b83-7c97191f9b97',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:27.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:14:00.000Z',
    execEndDate: '2020-03-13T01:15:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:15:00.000Z',
  },
  {
    id: '1304e7e7-706e-865a-5254-43cd9d72a8b4',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:28.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:15:00.000Z',
    execEndDate: '2020-03-13T01:16:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:16:00.000Z',
  },
  {
    id: '067229a9-e1ca-2348-0ea8-c7f0864c2ab3',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:29.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:16:00.000Z',
    execEndDate: '2020-03-13T01:17:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:17:00.000Z',
  },
  {
    id: '7b0099e9-a7be-17e0-835c-a445e466f78c',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:30.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:17:00.000Z',
    execEndDate: '2020-03-13T01:18:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:18:00.000Z',
  },
  {
    id: 'e3177c68-6155-f1f9-8f31-4b596d030e51',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:31.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:18:00.000Z',
    execEndDate: '2020-03-13T01:19:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:19:00.000Z',
  },
  {
    id: '5d9c4c3e-dc9c-f15e-1c00-a5e681c748fc',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '6b7741a1-c2cf-4ba3-bc2d-7d19bac78922',
    startPeriod: '2020-03-11T00:00:32.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:19:00.000Z',
    execEndDate: '2020-03-13T01:20:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/1.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:20:00.000Z',
  },
  {
    id: 'a54220fa-f463-7732-1457-f568e2079ac8',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '2020-03-13T00:00:00.000Z',
    execEndDate: '',
    url: '',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:00:00.000Z',
  },
  {
    id: '3f7c0e29-ab2e-6204-135b-8901f3187817',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-12T01:00:00.000Z',
    execEndDate: '2020-03-12T02:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/3.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-12T01:00:00.000Z',
  },
  {
    id: '0f96a81a-9786-9e43-9581-220dd6447926',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '2020-03-13T01:00:00.000Z',
    execEndDate: '',
    url: '',
    createUser: '阿木由紀夫',
    createDate: '2020-03-13T01:00:00.000Z',
  },
  {
    id: '805365e2-a56c-c7ff-a606-2dc8f84d8f72',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '74bdcc81-330b-4d66-900a-48b0d452c784',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-12T01:00:00.000Z',
    execEndDate: '2020-03-12T02:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/5.csv',
    createUser: '阿木由紀夫',
    createDate: '2020-03-12T01:00:00.000Z',
  },
  {
    id: '9467febd-6b4c-f50a-2da2-7bfe98617a5c',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '',
    execEndDate: '',
    url: '',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T03:01:00.000Z',
  },
  {
    id: 'e9655f90-0e8b-252b-a3fb-0c62a3119f32',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T02:01:00.000Z',
    execEndDate: '2020-03-13T03:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/7.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:21:00.000Z',
  },
  {
    id: '7b1c071d-ffad-3f34-c71d-880def700c2a',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '2020-03-13T00:00:00.000Z',
    execEndDate: '',
    url: '',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:00:00.000Z',
  },
  {
    id: '5b9f5938-c6d6-e0f2-a054-317d990d92c2',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'f837c090-f5ac-4973-8b65-53ee677c1dcb',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-12T01:00:00.000Z',
    execEndDate: '2020-03-12T02:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/9.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-12T01:00:00.000Z',
  },
  {
    id: '36283f9a-8668-b6d2-f07d-dde8f76aed40',
    corporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    siteId: '5fc8b9e5-996e-4f4e-acae-0dc5d4ffae96',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'InProgress',
    execStartDate: '2020-03-13T01:00:00.000Z',
    execEndDate: '',
    url: '',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:00:00.000Z',
  },
  {
    id: '92ddb75c-0d0d-c9df-63a4-1c55f22081c3',
    corporationId: '0da5298e-0081-08af-c9dd-3c8ae99fa742',
    siteId: '5fc8b9e5-996e-4f4e-acae-0dc5d4ffae96',
    startPeriod: '2020-03-11T00:00:00.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Error',
    execStartDate: '2020-03-12T01:00:00.000Z',
    execEndDate: '2020-03-12T02:00:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/11.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-12T01:00:00.000Z',
  },
  {
    id: '48ebde12-7a91-1d21-584a-3f9b03cdfb23',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:13.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:00:00.000Z',
    execEndDate: '2020-03-13T01:01:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:01:00.000Z',
  },
  {
    id: '16d0d5e9-89e5-5f2b-652e-5d7e4b40acda',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:14.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:01:00.000Z',
    execEndDate: '2020-03-13T01:02:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:02:00.000Z',
  },
  {
    id: '8839ab32-caa1-adce-0798-8924ea449bee',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:15.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:02:00.000Z',
    execEndDate: '2020-03-13T01:03:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:03:00.000Z',
  },
  {
    id: '0944c7a2-2c54-cd08-c0b4-76697c798374',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:16.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:03:00.000Z',
    execEndDate: '2020-03-13T01:04:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:04:00.000Z',
  },
  {
    id: 'f6e5b74a-e75a-8e06-a316-f4b2cb97ec9a',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:17.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:04:00.000Z',
    execEndDate: '2020-03-13T01:05:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:05:00.000Z',
  },
  {
    id: '400fa6ec-16ec-4b69-74bc-2f839f39a27b',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:18.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:05:00.000Z',
    execEndDate: '2020-03-13T01:06:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:06:00.000Z',
  },
  {
    id: '065c39c3-73d3-dfd9-9894-d604593e8988',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:19.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:06:00.000Z',
    execEndDate: '2020-03-13T01:07:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:07:00.000Z',
  },
  {
    id: 'ebd60f5e-b8f9-75d5-11d0-f948abba0a3e',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:20.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:07:00.000Z',
    execEndDate: '2020-03-13T01:08:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:08:00.000Z',
  },
  {
    id: '348f88b7-e77e-c311-d4c3-b0af12ef4219',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:21.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:08:00.000Z',
    execEndDate: '2020-03-13T01:09:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:09:00.000Z',
  },
  {
    id: 'fdc6f514-e82f-c500-5148-ccc1a9cf6d29',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:22.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:09:00.000Z',
    execEndDate: '2020-03-13T01:10:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:10:00.000Z',
  },
  {
    id: '9f6ea36d-f20d-6a7d-974e-d30d9544cf17',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:23.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:10:00.000Z',
    execEndDate: '2020-03-13T01:11:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:11:00.000Z',
  },
  {
    id: '920d99d4-e15f-41b5-fa25-6fbd9a178db3',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:24.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:11:00.000Z',
    execEndDate: '2020-03-13T01:12:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:12:00.000Z',
  },
  {
    id: '2c32751a-2a7b-4fbe-8077-df33a5a87f5d',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:25.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:12:00.000Z',
    execEndDate: '2020-03-13T01:13:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:13:00.000Z',
  },
  {
    id: '8afd6e6a-a9b3-727f-3515-92e2983411d0',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:26.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:13:00.000Z',
    execEndDate: '2020-03-13T01:14:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:14:00.000Z',
  },
  {
    id: 'e0630f14-d92c-aa1b-44d8-fa532ee669dc',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:27.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:14:00.000Z',
    execEndDate: '2020-03-13T01:15:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:15:00.000Z',
  },
  {
    id: '185f8cee-11a7-24e6-93de-50ea0a03080b',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:28.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:15:00.000Z',
    execEndDate: '2020-03-13T01:16:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:16:00.000Z',
  },
  {
    id: 'b1a980f4-9547-731e-6026-ea69d4fc8915',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:29.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:16:00.000Z',
    execEndDate: '2020-03-13T01:17:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:17:00.000Z',
  },
  {
    id: '84703000-de5f-b163-5fa7-2dc00ed02769',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:30.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:17:00.000Z',
    execEndDate: '2020-03-13T01:18:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:18:00.000Z',
  },
  {
    id: 'bb01525c-ac64-c4e9-2779-9c58a4c1baf9',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:31.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:18:00.000Z',
    execEndDate: '2020-03-13T01:19:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:19:00.000Z',
  },
  {
    id: 'f2f9c3c5-b132-601d-7b54-9adb010bfd96',
    corporationId: 'd856114c-0bed-44c5-8185-0ef87c5f678c',
    siteId: 'ebe0e417-a2ba-4a24-9052-2d78f69f122f',
    startPeriod: '2020-03-11T00:00:32.000Z',
    endPeriod: '2020-03-11T01:00:00.000Z',
    csvType: 'NEZ',
    execStatus: 'Complete',
    execStartDate: '2020-03-13T01:19:00.000Z',
    execEndDate: '2020-03-13T01:20:00.000Z',
    url: 'https://3dmg-frontend-sitedata.s3-ap-northeast-1.amazonaws.com/asbuilt_csv_url/13.csv',
    createUser: '鶴見和弘',
    createDate: '2020-03-13T01:20:00.000Z',
  },
  {
    id: '878262d3-0525-4ecf-93ff-c2c2af0bb8a7',
    corporationId: '2f072e43-44ff-40a6-81b2-fcf5d2e90827',
    siteId: '14b2149c-9d45-f3cb-bc93-7916ecc3778d',
    startPeriod: '2024-10-01T00:00:00.000Z',
    endPeriod: '2024-10-01T01:00:00.000Z',
    csvType: 'NEZ',
    retrofitIds: [6],
    execStatus: 'InProgress',
    execStartDate: '2021-10-01T00:00:00.000Z',
    execEndDate: '',
    url: '',
    createUser: '阿木由紀夫',
    createDate: '2024-10-01T00:00:00.000Z',
  },
];

export default asbuilts;
