// for et
// エストニア語
import raMessage from 'ra-language-estonian';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Ikke fundet',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Kas soovid kustutada %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Ootame heakskiitu',
    usermenu: {
      userNotice: 'Teavitamine',
      terms: 'TEENUSE TINGIMUSED',
      manual: 'Manual',
      downloads: 'Laadi alla',
    },
    breadcrumbs: {
      details: 'Detailid',
      retrofitEdit: 'Detailid',
      taskCreate: 'Loo ülesanne',
      taskEdit: 'Ülesande üksikasjad',
      geofenceCreate: 'Loo',
      geofenceEdit: 'Redigeeri',
      geofenceAlertList: 'Geofence-alarm',
      geofenceAlertShow: 'Detailid',
      compactionWorkAreaCreate: 'Lisa tihenduspiirkonnad',
      compactionWorkAreaShow: 'Tihendusvaldkonnad',
      compactionWorkAreaEdit: 'Muuda tihenduse tööalad',
      compactionLayerCreate: 'Loo tihenduse kihid',
      compactionLayerEdit: 'Muuda tihenduskihte',
      supportMode: 'Toetusrežiim',
      retrofitManage: 'Kontrolleri registreerimine',
      retrofitManageAdmin: 'Kontrolleri registreerimine (Admin)',
      retrofitNamePlateFiles: 'Kontrolleri registreerimine',
      retrofitAlternateRegistCreate: 'Kontrolleri Proxy registreerimine',
      retrofitAlternateRegistEdit: 'Detailid',
      retrofitManageAdminBulkActionCreate: 'Registreerimine',
      terms: 'TEENUSE TINGIMUSED',
      downloads: 'Laadi alla',
    },
    pages: {
      siteList: 'Byggepladsliste',
      siteRetrofitCreate: 'Lisa masin tööleheküljele',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Lisa ämber tööleheküljele',
      siteBucketEdit: 'Eimeri',
      siteProjectEdit: 'Projekti üksikasjad',
      siteProjectCreate: 'Projekti failiregistreerimine',
      siteExtensionarmCreate: 'Lisa pikendusvarras tööleheküljele',
      taskCreate: 'Loo ülesanne',
      taskEdit: 'Ülesande üksikasjad',
      geofenceAlertList: 'Geofence-alarm',
      geofenceAlertShow: 'Geofence-alarm detaljer',
      geofenceCreate: 'Loo geofence',
      geofenceEdit: 'Muuda geofence',
      compactionWorkAreaCreate: 'Lisa tihenduspiirkonnad',
      compactionLayerCreate: 'Loo tihenduse kihid',
      compactionLayerEdit: 'Muuda tihenduskihte',
      siteFileEdit: 'Detailid',
      siteFileCreate: 'Lisa fail tööleheküljele',
      retrofitList: 'Maskinliste',
      retrofitEdit: '',
      retrofitRentalCreate: 'Ülesande registreerimine',
      retrofitTransferCreate: 'Ülekande registreerimine',
      retrofitSendBack: 'Tagasi saatmise registreerimine',
      bucketList: 'Eimerite nimekiri',
      bucketEdit: 'Eimeri',
      bucketCreate: 'Eimeri registreerimine',
      bucketRentalCreate: 'Eimeri jagamine',
      extensionarmList: 'Armforlængerliste',
      extensionarmEdit: 'Armforlængeroplysninger',
      extensionarmShare: 'Armforlængerdeling',
      retrofitBulkActionList: 'Massi registreerimine',
      retrofitBulkActionCreate: 'Massregistreerimine',
      retrofitBasicInfoList: 'Kontrolleri nimekiri',
      retrofitBasicInfoCreate: 'Kontrolleri registreerimine',
      retrofitBasicInfoEdit: 'Kontrolleri värskendamine',
      gnssSettingList: 'Ntrip seadete loend',
      gnssSettingCreate: 'Loo Ntrip seadistus',
      gnssSettingAdminCreate: 'Loo Ntrip seadistus',
      gnssSettingOwnerCreate: 'Loo Ntrip seadistus',
      gnssSettingEdit: 'Muuda Ntrip seadistust',
      gnssSettingAdminEdit: 'Muuda Ntrip seadistust',
      gnssSettingOwnerEdit: 'Muuda Ntrip seadistust',
      approvalList: 'Maskinliste',
      retrofitRentalEdit: 'Ülesande üksikasjad',
      retrofitTransferEdit: 'Ülekande üksikasjad',
      asbuiltsCreate: 'Omandage ehitustulemus',
      asbuiltsShow: 'Asbuild ajalugu',
      asbuiltsRetrofitCreate: 'Omandage ehitustulemus',
      retrofitConfigList: 'Konfiguratsiooni info',
      retrofitKitInfoList: 'Kontrolleri info',
      retrofitAccuraciesList: 'Täpsuskontrolli tulemus',
      retrofitAccuraciesShow: 'Detailid',
      retrofitCalibaList: 'Kalibreerimisinfo',
      retrofitCalibInfo: 'Masinate kalibreerimise info üksikasjad',
      retrofitBasicSettingList: 'Põhilised seaded',
      retrofitErrorList: 'Fejlinfo',
      retrofitErrorShow: 'Detailid',
      retrofitTopographicSurveyList: 'Topomõõdistuse loetelu',
      retrofitTopographicSurveyShow: 'Topograafilised andmed',
      retrofitShareFileList: 'Rakenduse logi',
      retrofitCorporationLicenseList: 'Licensliste',
      retrofitCorporationLicenseEdit: 'Licensdetaljer',
      CompactionLayerList: 'Tihenduskihid',
      userNoticeList: 'Teavituse nimekiri',
      userNoticeCreate: 'Lisa teade',
      userNoticeEdit: 'Uuenda teavitust',
      userNoticeDrawer: 'Teavitamine',
      retrofitAlternateRegists: 'Kontrolleri Proxy registreerimise loend',
      firmwareList: 'Firmware liste',
      firmwareShow: 'Firmware detaljer',
      firmwareCreate: 'Lisa tarkvara',
      retrofitManage: 'Tekst',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Massregistreerimine',
      retrofitManageAdminBulkActionCreate: 'Massregistreerimine',
      retrofitAlternateRegistCreate: 'Kontrolleri Proxy registreerimine',
      retrofitAlternateRegistEdit:
        'Kontrolleri Proxy registreerimise üksikasjad',
      retrofitAlternateRegistCreateText: 'Tekst',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntripi info',
      ntripEdit: 'Ntripi haldamine',
    },
    label: {
      login: 'Log ind',
      selected: 'Valitud (%{length} juhtumid)',
      listNoResults: 'Andmeid ei leitud',
      maintenance: {
        title: 'Praegu toimub hooldus.',
        message: `Oleme teenused ajutiselt hooldustööde tõttu peatanud.
      Vabandame ebamugavuste pärast, kuid palume olla veel hetk kannatlik.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Sleep of selecteer het bestand/de bestanden (%{fileTypes}) om te uploaden.',
        maxFileNum: 'Korraga saab registreerida kuni viis kontrollerit.',
      },
      nosetting: 'Ingen indstilling',
      siteRetrofitList: {
        remoteSupport: 'Kaugabi',
      },
      retrofitEdit: {
        breadcrumbs: 'Detailid',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip seadete info',
        machineryDetails: 'Masina',
        retrofitDetails: 'Kontroller',
        basicInfo: 'Põhiinfo',
        controller: 'Kontroller',
        reciverMain: 'GNSS-i põhivastuvõtja',
        reciverSub: 'GNSS-i abivastuvõtja',
        retrofitstatus: 'Kontrolleri olek',
        license: 'Litsents',
        licenseId: 'Litsentsi ID-number',
        expiredDate: 'Udløbsdato',
      },
      retrofitRental: {
        rentalDetails: 'Ülesande üksikasjad',
        rentalInfo: 'Ülesande üksikasjad',
        lending: 'Ülesanne',
        return: 'Tagasi',
        lendingDestination: 'Määratud ettevõte',
        lendingCompany: 'Ettevõtte nimi',
        lendingEmail: 'E-posti aadress',
        companyName: 'Ettevõtte nimi',
      },
      retorfitTransfer: {
        tab: 'Ülekanne',
        transferInfo: 'Ülekande üksikasjad',
        transferButton: 'Ülekanne',
        transferEditButton: 'Muuda ülekannet',
      },
      siteConfigureEdit: {
        onButton: 'SEES',
        offButton: 'VÄLJAS',
      },
      approvals: {
        rentalTab: 'Ülesanne',
        transferTab: 'Ülekanne',
      },
      buckets: {
        bucketTab: 'Kopp',
        extensionarmTab: 'Kopavarre pikendi',
        extensionarmFile: 'Armforlængerfil',
        shareTo: 'Jagada kellelegi',
      },
      bucketRental: {
        corporation: 'Jagada kellelegi',
        bucketType: 'Tüüp',
        bk3File: 'BK3 fail',
      },
      retrofitBasic: {
        basicInfoTab: 'Põhiinfo',
        bulkActionTab: 'Massregistreerimine',
        ntripTab: 'Ntripi haldamine',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Tagasi saatmine',
        initialize: 'Initialisering',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Massilise uuendamine',
        retrofitDistributor: 'Levitaja',
        retrofitRegion: 'Ala',
        corporationId: 'Ettevõtte ID',
        corporationName: 'Ettevõtte nimi',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Ettevõtte ID on kasutusel',
        corporationName: 'Ettevõte kasutusel',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Ettevõtte nimi',
      },
      terms: {
        title: 'TEENUSE TINGIMUSED',
        message:
          'Palun klõpsake allpool olevat linki, et vaadata kasutustingimusi.',
        confirmMessage1:
          'For at bruge Smart Construction Pilot skal du acceptere følgende dokumenter.',
        confirmMessage2:
          'Hvis du ikke accepterer, kan du ikke bruge Smart Construction Pilot.',
        select: 'Palun valige oma piirkond.',
      },
      downloads: {
        title: 'Projekti failikonverter',
        message:
          'See on eraldiseisev rakendus, mis loob projektfaili projekteerimisfailidest.',
        button: 'Laadi alla',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automaatne nummerdamine',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Laadi alla',
        resultFileDownload: 'Laadi alla',
      },
      tasks: {
        coordinate: 'Koordinaadid (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Ploki suurus (%{unit})',
      },
      compactionLayers: {
        thickness: 'Paksus (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detailid',
        rentalDetail: 'Ülesande üksikasjad',
      },
      pendingTransfers: {
        breadcrumbs: 'Detailid',
        transferDetail: 'Ülekande üksikasjad',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detailid',
        alternateRegistDetail: 'Kontrolleri Proxy registreerimise üksikasjad',
      },
      supportMode: {
        retrofit: 'Masina',
        optional: 'Valikuline',
      },
      retrofitList: {
        assignment: 'Ülesanne',
        license: 'Litsents',
      },
      userNotices: {
        languageDetails: 'Teavituse sisu',
        language: 'Keel',
        languageEn: 'English',
        languageJa: 'Japansk',
        languageFr: 'Fransk',
        languageDe: 'Saksa',
        languageEs: 'Hispaania keel',
        Information: 'Teavitamine',
        Maintenance: 'Vedligeholdelse',
        emergency: 'Hädaolukord',
        important: 'Viktigt',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Kontrolleri registreerimine',
        corporationName: 'Ettevõtte nimi',
        basicInfoSerialNumber: 'Seerianumber',
        confirmInput: 'Sisesta seerianumber uuesti',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Seerianumber',
        status: 'Olek',
        imageFile: 'Billede',
        message1:
          'Palun tehke pilt kontrolleri seerianumbrist või QR-koodist, mis on selge ja mitte udune.',
        message2: 'Billeder skal være mindre end %{mbyte} MB.',
        errormessage: 'Korraga saab registreerida kuni viis kontrollerit.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Kehtiv',
          disable: 'Kehtetu',
          unLicense: 'Määramata',
        },
        machine: 'Masina',
        before: 'Enne',
        after: 'Pärast',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Kiht',
        rgb: 'Värv(RGB)',
        select: 'Valiku geofence',
        coordinates: 'Koordinaadid',
        coordinatesLabel: 'Koordinaadid (N,E)',
        unitLength: 'Ühik:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinaadid (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Raadius',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Kontrolleri Proxy registreerimine',
        corporationName: 'Ettevõtte nimi',
        email: 'E-posti aadress',
        basicInfoSerialNumber: 'Seerianumber',
        confirmInput: 'Sisesta seerianumber uuesti',
      },
      ntripList: {
        unusedTotal: 'Kasutamata kogus',
      },
      calibrationDetail: {
        breadcrumbs: 'Detailid',
        machineInfo: 'Masina info',
        machineGeometryInfo: 'Masina geomeetria info',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU paigaldusinfo',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Põhi-GNSS paigaldusinfo',
        bodyCenterToGnssSub: 'Abi-GNSS paigaldusinfo',
        extendedModel: 'Laiendatud mudel',
        measuredValues: 'Kalibreerimisinfo',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Andmete konverteerimise viga',
        system_localization_quantity_error: `Mitut lokaliseerimisandmeid sisaldavat faili ei saa registreerida.
      Palun registreerige ainult üks lokaliseerimisandmeid sisaldav fail.`,
        system_detect_0byte_file_error: `Faili registreerimine serveris ebaõnnestus sidevea tõttu.
      Palun registreerige fail uuesti.`,
        system_batch_error: 'Süsteemi viga (P001)',
        system_parameter_error: 'Süsteemi viga (P002)',
        system_parameterfile_notfound: 'Süsteemi viga (P003)',
        system_exception: 'Süsteemi viga (P004)',
        system_logfile_error: 'Süsteemi viga (P005)',
        system_parameterfile_parameter_error: 'Süsteemi viga (P006)',
        desing_localization_error: `Lokaliseerimisteavet pole saadaval.
      Palun registreerige üks järgmistest failidest:

      ・GC3 fail
      ・TP3 fail, milles on kontrollpunktid
      ・CAL fail`,
        desing_no_support_dxf_error:
          'DXF-fail(id) ei saanud lugeda, kuna selle versioon on vanem kui AutoCAD2000.',
        desing_tile_error: 'Kunne ikke oprette projektfilen.',
        desing_outline_error: 'Välimise piirjoone loomine ebaõnnestus.',
        desing_area_length_error:
          'Protsess on katkestatud, sest projektiala on liiga suur. Palun kontrollige, kas projekteerimisandmed ja CAD-andmete mõõtkava on õiged.',
        desing_layer_name_duplication_error: `Projekti andmed, millel on korduvad kihinimed, ei saa registreerida.
      Palun muutke disainikihi nimi või registreerige nii, et oleks ainult üks fail sama disainikihi nimega.`,
        targetFiles: '[Filnavn]',
        targetLayers: '[Kiht]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Puudub',
        system_batch_error: 'Süsteemi viga (T001)',
        system_parameter_error: 'Süsteemi viga (T002)',
        system_parameterfile_notfound: 'Süsteemi viga (T003)',
        system_exception: 'Süsteemi viga (T004)',
        system_logfile_error: 'Süsteemi viga (T005)',
        system_basefile_notfound: 'Süsteemi viga (T006)',
        system_prjfile_multi: 'Süsteemi viga (T007)',
        system_basefile_read_error: 'Süsteemi viga (T008)',
        system_target_layer_notfound: 'Süsteemi viga (T009)',
        system_target_layer_duplication: 'Sama kihi nimi on juba olemas.',
        system_taskarea_error: 'Ugyldigt opgaveområde',
        system_taskarea_design_notfound:
          'Ülesandealal puuduvad andmed projektfaili jaoks (TIN, jooned, punktid).',
        system_tile_error: 'Kunne ikke oprette projektfilen.',
        system_outline_error: 'Välimise piirjoone loomine ebaõnnestus.',
        system_parameterfile_error: 'Süsteemi viga',
      },
      retrofitError: {
        1000: 'UDP andmed Kontrollerilt on tuvastamata',
        2000: 'Kere IMU on tuvastamata',
        2001: 'Kere IMU riistvara viga',
        2002: 'Kere IMU algoritmi viga',
        2003: 'Kere IMU andmeviga',
        2004: 'Kere IMU algväärtustamise viga',
        2100: 'Noole IMU on tuvastamata',
        2101: 'Noole IMU riistvara viga',
        2102: 'Noole IMU algoritmi viga',
        2103: 'Noole IMU andmeviga',
        2104: 'Noole IMU algväärtustamise viga',
        2200: 'Kopavarre IMU on tuvastamata',
        2201: 'Kopavarre IMU riistvara viga',
        2202: 'Kopavarre IMU algoritmi viga',
        2203: 'Kopavarre IMU andmeviga',
        2204: 'Kopavarre IMU algväärtustamise viga',
        2300: 'Kopa IMU on tuvastamata',
        2301: 'Kopa IMU riistvara viga',
        2302: 'Kopa IMU algoritmi viga',
        2303: 'Kopa IMU andmeviga',
        2304: 'Kopa IMU algväärtustamise viga',
        2400: 'Kallutatava kopa IMU on tuvastamata',
        2401: 'Kallutatava kopa IMU riistvara viga',
        2402: 'Kallutatava kopa IMU algoritmi viga',
        2403: 'Kallutatava kopa IMU andmeviga',
        2404: 'Kallutatava kopa IMU algväärtustamise viga',
        2500: '2. masti IMU tuvastamata',
        2501: '2. masti IMU riistvara viga',
        2502: '2. masti IMU algoritmi viga',
        2503: '2. masti IMU andmeviga',
        2504: '2. masti IMU lähtestamise viga',
        2600: 'Pöördenurga andur tuvastamata',
        2601: 'Pöördenurga anduri süsteemi viga',
        2602: 'Kiikanurga anduri riistvaraline viga',
        2700: 'Nihke andur tuvastamata',
        2701: 'Nihke anduri süsteemi viga',
        2702: 'Nihke anduri riistvaraline viga',
        3000: 'Silindripea poolne rõhuandur on tuvastamata',
        3100: 'Alumine rõhuandur on tuvastamata',
        4000: 'GNSS-i antenn on tuvastamata',
        5000: 'Korrigeerimise info on tuvastamata',
        5001: 'Raadiovastuvõtja signaal on tuvastamata',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Teil suunatakse ümber teisele veebisaidile. Kas see on korras?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kopp',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Ettevõtte ID ja ettevõtte nimi on vajalikud.',
        },
        save: {
          message1: 'Ettevõtte ID/nimi ei saa muuta, kuna see sisaldab',
          message2: 'maskine(r) tildelt til et andet firma.',
          message3: '',
          message4: 'Palun kontrollige allpool olevaid seerianumbreid',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kontroller',
        },
        undeletable: {
          message: 'Kontroller on kasutusel ega saa kustutada.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Kas soovid peatada selle Ntrip seadistuse?',
          delete: 'Kas soovid kustutada Ntrip seadistuse?',
        },
      },
      siteRetrofitList: {
        title: 'Lisateave',
        message: 'Ehitusperiood on lõppenud.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Ühised seaded',
          siteSetting: 'Byggepladsindstillinger',
        },
        mapBackgroundColor: 'Baggrundsfarve',
        message:
          'Geopiirde kontakti tuvastamise / hoiatuse teavituse funktsioon ei pruugi korralikult toimida, olenevalt kasutamise keskkonnast või tingimustest. Ärge kasutage funktsiooni liiga enesekindlalt ja veenduge, et saate aru funktsioonist ja kasutamistingimustest.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Kas soovid kustutada seda geofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Masin on seotud töökohaga ja seda ei saa määrata. Palun ühendage masin tööplatsist lahti.',
          message2: 'Palun ühendage masin lahti ehitusplatsilt.',
        },
        sameOwner: {
          message: 'Masinal ei saa määrata, sest teie ettevõte on omanik.',
        },
        unableTransfer: {
          message:
            'Masin ootab üleandmise kinnitust ja seda ei saa registreerida määramiseks.',
        },
        unableAlternateRegist: {
          message:
            'Masin ootab kontrolleri proxy registreerimise kinnitust ja seda ei saa registreerida määramiseks.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Masin on seotud tööplatsiga, mida ei saa üle kanda. Palun ühendage masin tööplatsiga lahti.',
        messageRentaled: 'Masin on määratud ja seda ei saa üle kanda.',
        messageLicense: 'Masinal on litsents ja seda ei saa üle anda.',
        pendingApprovals: {
          message1:
            'Ülekandmistaotlus on vastu võetud. Palun oodake heakskiitu.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Kas soovid kontrolleri tagasi saata ja pärivõtta seaded sihtkontrollerile?',
        deleteNtrip: 'Kas soovid algseadistada tagastatud kontrolleri?',
        unableRegion:
          'See kontroller on saadetud välismaale. Kui see kontroller on õige, siis taastage "Regioon" nulliks.',
        unableCorporation: {
          message1: 'Kontrollerit ei saa tagasi saata.',
          message2:
            'Hvis du tror, dette er en fejl, skal du kontakte din forhandler.',
        },
        retrofitRentaled: 'Kontroller on määratud ega saa tagasi saata.',
        tiedToSite: 'Masin on seotud tööplatsiga ja seda ei saa tagasi saata.',
      },
      retrofitAddToSiteAction: {
        title: 'Lisateave',
        message:
          'Üks masin ootab heakskiitu, seega ei saa seda töökohaga siduda.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Lisateave',
        message: 'Masin on üle antud ja seda ei saa siduda tööülesandega.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'Valitud Ntrip Setting on saadetud teisele masinale.',
        },
        unableTransferInfo: {
          message: 'For at overføre, annuller følgende indstillinger.',
          reasonSiteId: 'Byggeplads',
          reasonRentaled: 'Ülesanne',
          reasonLicense: 'Litsents',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Määratud ettevõte määrab praegu masina teisele ettevõttele. Ülesande alguskuupäev teisele ettevõttele on %{date}.',
          message2: 'Palun valige lõppkuupäev pärast %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Masin on seotud määratud ettevõtte tööplatsiga. Kas soovite selle määramise lõpetada?',
        },
        confirmDoubleRental: {
          message:
            '"Määratud ettevõte määrab praegu masina teisele ettevõttele. Määratud ettevõte määrab praegu masina teisele ettevõttele. Lõppkuupäeva määramine lõpetab ka ülesande määramise teisele ettevõttele. Kas soovite määrata lõppkuupäeva?"',
        },
        unapprovedSave: {
          message:
            'Seda ülesannet ei ole heaks kiidetud ja seda ei saa salvestada.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Kas soovid kustutada seda ülesannet?',
        },
        approvedDelete: {
          title: '',
          message: 'See ülesanne on heaks kiidetud ja seda ei saa kustutada.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Masin on praegu määramisel ja seda ei saa üle kanda.',
        },
        approvedSave: {
          message: 'Masinal on litsents ja seda ei saa üle anda.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Kas soovid kustutada seda ülekannet?',
        },
        approvedDelete: {
          title: '',
          message: 'See ülekanne on heaks kiidetud ja seda ei saa kustutada.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kopavarre pikendi',
        },
      },
      projectFileUpload: {
        message: 'Flere kontrolpunktfiler (.gc3/.cal) kan ikke registreres.',
      },
      projectEdit: {
        confirm: {
          message:
            'Pärast projekti värskendamist kustutatakse olemasolevad ülesanded. Kas soovite värskendada?',
        },
      },
      projectConversionStatus: {
        title: 'Konversiooni olek',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Konversiooni olek',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'Valitud ülesannet ei saa kustutada, sest see on kasutusel.',
        },
        unableToCreate: {
          message: 'Der er ikke registreret konstruktionsdata i projektet.',
        },
        unableToDelete: {
          message1:
            'Ülesannet ei saa kustutada, sest projekti staatus ja ülesande staatus on järgmised.',
          message2: 'Projekt: Konvertimine',
          message3: 'Ülesanne: Viga',
        },
        unableToUpdate: {
          message1:
            'Ülesannet ei saa redigeerida, sest projekti ja ülesande olekud ei ole järgmised.',
          message2: 'Projekt: [Konverteeritud]',
          message3:
            'Ülesanne: [Edastamine][Edastatud][Ebaõnnestunud edastamine]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Ülesanne',
        },
        unableToDelete: {
          message: 'Valitud ülesannet ei saa kustutada, sest see on kasutusel.',
        },
      },
      taskMap: {
        crossingMessage: 'Ülesande ala on lõigatud.',
        sameValueMessage: 'Samad koordinaadid on sisestatud.',
        areaZeroMessage: 'Puudub ala tööülesande ala jaoks.',
        noPolygonMessage:
          'Ülesannet ei saa registreerida, sest see ei ole veel registreeritud.',
      },
      compactionMaterials: {
        add: {
          title: 'Lisa materjal',
        },
        update: {
          title: 'Materjali uuendamine',
        },
      },
      weathers: {
        add: {
          title: 'Lisa ilm',
        },
        update: {
          title: 'Ilma uuendamine',
        },
      },
      operators: {
        add: {
          title: 'Lisa operaator',
        },
        update: {
          title: 'Operaatori uuendamine',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Muuda tihenduse tööalad',
        },
        undeletable: {
          message: 'Tihenduskiht ehitusalas on kasutusel ega saa kustutada.',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Tihenduskiht on kasutusel ega saa kustutada.',
        },
        deleteConfirm: {
          messageTargetItem: 'Tihenduskiht',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Valitud masinale saadetakse veel üks Ntripi seade.',
        },
        confirmDelete: {
          message: 'Kas soovid kustutada Ntrip seadistuse?',
        },
        confirmDeleteWithMachine: {
          message:
            'Masin on praegu seotud. Kas soovite Ntrip Settingi kustutada?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Teavitamine',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Määrake litsents(id) järgmisele masinale.',
        },
        changeDisable: {
          message:
            'Palun muutke järgnevate ehitustehnika litsentsi ID kehtivaks.',
        },
        changeTemporary: {
          message:
            'Määrake litsents(id) järgmisele masinale. Praegune ajutine litsents tühistatakse.',
        },
        errorAdd: {
          message:
            'Masina litsentsi ei saa muuta, sest All license on juba määratud.',
        },
        errorSameType: {
          message:
            'Masina litsentsi ei saa muuta, sest sama tüüpi litsents on juba määratud.',
        },
        errorUnlimited: {
          message:
            'Masinal on juba seatud Unlimited litsents, teisi litsentse ei saa määrata.',
        },
        addAll: {
          message:
            'Järgmise masina litsents(id) on juba määratud. Kas soovite määrata All license?',
        },
        compare: {
          message: 'Muutke litsents(id) järgmise masina jaoks.',
        },
        warning: {
          message:
            '%{retrofit} on litsentsi numbriga %{id}. Kas soovite litsentsi katkestada?',
        },
        unableTransfer: {
          message: 'Masin on üle viidud, nii et litsentsi ei saa siduda.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'On andmed, mida ei õnnestunud pildi analüüsimisel.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Registreerimistaotlus on vastu võetud. Palun oodake, kuni see on heaks kiidetud.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontroller on juba registreeritud. Palun kontrollige masinate otsinguga.',
          message2:
            'Hvis du ikke kan finde målkontrolleren på listen, skal du kontakte os fra følgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Vabandame ebamugavuste pärast',
          message2:
            'aga palun võtke meiega ühendust alloleva URL-i kaudu järgmise teabega.',
          message3: '-Kontrolleri seerianumber',
          message4: '-Navn på maskinen, der skal registreres (valgfri)',
          message5: '-Ettevõtte ID registreeritav',
        },
        invalidSerialNumber: {
          message1: 'Kontroller on juba kasutus teise ettevõtte poolt.',
          message2: 'Hvis du vil ændre det af andre grunde,',
          message3:
            'Palun võtke meiega ühendust allpool toodud URL-i kaudu ja sisestage järgmised andmed.',
          message4: '-Kontrolleri seerianumber',
          message5: '-Navn på maskinen, der skal registreres (valgfri)',
          message6: '-Ettevõtte ID registreeritav',
          message7: '-Muutuse põhjus',
        },
        lendingRetrofits: {
          message1: 'Kontroller on määratud.',
          message2:
            'Palun kandideerige uuesti pärast ülesande lõppu määramisel ettevõttes.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        siteAttached: {
          message1: 'Kontroller on seotud töökohaga.',
          message2:
            'Palun kandideerige uuesti pärast masina lahtiühendamist ehitusplatsilt.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        serialNumberExistError: {
          message1:
            'See on kontrolleri seerianumber, mida ei ole olemas. Veenduge, et sisestatud kontrolleri seerianumber on õige.',
          message2:
            'Hvis problemet fortsætter, skal du kontakte os fra følgende URL.',
        },
        ntripShortage: {
          message1:
            'Me ei saanud väljastada teie Ntripi kasutajatunnust ja parooli.',
          message2: 'Palun võtke meiega ühendust allpool toodud URL-i kaudu.',
        },
        serialnumberCheck: {
          message: 'Palun kontrollige tooteid järgmiste seerianumbritega',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-post er nødvendig',
        },
        pendingApprovals: {
          message1:
            'Registreerimisnõue on vastu võetud. Palun oodake, kuni see on heaks kiidetud.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontroller on juba registreeritud. Palun kontrollige masinate otsinguga.',
          message2:
            'Hvis du ikke kan finde målkontrolleren på listen, skal du kontakte os fra følgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Vabandame ebamugavuste pärast',
          message2:
            'aga palun võtke meiega ühendust alloleva URL-i kaudu järgmise teabega.',
          message3: '-Kontrolleri seerianumber',
          message4: '-Navn på maskinen, der skal registreres (valgfri)',
          message5: '-Ettevõtte ID registreeritav',
        },
        invalidSerialNumber: {
          message1: 'Kontroller on juba kasutus teise ettevõtte poolt.',
          message2: 'Hvis du vil ændre det af andre grunde,',
          message3:
            'Palun võtke meiega ühendust allpool toodud URL-i kaudu ja sisestage järgmised andmed.',
          message4: '-Kontrolleri seerianumber',
          message5: '-Navn på maskinen, der skal registreres (valgfri)',
          message6: '-Ettevõtte ID registreeritav',
          message7: '-Muutuse põhjus',
        },
        lendingRetrofits: {
          message1: 'Kontroller on määratud.',
          message2:
            'Palun kandideerige uuesti pärast ülesande lõppu määramisel ettevõttes.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        siteAttached: {
          message1: 'Kontroller on seotud töökohaga.',
          message2:
            'Palun kandideerige uuesti pärast masina lahtiühendamist ehitusplatsilt.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        serialNumberExistError: {
          message1:
            'See on kontrolleri seerianumber, mida ei ole olemas. Veenduge, et sisestatud kontrolleri seerianumber on õige.',
          message2:
            'Hvis problemet fortsætter, skal du kontakte os fra følgende URL.',
        },
        ntripShortage: {
          message1:
            'Me ei saanud väljastada teie Ntripi kasutajatunnust ja parooli.',
          message2: 'Palun võtke meiega ühendust allpool toodud URL-i kaudu.',
        },
        serialnumberCheck: {
          message: 'Palun kontrollige tooteid järgmiste seerianumbritega',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'See kontroller kuulub juba teie ettevõttele.',
          message2: 'Palun võtke ühendust taotleva ettevõttega.',
        },
        userCorporation: {
          message1:
            'Teil ei saa seda taotlust heaks kiita, sest te juba laenutate seda kontrollerit.',
          message2: 'Palun võtke ühendust taotleva ettevõttega.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Kohapeal on kasutusel masin, millel puudub litsents.
        Kohapeal kasutamiseks on vaja litsentsi.
        Kui te ei ole litsentsi ostnud, ostke see Smart Construction Marketplace'ist.`,
        message2: `
        Kui te rendite masinat mõnelt teiselt ettevõttelt, siis paluge rendiallikalt masinale litsentsi.

        Kui teil on küsimusi, vaadake järgmist tugisaiti.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
    },
    tootip: {
      retrofitShareFileList: 'Rakenduse logi',
      retrofitConfigList: 'Konfiguratsiooni info',
      retrofitKitInfoList: 'Kontrolleri info',
      retrofitAccuraciesList: 'Täpsuskontrolli tulemus',
      retrofitCalibaList: 'Kalibreerimisinfo',
      retrofitBasicSettingList: 'Põhisätted',
      retrofitErrorList: 'Fejl detaljer',
      retrofitTopographicSurveyList: 'Topomõõdistamine',
      retrofitAsbuilts: 'Ehitustulemused',
    },
    actions: {
      ok: 'OK',
      add: 'Lisa',
      create: 'Registreerimine',
      yes: 'Jah',
      no: 'Ne',
      confirmation: 'Kinnita',
      save: 'SALVESTA',
      cancel: 'Tühista',
      required: 'Nõutav',
      addMachineToSite: 'Lisa masin tööleheküljele',
      addBucketToSite: 'Lisa',
      deleteFromSite: 'Eemalda ehitusplatsist',
      removeLicense: 'Masinast lahti',
      lendMachine: 'Ülesanne',
      transferMachine: 'Ülekanne',
      remoteSupport: 'Kaugabi',
      lendBucket: 'Jagada',
      approval: 'Kinnitus',
      connect: 'Ühenda',
      creationInstructions: 'Omandage ehitustulemus',
      csvDownload: 'Laadi alla',
      retrofitBundleCreate: 'Massregistreerimine',
      termsAccept: 'Nõustuma',
      termsDeny: 'Ei nõustu',
      bulkRegistration: 'Massilise uuendamine',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Tihenduse üksused',
      addLanguage: 'Lisa keel',
    },
    message: {
      error: 'Ilmnes tõrge',
      confirm: 'Er det ok?',
      fileUpload: 'Haal en sleep bestanden hier',
      success: 'Registreerimine on lõpetatud',
      approved: 'Kinnitus on lõpule viidud',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Kas soovid kustutada %{title}?',
      bulkDeleteConfirmContent: 'Kas soovid kustutada %{title}?',
      disconnectFromSite: 'Kas soovid ehitusplatsist lahti ühendust võtta?',
      saveConfirmContent: 'Kinnita',
      removeLicense: 'Kas soovid masinast lahti ühendust võtta?',
      task: {
        crossingMessage: 'Ülesande ala on lõigatud.',
        sameValueMessage: 'Samad koordinaadid on sisestatud.',
        areaZeroMessage: 'Puudub ala tööülesande ala jaoks.',
        invalidPolygonMessage: 'Samad koordinaadid on sisestatud.',
      },
      geofence: {
        crossingMessage: 'Geopiirde ala on lõigatud.',
        sameValueMessage: 'Samad koordinaadid on sisestatud.',
        areaZeroMessage: 'Puudub ala geoala.',
      },
      licenseError: 'Teil ei ole Smart Construction Pilot(Web App) litsentsi.',
      retrofitsManage: {
        serialNumberMatchError: 'Seerianumber ei ühti.',
        serialNumberInputError: 'Seerianumber ei eksisteeri',
        invalidAccess:
          'Teil ei ole luba seda lehekülge vaadata, naaske tagasi esilehele.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Seerianumber ei ühti.',
        serialNumberInputError: 'Seerianumber ei eksisteeri',
        retrofitRentaled:
          'Ettevõtte teavet ei saa ajakohastada, kui juht on ülesandes.',
        invalidCorporation:
          'Kuna teie ettevõte ei ole levitaja, ei saa teie kontrolleri proksi registreerimist teha.',
      },
    },
    validation: {
      startDate: 'Alguskuupäev',
      endDate: 'Slutdato',
      invalidDateFormat: 'Kuupäeva vorming on vigane.',
      invalidDateTimeFormat: 'Kuupäeva ja kellaaja vorming on vigane.',
      duplicateProject: 'Projekti nimi on kopeeritud',
      incorrectBeforeDate: '%{target} peab olema määratud enne %{before}.',
      incorrectAfterDate: '%{target} peab olema määratud pärast %{after}.',
      incorrectPlanStartDate:
        'Kavandatud alguskuupäev peab olema enne kavandatud lõppkuupäeva.',
      incorrectPlanEndDate:
        'Kavandatud lõppkuupäev peab olema kavandatud alguskuupäevast hilisem kuupäev.',
      incorrectActualStartDate: 'Alguskuupäev peab olema enne lõppkuupäeva.',
      incorrectActualEndDate:
        'Slutdatoen skal indstilles til en dato efter startdatoen.',
      withActualStartDate: 'Palun sisestage ka alguskuupäev.',
      inputAvailableDate: 'Sisestatav kuupäev on alates %{date}.',
      registrationRequired:
        'Palun valige määratud ettevõte või e-posti aadress.',
      bothInput:
        'Teil on võimalik sisestada ainult kas määratud ettevõte või e-posti aadress.',
      supportMode: 'Palun sisestage kas ettevõtte ID või ehitusplatsi ID.',
      invalidBkFile: 'Det er ikke en BK3-fil.',
      invalidPdfFile: 'Det er ikke en PDF-fil.',
      duplicatedBucketName: 'Eimeri nimi on kopeeritud',
      duplicatedTaskName: 'Ülesande nimi on topelt.',
      duplicateGeofenceName: 'Geofence-navnet er duplikeret',
      duplicatedCompactionWorkAreaName: 'Dupleeritud tihenduse tööala nimi',
      duplicatedCompactionLayerName: 'Dupleeritud tihenduskihi nimi',
      duplicated: {
        compactionMaterials: {
          message: 'Dupleeritud materjali nimi',
        },
        weathers: {
          message: 'Dupleeritud ilmaka nimi',
        },
        operators: {
          message: 'Dupleeritud operaatori nimi',
        },
      },
      containInvalidString:
        'Der er inkluderet ugyldige tegn. (%{msg} er ikke tilladt)',
      validHalfWidthAlphanumeric:
        'Ainult poole laiusega alfanumeersed tähemärgid on lubatud.',
      validNumberFormat: 'Ikke et tal',
      validIntegerFormat: 'Ikke et heltal',
      validIntegerRangeFormat: 'Der kan indtastes fra 0 til 2147483647.',
      validDuplicateLanguage: 'Valitud on sama keel.',
      validBeforeDate:
        '%{target} peab olema määratud kuupäev ja kellaaeg enne %{before}.',
      validAfterDate:
        '%{target} peab olema määratud kuupäev ja kellaaeg pärast %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Byggeplads',
      fields: {
        name: 'Byggeplads',
        corporationName: 'Ettevõtte nimi',
        status: 'Olek',
        workPeriodStartDate: 'Alguskuupäev',
        workPeriodEndDate: 'Slutdato',
      },
      status: {
        completed: 'Lõpule viidud',
        waiting: 'Enne ehituse algust',
        working: 'Ehitus all',
      },
    },
    retrofits: {
      name: 'Masina',
      fields: {
        information: 'Masina info',
        rental: 'Ülesanne',
        sendBack: 'Tagasi saatmine',
        corporationId: 'Ettevõte kasutusel',
        siteId: 'Byggeplads',
        uuid: 'UUID',
        isError: 'Olek',
        isOnline: 'Võrgus',
        lastOnlineTime: 'Seneste forbindelse',
        basicInfoManufacturer: 'Valmistaja',
        basicInfoModel: 'Mudel',
        basicInfoProductNumber: 'Tootenumber',
        basicInfoSerialNumber: 'Seerianumber.',
        controllerManufacturer: 'Valmistaja',
        controllerModel: 'Mudel',
        controllerFirmwareVersion: 'Püsivara ver.',
        gnssReceiverMainManufacturer: 'Valmistaja',
        gnssReceiverMainModel: 'Mudel',
        gnssReceiverMainFirmwareVersion: 'Püsivara ver.',
        gnssReceiverSubManufacturer: 'Valmistaja',
        gnssReceiverSubModel: 'Mudel',
        gnssReceiverSubFirmwareVersion: 'Püsivara ver.',
        machineInfoCompanyName: 'Tootja',
        machineInfoMachineType: 'Masina tüüp',
        machineInfoMachineName: 'Masina nimetus',
        machineInfoMachineId: 'Masina ID',
        rentalStatus: 'Ülesanne',
        lastUpdated: 'Senest opdateret',
      },
      isError: {
        true: 'Kontrolleri olekuteate vorming on vale',
        false: 'Tavaline',
      },
      isOnline: {
        true: 'Võrgus',
        false: 'Võrguühenduseta',
      },
      machineInfoMachineType: {
        excavator: 'Ekskavator',
        roadroller: 'Teerull',
        dozer: 'Dozer',
        rigiddump: 'Rigid Dumper',
        wheelloader: 'Rattalaadiur',
        grader: 'Grader',
        automobile: 'Sõiduk',
      },
      licenseMachineType: {
        excavator: 'Ekskavator',
        roadroller: 'Teerull',
        dozer: 'Dozer',
        rigiddump: 'Rigid Dumper',
        wheelloader: 'Rattalaadiur',
        grader: 'Grader',
        automobile: 'Sõiduk',
        excavator_license: 'Ekskavator : %{licenseGrantInfo}',
        roadroller_license: 'Teerull : %{licenseGrantInfo}',
        dozer_license: 'Dozer : %{licenseGrantInfo}',
        rigiddump_license: 'Rigid Dumper : %{licenseGrantInfo}',
        wheelloader_license: 'Rattalaadiur: %{licenseGrantInfo}',
        grader_license: 'Grader : %{licenseGrantInfo}',
        automobile_license: 'Sõiduk: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Ülekandmine',
        sent: 'Üle kantud',
        failtosend: 'Ülekanne ebaõnnestus',
      },
      rentalStatus: {
        approved: 'Kinnitatud',
        unapproved: 'Mitte kinnitatud',
        returned: 'Tagastatud',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'GNSS-i antenn on tuvastamata',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'Noole IMU on tuvastamata',
          2: 'Noole IMU on tuvastamata',
          3: 'Noole IMU on tuvastamata',
        },
        armImuStatus: {
          0: '',
          1: 'Kopavarre IMU on tuvastamata',
          2: 'Kopavarre IMU on tuvastamata',
          3: 'Kopavarre IMU on tuvastamata',
        },
        bucketImuStatus: {
          0: '',
          1: 'Kopa IMU on tuvastamata',
          2: 'Kopa IMU on tuvastamata',
          3: 'Kopa IMU on tuvastamata',
        },
        bodyImuStatus: {
          0: '',
          1: 'Kere IMU on tuvastamata',
          2: 'Kere IMU on tuvastamata',
          3: 'Kere IMU on tuvastamata',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Silindripea poolne rõhuandur on tuvastamata',
          2: 'Silindripea poolne rõhuandur on tuvastamata',
          3: 'Silindripea poolne rõhuandur on tuvastamata',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Alumine rõhuandur on tuvastamata',
          2: 'Alumine rõhuandur on tuvastamata',
          3: 'Alumine rõhuandur on tuvastamata',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'Kallutatava IMU on tuvastamata',
          2: 'Kallutatava IMU on tuvastamata',
          3: 'Kallutatava IMU on tuvastamata',
        },
        secondBoomImuStatus: {
          0: '',
          1: '2. masti IMU tuvastamata',
          2: '2. masti IMU tuvastamata',
          3: '2. masti IMU tuvastamata',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Nihke andur tuvastamata',
          2: 'Nihke andur tuvastamata',
          3: 'Nihke andur tuvastamata',
          4: 'Nihke andur tuvastamata',
          5: 'Nihke andur tuvastamata',
          6: 'Nihke andur tuvastamata',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Pöörlemisanduri tuvastamata',
          2: 'Pöörlemisanduri tuvastamata',
          3: 'Pöörlemisanduri tuvastamata',
          4: 'Pöörlemisanduri tuvastamata',
          5: 'Pöörlemisanduri tuvastamata',
          6: 'Pöörlemisanduri tuvastamata',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Tiltrotator tuvastamata',
          2: 'Tiltrotator tuvastamata',
          3: 'Tiltrotator tuvastamata',
        },
      },
    },
    retrofitRentals: {
      name: 'Ülesanne',
      fields: {
        id: 'ID',
        retrofitId: 'Masina ID',
        fromCorporationId: 'Määrava ettevõtte ID',
        fromCorporationName: 'Määrav ettevõte',
        toCorporationId: 'Määratud ettevõte',
        toCorporationName: 'Ettevõte kasutusel',
        retrofitRentalId: 'Ülesande ID',
        isApproved: 'Olek',
        isReturned: 'Tagastatud',
        planStartDate: 'Kavandatud alguskuupäev',
        planEndDate: 'Kavandatud lõppkuupäev',
        actualStartDate: 'Alguskuupäev',
        actualEndDate: 'Slutdato',
        rentalUsers: 'E-posti aadress',
        email: 'E-posti aadress',
        rentalStatus: 'Olek',
      },
      isApproved: {
        true: 'Kinnitatud',
        false: 'Mitte kinnitatud',
      },
      rentalStatus: {
        approved: 'Kinnitatud',
        unapproved: 'Mitte kinnitatud',
        returned: 'Tagastatud',
      },
    },
    pendingRentals: {
      name: 'Ootame heakskiitu',
      fields: {
        fromCorporationName: 'Määrav ettevõte',
        planStartDate: 'Kavandatud alguskuupäev',
        planEndDate: 'Kavandatud lõppkuupäev',
      },
    },
    pendingTransfers: {
      name: 'Ootame heakskiitu',
      fields: {
        fromCorporationName: 'Ülekandev ettevõte',
        planDate: 'Kavandatud üleandmise kuupäev',
        lastUpdated: 'Senest opdateret',
      },
    },
    pendingAlternateRegists: {
      name: 'Ootame heakskiitu',
      fields: {
        fromCorporationName: 'Praegune omanik',
        lastUpdated: 'Senest opdateret',
      },
    },
    assignments: {
      name: 'Ülekanne',
      fields: {
        id: 'ID',
        retrofitId: 'Masina ID',
        fromCorporationId: 'Ülekandva ettevõtte ID',
        fromCorporationName: 'Ülekandev ettevõte',
        toCorporationId: 'Üle kantud ettevõte',
        toCorporationName: 'Ettevõte',
        isApproved: 'Olek',
        planDate: 'Kavandatud üleandmise kuupäev',
        actualDate: 'Ülekandmise kuupäev',
        transferUsers: 'E-posti aadress',
        email: 'E-posti aadress',
        lastUpdated: 'Senest opdateret',
      },
      isApproved: {
        true: 'Kinnitatud',
        false: 'Mitte kinnitatud',
      },
    },
    buckets: {
      name: 'Kopp',
      fields: {
        companyName: 'Ettevõtte nimi',
        siteId: 'Byggeplads',
        name: 'Nimi',
        corporationId: 'Ettevõte kasutusel',
        bucketType: 'Tüüp',
        bk3File: 'BK3 fail',
        'bk3File.title': 'BK3 fail',
        gltfFile: 'Kopa fail',
        uploadedBy: 'Registreeritud',
        updatedBy: 'Uuendatud:',
        fileStatus: 'Olek',
        lastUpdated: 'Senest opdateret',
        uploadBk: 'Üleslaadimine BK3',
      },
      bucketType: {
        slope: 'Kalle',
        standard: 'Standard',
        tilt: 'Kalle',
        tiltrotator: 'Kalluta Rotator',
      },
      uploadedBy: {
        admin: 'Administraator',
        tablet: 'Tahvelarvuti',
      },
      fileStatus: {
        converting: 'Konverteerimine',
        completed: 'Lõpule viidud',
        error: 'Viga',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Projekti nimi',
        coordinate: 'Koord.-süsteem',
        version: 'Versioon',
        status: 'Olek',
        lastUpdated: 'Senest opdateret',
        regionId: 'Ala',
        projectionId: 'Projektsioon',
        geoidId: 'Geoid',
        datumId: 'Kuupäev',
        file: 'Fil(er)',
        retrofitId: 'Sihtmärkmasin',
      },
      status: {
        converted: 'Lõpule viidud',
        waitforconvert: 'Konverteerimine',
        error: 'Viga',
      },
    },
    extensionarms: {
      name: 'Kopavarre pikendi',
      fields: {
        extensionarmType: 'Tüüp',
        name: 'Nimi',
        corporationId: 'Ettevõte kasutusel',
        corporationName: 'Ettevõte kasutusel',
        siteId: 'Byggeplads',
        siteName: 'Byggeplads',
        lastUpdated: 'Senest opdateret',
        extensionarmFile: 'Armforlængerfil',
      },
      extensionarmType: {
        basic: 'Kopavarre pikendi',
        a: 'Tüüp A',
        b: 'Tüüp B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Jagada kellelegi',
        name: 'Nimi',
        extensionarmType: 'Tüüp',
        extensionarmFile: {
          title: 'Armforlængerfil',
        },
      },
    },
    corporations: {
      name: 'Ettevõte',
      fields: {
        name: 'Ettevõte kasutusel',
      },
    },
    retrofitShareFiles: {
      name: 'Rakenduse logi',
      fields: {
        name: 'Filnavn',
        fileTime: 'Saabumise kuupäev ja kellaaeg',
        url: 'Laadi alla',
      },
    },
    retrofitConfigs: {
      name: 'Konfiguratsiooni info',
      fields: {
        timeStamp: 'Saabumise kuupäev ja kellaaeg',
        url: 'Laadi alla',
      },
    },
    retrofitKitInfos: {
      name: 'Kontrolleri info',
      fields: {
        timeStamp: 'Saabumise kuupäev ja kellaaeg',
        url: 'Laadi alla',
      },
    },
    retrofitCalibs: {
      name: 'Kalibreerimisinfo',
      fields: {
        timeStamp: 'Saabumise kuupäev ja kellaaeg',
        url: 'Laadi alla',
        detailInfo: {
          createDateTime: 'Loo kuupäev ja kellaaeg',
          updateDateTime: 'Ajakohastamise kuupäev ja kellaaeg',
          machineInfo: {
            companyName: 'Tootja',
            machineType: 'Masina tüüp',
            machineName: 'Masina nimetus',
            machineId: 'Masina ID',
            drumConfiguration: 'Masina konfiguratsioon',
            guidanceMode: 'Juhtimisrežiim',
          },
          machineGeometryInfo: {
            boomLength: 'Noole pikkus',
            secondBoomLength: '2. masti pikkus',
            armLength: 'Kopavarre pikkus',
            armTopPinToBucketSideLinkPinLength: 'Kvar ültpi&kpa ktpi vahek',
            boomTopPinToBucketSideLinkPinLength: 'Nle ültpi&kpa ktpi vahek',
            bucketLink1Length: 'Kklgtpi&kpasiltpi vahek',
            bucketLink2Length: 'Kpsiltpi&kplgndtpi vahek',
            bodyCenterToBoomFootPin: {
              x: 'Nle jlse tpp X kere kskp',
              y: 'Nle jlse tpp Y kere kskp',
              z: 'Nle jlse tpp Z kere kskp',
            },
            drumWidth: 'Tera laius',
            length: 'Kingade vaheline pikkus',
            shoeInterval: 'Kingade vahepikkus',
            bladeWidthForDozer: 'Dozer tera laius',
            distanceFromBodyToBlade: 'Kere ja tera vaheline kaugus',
            frontToMainGnssAntenna:
              'Pikkus sirge joone keskpunktist, mis ühendab eesmise maandatud otsa eesmise gnss peaantenniga X',
            frontToSubGnssAntenna:
              'Pikkus sirgjoone keskpunktist, mis ühendab ettepoole maandatud otsa eesmise osa gnss-antenniga X',
            centerToMainGnssAntenna:
              'Y kaugus keskpunktist kuni peamise GNSS elektrilise faasi keskpunktini',
            centerToSubGnssAntenna:
              'Y kaugus keskpunktist kuni GNSSi elektrilise faasi keskpunktini',
            groundToMainGnssAntenna:
              'Z kaugus maapinnast kuni GNSSi elektrilise faasi keskpunktini',
            groundToSubGnssAntenna:
              'Z kaugus maapinnast kuni GNSSi elektrilise faasi keskpunktini',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Noole IMU nihke nurk',
            secondBoomImuOffsetAngle: '2. masti IMU nihke nurk',
            armImuOffsetAngle: 'Kopavarre IMU nihke nurk',
            bucketImuOffsetAngle: 'Kopa IMU nihke nurk',
            bodyImuOffsetRollAngle: 'Kere IMU nihke külgkalle',
            bodyImuOffsetPitchAngle: 'Kere IMU nihke pikikalle',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Põhi-GNSS X kere kskpkt',
                y: 'Põhi-GNSS Y kere kskpkt',
                z: 'Põhi-GNSS Z kere kskpkt',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Abi-GNSS X kere kskpkt',
                y: 'Abi-GNSS Y kere kskpkt',
                z: 'Abi-GNSS Z kere kskpkt',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Pöördmast mudel',
            twoPieceBoom: '2-sektsiooniline mast mudel',
            minimalSwingRadius: 'Külgnihkega mast mudel',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Vastukaalu kliirens',
            },
            bodyImu1: {
              roll: 'IMU mõõdetud väärtuse rull',
              pitch: 'IMU mõõdetud väärtuse kallak',
              yaw: 'IMU mõõdetud väärtus „Yaw“ (kallak)',
            },
            bodyImu2: {
              roll: 'IMU mõõdetud väärtuse rull',
              pitch: 'IMU mõõteväärtus „Pitch of IMU“ mõõdetuna',
              yaw: 'IMU mõõteväärtus „Yaw“ (Raskus)',
            },
            frontLeft: {
              x: 'Mõõdetud väärtus vasakul ees X',
              y: 'Mõõdetud väärtus vasakul ees Y',
              z: 'Vasaku esiosa mõõdetud väärtus Z',
            },
            frontRight: {
              x: 'Mõõdetud väärtus paremal ees X',
              y: 'Mõõdetud väärtus paremal ees Y',
              z: 'Parema esiosa uuritud väärtus Z',
            },
            frontGroundingEnd: {
              x: 'Esikülje mõõtmisväärtus X',
              y: 'Eesmise äärise mõõtmisväärtus Y',
              z: 'Esikülje otsa uuritud väärtus Z',
            },
            rearGroundingEnd: {
              x: 'Tagumise kumeruse otsa mõõdetud väärtus X',
              y: 'Tagumise kumeruse otsa mõõdetud väärtus Y',
              z: 'Tagumise ümbermõõdu otsa mõõdetud väärtus Z',
            },
            mainGnssAntenna: {
              x: 'GNSS peaantenni mõõdetud väärtus X',
              y: 'GNSS peaantenni mõõdetud väärtus Y',
              z: 'GNSS peaantenni mõõdetud väärtus Z',
            },
            subGnssAntenna: {
              x: 'GNSS alamantenni mõõdetud väärtus X',
              y: 'GNSS alamantenni mõõdetud väärtus Y',
              z: 'GNSS alamantenni Z mõõdetud väärtus',
            },
            prismPoleOffset: 'Prisma posti kõrgus',
            antennaElectricalCenterHeight:
              'Kõrgus antenni elektrilisest keskpunktist',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Täpsuskontrolli tulemus',
      fields: {
        checkDateTime: 'Kinnitamise kuupäev ja kellaaeg',
        surveyPoint: 'Målepunkt',
        positionN: 'Målt værdi : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Nihkeväärtus : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Viitepunkti nimi',
        referencePointN: 'Viitkoht : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Pikkusühik',
      },
    },
    retrofitBasicSettings: {
      name: 'Põhisätted',
      fields: {
        timeStamp: 'Saabumise kuupäev ja kellaaeg',
        url: 'Laadi alla',
      },
    },
    retrofitErrors: {
      name: 'Fejlinfo',
      fields: {
        notifiedTime: 'Esinemise kuupäev ja kellaaeg',
        errorCodes: 'Veilkode: Detaljer',
      },
    },
    retrofitToposurveys: {
      name: 'Topomõõdistuse loetelu',
      fields: {
        surveyDate: 'Küsitlemise kuupäev ja kellaaeg',
        projectName: 'Projekti nimi',
        layerName: 'Kiht',
        pointName: 'Punkti nimi',
        surveyPoint: 'Målepunkt',
        n: 'Målt værdi : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Pikkusühik',
      },
    },
    asbuilts: {
      name: 'Ehitustulemused',
      fields: {
        execStartDate: 'Udførelse start',
        execEndDate: 'Udførelse afsluttet',
        startPeriod: 'Omandamisperiood alates',
        endPeriod: 'Omandamisperiood kuni',
        execStatus: 'Olek',
        url: '',
        csvType: 'Andmevorming',
        createUser: 'Loo kasutaja',
      },
      execStatus: {
        complete: 'Lõpule viidud',
        inprogress: 'Töötlus',
        error: 'Viga',
        completewithoutdata: 'Lõpule viidud (Andmed puuduvad)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'määratud aeg',
      },
    },
    retrofitBasicInfos: {
      name: 'Põhiinfo',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Levitaja',
        retrofitRegionId: 'Ala',
        corporationId: 'Ettevõtte ID',
        corporationName: 'Ettevõtte nimi',
        basicInfoManufacturer: 'Valmistaja',
        basicInfoModel: 'Mudel',
        basicInfoProductNumber: 'Tootenumber',
        basicInfoSerialNumber: 'Seerianumber.',
        lastUpdated: 'Senest opdateret',
        description: 'Kirjeldus',
        ownerId: 'Ettevõtte ID',
        ownerName: 'Ettevõtte nimi',
      },
      tag: {
        retrofitBasicInfosDetail: 'Põhiinfo',
        sendBack: 'Tagasi saatmine',
      },
      sendBack: {
        fields: {
          site: 'Byggeplads',
          corporation: 'Ettevõte kasutusel',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternatiivne kontroller',
          notificationEmailAddress: 'E-posti aadress',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Massregistreerimine',
      fields: {
        uploadFileName: 'Filnavn',
        execStartDate: 'Udførelse startdato og -tid',
        execEndDate: 'Udførelse slutdato og -tid',
        execStatus: 'Olek',
        uploadFile: 'Registreeritud fail',
        resultFile: 'Registreerimistulemused',
      },
      execStatus: {
        complete: 'Lõpule viidud',
        inprogress: 'Töötlus',
        error: 'Viga',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Administraatori ettevõtte massitegevus',
      fields: {
        uploadFileName: 'Filnavn',
        execStartDate: 'Udførelse startdato og -tid',
        execEndDate: 'Udførelse slutdato og -tid',
        execStatus: 'Olek',
        uploadFile: 'Registreeritud fail',
        resultFile: 'Registreerimistulemused',
      },
      execStatus: {
        complete: 'Lõpule viidud',
        inprogress: 'Töötlus',
        error: 'Viga',
      },
    },
    tasks: {
      name: 'Ülesanne',
      fields: {
        name: 'Nimi',
        status: 'Olek',
        description: 'Kirjeldus',
        projectSourceLayerId: 'Kiht',
        retrofitIds: 'Sihtmärkmasin',
        expiredDate: 'Udløbsdato',
        createDate: 'Udløbsdato',
        rectangle: 'Toetatud vahemik',
        lastUpdated: 'Senest opdateret',
      },
      status: {
        error: 'Viga',
        converting: 'Konverteerimine',
        sending: 'Ülekandmine',
        sent: 'Üle kantud',
        failtosend: 'Ülekanne ebaõnnestus',
      },
    },
    compactionMaterials: {
      name: 'Materialer',
      fields: {
        name: 'Nimi',
      },
    },
    weathers: {
      name: 'Ilmastik',
      fields: {
        name: 'Nimi',
      },
    },
    operators: {
      name: 'Operaatorid',
      fields: {
        name: 'Nimi',
      },
    },
    compactionWorkAreas: {
      name: 'Tihendusvaldkonnad',
      fields: {
        name: 'Tööpiirkonna nimetus',
        blockSize: 'Ploki suurus',
        description: 'Kirjeldus',
      },
    },
    compactionLayers: {
      name: 'Tihenduskihid',
      fields: {
        name: 'Nimi',
        thickness: 'Paksus',
        passCount: 'Läbimiskordade arv',
        compactionMaterialId: 'Materiale',
        inUse: 'Olek',
        description: 'Kirjeldus',
      },
      inUse: {
        true: 'i brug',
        false: 'Kasutamata',
      },
    },
    gnssSettings: {
      name: 'Ntrip seaded',
      fields: {
        registCorporationName: 'Registreeritud',
        ownerCorporationId: 'Ettevõtte ID',
        ownerCorporationName: 'Ettevõtte nimi',
        name: 'Nimi',
        url: 'Serveri URL',
        portnum: 'Port',
        userId: 'ID',
        password: 'Salasõna',
        retrofitId: 'Sihtmärkmasin',
        status: 'Olek',
        updateDate: 'Senest opdateret',
      },
      status: {
        waitforsend: 'Konverteerimine',
        sending: 'Ülekandmine',
        sent: 'Üle kantud',
        failtosend: 'Ülekanne ebaõnnestus',
      },
    },
    supportMode: {
      name: 'Toetusrežiim',
      fields: {
        enabled: 'Toeta režiim sisse',
        corporationId: 'Ettevõtte ID',
        corporationName: 'Ettevõtte nimi',
        siteId: 'Byggeplads ID',
        siteName: 'Byggeplads',
        latitude: 'Laiuskraad',
        longitude: 'Pikkuskraad',
        workPeriodStartDate: 'Tegelik ehitusperiood (alguskuupäev)',
        workPeriodEndDate: 'Tegelik ehitusperiood (lõppkuupäev)',
        photoUrl: 'Saidi pildi URL',
        description: 'Kirjeldus',
      },
    },
    userNotices: {
      name: 'Teavitamine',
      noticeType: {
        emergencyimportant: 'Hädaolukord/oluline',
        emergency: 'Hädaolukord',
        important: 'Viktigt',
      },
      fields: {
        noticeType: 'Betydning',
        noticeTypes: 'Betydning',
        noticeCategory: 'Teavituse tüüp',
        title: 'Pealkiri',
        content: 'Sisu',
        publicationStartDate: 'Alguskuupäev ja kellaaeg (UTC)',
        publicationEndDate: 'Slutdato og -tid(UTC)',
        file: 'Manused',
        language: 'Keel',
        emergency: 'Hädaolukord',
        important: 'Viktigt',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versioon',
        compatibilityLevel: 'Ühilduvuse tase',
        firmwareFile: 'Firmware-fil',
        firmwareFileCheckSum: 'Firmware Check Sum',
        firstInitializationFile: 'Initialiseringsfil 1',
        firstInitializationFileCheckSum: 'Initialiseringsfil 1 CheckSum',
        secondInitializationFile: 'Initialiseringsfil 2',
        secondInitializationFileCheckSum: 'Initialiseringsfil 2 CheckSum',
      },
    },
    bucketShares: {
      name: 'Eimeri jagamine',
      fields: {
        fromBucketId: 'Fra spand-id',
        corporationId: 'Ettevõtte ID',
        name: 'Nimi',
      },
    },
    extensionarmShare: {
      name: 'Armforlængerdeling',
      fields: {
        name: 'Nimi',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LITSENTS',
      fields: {
        licenseId: 'Litsentsi ID-number',
        licenseType: 'Tüüp',
        issuedAt: 'Udgivelsesdato',
        expiresAt: 'Udløbsdato',
        retrofitId: 'Masina',
        comment: 'Kirjeldus',
        licenseStatus: 'Olek',
        licensePermission: 'LITSENTS',
        productType: 'Tootenimi',
      },
      licenseStatus: {
        unlicense: 'Määramata',
        disable: 'Kehtetu',
        enable: 'Kehtiv',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Ettevõtte nimi',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Pikkusühik',
      },
    },
    geofences: {
      name: 'Geopiire',
      fields: {
        type: 'Tüüp',
        name: 'Nimi',
        dimension: 'Mõõt',
        trigger: 'Päästik',
        valid: 'Kehtiv',
        alertType: 'Hoiatuse tüüp',
        startDate: 'Alguskuupäev',
        endDate: 'Slutdato',
        transparency: 'Läbipaistev',
        elevation: 'Kõrgus merepinnast',
        height: 'Kõrgus',
        radius: 'Raadius',
        lastUpdated: 'Senest opdateret',
        points: 'Toetatud vahemik',
        thickness: 'Paksus',
      },
      type: {
        line: 'Joon',
        polygon: 'Polügoon',
        circle: 'Pöördering',
        point: 'Punkt',
        wall: 'Sein',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Kehtiv',
        false: 'Kehtetu',
      },
      alertType: {
        attention: 'Tähelepanu',
        notice: 'Pane tähele',
        warning: 'Hoiatus',
        caution: 'Hoiatus',
        danger: 'Oht',
      },
      transparency: {
        true: 'Läbipaistev',
        false: 'Opakne',
      },
    },
    geofenceAlerts: {
      name: 'Hoiatused',
      fields: {
        timeStamp: 'Avastamise kuupäev ja kellaaeg',
        geofenceName: 'Geofence-navn',
        alertType: 'Hoiatuse tüüp',
        collisionPoint: 'Kokkupõrkepunkt',
        retrofit: {
          machineInfoMachineName: 'Masina',
        },
      },
      alertType: {
        attention: 'Tähelepanu',
        notice: 'Pane tähele',
        warning: 'Hoiatus',
        caution: 'Hoiatus',
        danger: 'Oht',
      },
    },
    ntrips: {
      name: 'Ntripi haldamine',
      fields: {
        name: 'Kasutaja-ID',
        ntripPass: 'Salasõna',
        serial: 'Seerianumber.',
        customerCorporationId: 'Ettevõtte ID',
        customerCorporationName: 'Ettevõtte nimi',
        ntrip: 'Olek',
        numberOfExchanges: 'Vahetuste arv',
        id: 'ID',
        licenceId: 'Litsentsi ID-number',
        dateOfRegistration: 'Registreerimiskuupäev',
        billingToCustomers: 'Arveldamine klientidele',
        paymentToKomatsu: 'Makse Komatsule',
        applicantId: 'Taotleja ID',
        applicant: 'Taotleja',
        requestCorporationId: 'Taotlege ettevõtte ID',
        requestCorporationName: 'Taotlege ettevõtte nime',
        isUsed: 'Bruges',
      },
      ntrip: {
        publish: 'Väljamakstud',
        stop: 'Peatage',
        delete: 'Kustutatud',
      },
    },
    retrofitAlternateRegists: {
      name: 'Kontrolleri Proxy registreerimine',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fra selskabs-id',
        fromCorporationName: 'Kontrolleri Proxy registreerimine',
        toCorporationId: 'Kasutaja',
        toCorporationName: 'Ettevõtte nimi',
        isApproved: 'Olek',
        createDate: 'Taotluse kuupäev',
        actualDate: 'Kinnitamise kuupäev',
        transferUsers: 'E-posti aadress',
        email: 'E-posti aadress',
        lastUpdated: 'Senest opdateret',
      },
      isApproved: {
        true: 'Kinnitatud',
        false: 'Mitte kinnitatud',
      },
    },
    pendingApprovals: {
      name: 'Ootame heakskiitu',
      fields: {
        approvalType: 'Tüüp',
        requestedBy: 'Taotleja ettevõte',
        lastUpdated: 'Senest opdateret',
      },
      approvalType: {
        assignment: 'Ülesanne',
        transfer: 'Ülekanne',
        agent: 'Kontrolleri Proxy registreerimine',
      },
    },
    retrofitSendbacks: {
      name: 'Tagasi saatmine',
      fields: {
        fromRetrofitId: 'Fra Retrofit',
        toRetrofitId: 'Alternatiivne kontroller',
        notificationEmailAddress: 'E-posti aadress',
        siteId: 'Byggeplads',
        corporationId: 'Ettevõtte ID',
        corporationName: 'Ettevõte kasutusel',
        ntripId: 'Ntripi info',
        isComplete: 'Saatmine lõpetatud',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
